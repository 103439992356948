import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
//import { saveAs } from 'file-saver';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import DatePicker from '../../components/DatePicker';
//import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";
import ComboBox from '../../components/ComboBox';

import { logo } from "../../components/d3/charts/chart";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';
import { timeformat, getWidth, responsivness, getMonths } from '../../components/d3/d3timeformat';

import { getCurrentYear, } from '../../js/datetime';

import { borders, addLine, addArea, addLabelToSVG } from '../../components/d3/legend/legend';

export const WinecultureKenntage = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [year, setYear] = useState(getCurrentYear());

    const [data, setData] = useState();
    const [anlage, setAnlage] = useState(1);
    const [anlagen, setAnlagen] = useState([]);
    const [fromdjs, setFromdjs] = useState(dayjs());

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }, [resize, data, anlage, i18n.language]);

    useEffect(() => {
        setData(undefined);
        d3.json(preferences.address + "/query/wineculture/kenntage/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [year, parameter.station]);

    useEffect(() => {

        d3.json(preferences.address + "/query/anlagen.php")
            .then((data) => {
                setAnlagen(data);
            });
    }, []);

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Phänologie Ganttchart', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])

    /*const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/dry/excel.php';

        let block = {};
        block.station = parameter.station;
        //block.year = year;
        //block.period = period;
        //block.level = threshold;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }*/
    function avg(list) {
        let sum = 0;
        list.forEach((item) => { sum += item; })
        return sum / list.length;
    }


    function draw(svg) {

        /*var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);*/

        timeformat(t);

        // Clear
        svg.selectAll("*").remove();


        const margin = {
            top: 20,
            right: 80,
            bottom: 20,
            left: 80,
            middle: 100,
            graph: 200
        }; // margin of chart

        let FROM = year + "-03-10";
        let TO = year + "-09-01";

        let width = parseInt(svg.style("width"));

        //console.log (width);
        let parseTime = d3.timeParse("%Y-%m-%d");

        // Create scale
        let step = 40; // Pro zeile, Jahr

        // Anzahl Jahre
        let years = data.keys.length;
        if (years === 0) years = 1;

        let height = margin.top + margin.bottom + 4 * margin.graph + (4) * margin.middle;
        svg.attr("height", height);

        // Add scales to axis
        //var xScale = d3.scaleLinear()
        var xScaleBand = d3.scaleBand()
            .domain([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
            .range([0, width - margin.right - margin.left])
            .padding(0.6)

        // X-Axis
        /*svg.append("g")
            .attr("transform", `translate(${margin.left}, ${height-margin.bottom})`)
            .call(x_axis.tickFormat((x) => {return x + " " + t("month") }).ticks(12)) */
        // X-grid

        let months = getMonths(t); //[t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];
        var xScaleLabel = d3.scaleBand()
            .domain(months.map((d) => { return t(d) }))
            .range([0, width - margin.left - margin.right])
            .padding(0.6);
        var x_axis = d3.axisBottom()
            .scale(xScaleLabel);
        svg.append("g")
            .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
            .call(x_axis.tickFormat((x) => { return x }).ticks(12))
        svg.append("g")
            .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
            .attr("class", "gridline")
            .call(x_axis.tickSize(-height + margin.bottom + margin.top).tickFormat("").ticks(12))

        // Add scales to axis
        var yScale1 = d3.scaleLinear()
            .domain([0, 31])
            .range([margin.top + margin.graph, margin.top])
        var y_axis1 = d3.axisLeft()
            .scale(yScale1);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis1.tickFormat((x) => { return x + " " + t("days") }).ticks(5))
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis1.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))
        /*svg.append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top+margin.graph+margin.middle/2})`)
            .attr("class", "gridline")
            .call(x_axis.tickSize(-height+margin.bottom+margin.top).tickFormat("")) */

        var yScale1a = d3.scaleLinear()
            .domain([-15, 15])
            .range([margin.top + margin.graph + margin.middle - 15, margin.top + margin.graph + 15])
        var y_axis1a = d3.axisLeft()
            .scale(yScale1a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis1a.tickFormat((x) => { return x + " " + t("days") }).ticks(3))
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis1a.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))



        // Add scales to axis
        var yScale2 = d3.scaleLinear()
            .domain([0, 31])
            .range([margin.top + 2 * margin.graph + (2 - 1) * margin.middle, margin.top + (2 - 1) * margin.graph + (2 - 1) * margin.middle])

        var y_axis2 = d3.axisLeft()
            .scale(yScale2);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis2.tickFormat((x) => { return x + " " + t("days") }).ticks(5))
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis2.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))

        var yScale2a = d3.scaleLinear()
            .domain([-15, 15])
            .range([margin.top + margin.graph * 2 + margin.middle * 2 - 15, margin.top + margin.graph * 2 + margin.middle + 15])
        var y_axis2a = d3.axisLeft()
            .scale(yScale2a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis2a.tickFormat((x) => { return x + " " + t("days") }).ticks(3))
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis2a.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))


        // Add scales to axis
        var yScale3 = d3.scaleLinear()
            .domain([0, 31])
            //.range([margin.top + 3 *200 + margin.middle, margin.top + 200 * (3-1) + (3-1) * margin.middle])
            .range([margin.top + 3 * margin.graph + (3 - 1) * margin.middle, margin.top + (3 - 1) * margin.graph + (3 - 1) * margin.middle])

        var y_axis3 = d3.axisLeft()
            .scale(yScale3);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis3.tickFormat((x) => { return x + " " + t("days") }).ticks(5))
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis3.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))

        var yScale3a = d3.scaleLinear()
            .domain([-15, 15])
            .range([margin.top + margin.graph * 3 + margin.middle * 3 - 15, margin.top + margin.graph * 3 + margin.middle * 2 + 15])
        var y_axis3a = d3.axisLeft()
            .scale(yScale3a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis3a.tickFormat((x) => { return x + " " + t("days") }).ticks(3))
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis3a.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))

        // Add scales to axis
        var yScale4 = d3.scaleLinear()
            .domain([0, 31])
            .range([margin.top + 4 * margin.graph + (4 - 1) * margin.middle, margin.top + (4 - 1) * margin.graph + (4 - 1) * margin.middle])

        var y_axis4 = d3.axisLeft()
            .scale(yScale4);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis4.tickFormat((x) => { return x + " " + t("days") }).ticks(5))
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis4.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))

        var yScale4a = d3.scaleLinear()
            .domain([-15, 15])
            .range([margin.top + margin.graph * 4 + margin.middle * 4 - 15, margin.top + margin.graph * 4 + margin.middle * 3 + 15])
        var y_axis4a = d3.axisLeft()
            .scale(yScale4a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis4a.tickFormat((x) => { return x + " " + t("days") }).ticks(3))
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis4a.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))

        var thick = 3.5
        var thin = 1.5;

        var graph = svg.append("g");

        // Sommertage
        let color = "steelblue";
        graph.append("path")
            .datum(data)
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("id", "line")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", thick)
            .attr("d", d3.line()
                .x((d) => { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
                .y((d) => { return yScale1(d.summer) })
            )
        graph
            .selectAll("vertLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", (d, index) => { return xScaleBand(index + 1) })
            .attr("x2", (d, index) => { return xScaleBand(index + 1) })
            .attr("y1", (d, index) => { return yScale1(d3.min(data[index].summer_mid)) })
            .attr("y2", (d, index) => { return yScale1(d3.max(data[index].summer_mid)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40);
        graph
            .selectAll("boxes")
            .data(data)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale1(d3.quantile(data[index].summer_mid, 0.75)) })
            .attr("height", (d, index) => { return yScale1(d3.quantile(data[index].summer_mid, 0.25)) - yScale1(d3.quantile(data[index].summer_mid, 0.75)) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.7)
            .attr("rx", 5);
        graph
            .selectAll("horLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("y1", function (d, index) { return yScale1(d3.quantile(data[index].summer_mid, 0.5)) })
            .attr("y2", function (d, index) { return yScale1(d3.quantile(data[index].summer_mid, 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40)
        graph
            .selectAll("boxes")
            .data(data.filter((d, i) => { if (year < dayjs().year()) return true; return (i - 1) < (dayjs().month()); }))
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale1a(data[index].summer > data[index].summer_avg ? data[index].summer - data[index].summer_avg : 0) })
            .attr("height", (d, index) => { return yScale1a(0) - yScale1a(data[index].summer_avg > data[index].summer ? data[index].summer_avg - data[index].summer : data[index].summer - data[index].summer_avg) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.9)
            .append("title")
            .text((d) => Math.round((d.summer - d.summer_avg) * 10) / 10);
        graph.append('line')
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("x1", margin.left)
            .attr("x2", width - margin.right)
            .attr("y1", yScale1a(0))
            .attr("y2", yScale1a(0))

        // Tropentage
        color = "#9b00ff";
        graph.append("path")
            .datum(data)
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("id", "line")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", thick)
            .attr("d", d3.line()
                .x((d) => { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
                .y((d) => { return yScale2(d.tropic) })
            )
        graph
            .selectAll("vertLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", (d, index) => { return xScaleBand(index + 1) })
            .attr("x2", (d, index) => { return xScaleBand(index + 1) })
            .attr("y1", (d, index) => { return yScale2(d3.min(data[index].tropic_mid)) })
            .attr("y2", (d, index) => { return yScale2(d3.max(data[index].tropic_mid)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40);
        graph
            .selectAll("boxes")
            .data(data)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale2(d3.quantile(data[index].tropic_mid, 0.75)) })
            .attr("height", (d, index) => { return yScale2(d3.quantile(data[index].tropic_mid, 0.25)) - yScale2(d3.quantile(data[index].tropic_mid, 0.75)) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.7)
            .attr("rx", 5);
        graph
            .selectAll("horLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("y1", function (d, index) { return yScale2(d3.quantile(data[index].tropic_mid, 0.5)) })
            .attr("y2", function (d, index) { return yScale2(d3.quantile(data[index].tropic_mid, 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40)
        graph
            .selectAll("boxes")
            .data(data.filter((d, i) => { if (year < dayjs().year()) return true; return (i - 1) < (dayjs().month()); }))
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale2a(data[index].tropic > data[index].tropic_avg ? data[index].tropic - data[index].tropic_avg : 0) })
            .attr("height", (d, index) => { return yScale2a(0) - yScale2a(data[index].tropic_avg > data[index].tropic ? data[index].tropic_avg - data[index].tropic : data[index].tropic - data[index].tropic_avg) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.9)
            .append("title")
            .text((d) => Math.round((d.tropic - d.tropic_avg) * 10) / 10);
        graph.append('line')
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("x1", margin.left)
            .attr("x2", width - margin.right)
            .attr("y1", yScale2a(0))
            .attr("y2", yScale2a(0))

        // Wüstentage
        color = "#ff0000";
        graph.append("path")
            .datum(data)
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("id", "line")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", thick)
            .attr("d", d3.line()
                .x((d) => { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
                .y((d) => { return yScale3(d.desert) })
            )
        graph
            .selectAll("vertLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", (d, index) => { return xScaleBand(index + 1) })
            .attr("x2", (d, index) => { return xScaleBand(index + 1) })
            .attr("y1", (d, index) => { return yScale3(d3.min(data[index].desert_mid)) })
            .attr("y2", (d, index) => { return yScale3(d3.max(data[index].desert_mid)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40);
        graph
            .selectAll("boxes")
            .data(data)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale3(d3.quantile(data[index].desert_mid, 0.75)) })
            .attr("height", (d, index) => { return yScale3(d3.quantile(data[index].desert_mid, 0.25)) - yScale3(d3.quantile(data[index].desert_mid, 0.75)) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.7)
            .attr("rx", 5);
        graph
            .selectAll("horLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("y1", function (d, index) { return yScale3(d3.quantile(data[index].desert_mid, 0.5)) })
            .attr("y2", function (d, index) { return yScale3(d3.quantile(data[index].desert_mid, 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40)
        graph
            .selectAll("boxes")
            .data(data.filter((d, i) => { if (year < dayjs().year()) return true; return (i - 1) < (dayjs().month()); }))
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale3a(data[index].desert > data[index].desert_avg ? data[index].desert - data[index].desert_avg : 0) })
            .attr("height", (d, index) => { return yScale3a(0) - yScale3a(data[index].desert_avg > data[index].desert ? data[index].desert_avg - data[index].desert : data[index].desert - data[index].desert_avg) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.9)
            .append("title")
            .text((d) => Math.round((d.desert - d.desert_avg) * 10) / 10);
        graph.append('line')
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("x1", margin.left)
            .attr("x2", width - margin.right)
            .attr("y1", yScale3a(0))
            .attr("y2", yScale3a(0))


        // Tropennächte
        color = "#ff0088";
        graph.append("path")
            .datum(data)
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("id", "line")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", thick)
            .attr("d", d3.line()
                .x((d) => { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
                .y((d) => { return yScale4(d.tropicnight) })
            )
        graph
            .selectAll("vertLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", (d, index) => { return xScaleBand(index + 1) })
            .attr("x2", (d, index) => { return xScaleBand(index + 1) })
            .attr("y1", (d, index) => { return yScale4(d3.min(data[index].tropicnight_mid)) })
            .attr("y2", (d, index) => { return yScale4(d3.max(data[index].tropicnight_mid)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40);
        graph
            .selectAll("boxes")
            .data(data)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale4(d3.quantile(data[index].tropicnight_mid, 0.75)) })
            .attr("height", (d, index) => { return yScale4(d3.quantile(data[index].tropicnight_mid, 0.25)) - yScale4(d3.quantile(data[index].tropicnight_mid, 0.75)) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.7)
            .attr("rx", 5);
        graph
            .selectAll("horLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("y1", function (d, index) { return yScale4(d3.quantile(data[index].tropicnight_mid, 0.5)) })
            .attr("y2", function (d, index) { return yScale4(d3.quantile(data[index].tropicnight_mid, 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40)
        graph
            .selectAll("boxes")
            .data(data.filter((d, i) => { if (year < dayjs().year()) return true; return (i - 1) < (dayjs().month()); }))
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale4a(data[index].tropicnight > data[index].tropicnight_avg ? data[index].tropicnight - data[index].tropicnight_avg : 0) })
            .attr("height", (d, index) => { return yScale4a(0) - yScale4a(data[index].tropicnight_avg > data[index].tropicnight ? data[index].tropicnight_avg - data[index].tropicnight : data[index].tropicnight - data[index].tropicnight_avg) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.9)
            .append("title")
            .text((d) => Math.round((d.tropicnight - d.tropicnight_avg) * 10) / 10);
        graph.append('line')
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("x1", margin.left)
            .attr("x2", width - margin.right)
            .attr("y1", yScale4a(0))
            .attr("y2", yScale4a(0))



        // X Label
        svg.append("text")
            .attr("class", "x label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", 15)
            .text(t("months"));

        // Y Label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", 20)
            .text(t("stadium"));

        // TOP
        /*var xScaleLinearLeft = d3.scaleLinear()
            .domain([1, 12])
            .range([margin.left, width - margin.right]);
        y_axis = d3.axisTop()
            .scale(xScaleLinearLeft);
        svg.append("g") // X Axis
            .attr("transform", `translate(0, ${margin.top})`)
            .call(y_axis.ticks(20));*/

        // BOTTOM
        /*let xScaleTime = d3.scaleTime()
            .domain([parseTime(FROM), parseTime(TO)])
            .range([margin.left, width - margin.right]);
        let xAxis = d3.axisBottom()
            .scale(xScaleTime);
        svg.append("g") // X Axis
            .attr("transform", `translate(0, ${height-margin.bottom})`)
            .call(xAxis);*/
        //let xGrid =
        /*svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(80));
        //let xGridWeeks = 
        svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(10));*/

        const box = svg.append("g").attr('id', 'block');

        logo(svg);

        let labels = [t("summerday"), t("tropicday"), t("desertday"), t("tropicnight")]
        let colors = ["steelblue", "#9b00ff", "red", "#ff0088"];
        /*borders (svg, labels, 250, 80, 20);
        labels.forEach((d, i) => {
            addArea (svg, i, colors[i], d);
        })*/
        let r = responsivness();
        if (r.dialog) {
            addLabelToSVG(svg, 80, margin.top, colors[0], labels[0])
            addLabelToSVG(svg, 80, margin.top + margin.graph + margin.middle, colors[1], labels[1])
            addLabelToSVG(svg, 80, margin.top + margin.graph * 2 + margin.middle * 2, colors[2], labels[2])
            addLabelToSVG(svg, 80, margin.top + margin.graph * 3 + margin.middle * 3, colors[3], labels[3])
        }
    }

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    let c = ["Golden Delicious", "Cripps Pink", "Gala"];
    const handleSelectorAnlage = (event) => {
        setAnlage(event.target.value);
    };


    return (
        <>
            <div className="stagearea grid p-2">

                <Stack spacing={0}>

                    <Item>
                        <DatePicker
                            title="year"
                            value={fromdjs}
                            callback={handleSelector}
                            view={["year"]}
                            format="YYYY"
                        />
                    </Item>

                </Stack>


                <div id="svgchart">
                    {data !== undefined && data.length > 0
                        ?
                        <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
                        :
                        <div>No data available</div>
                    }
                </div>

                <Grid container flexDirection={"row"}>

                    <Grid item className="p-2" flexGrow={1}>
                        <InfoButton
                            de={"Die temperaturabhängigen Kenntage sind folgende: Sommer-, Tropen- (bzw. Hitze-) und Wüstentage, sowie Tropennächte. Die Kenntage werden als Boxplot dargestellt, der Verlauf des ausgewählten Jahres als Linie. Das Balkendiagramm unter dem Boxplot zeigt die Abweichung des aktuellen Jahres vom langjährigen Medianwert an. Mittels Mausklick auf den entsprechenden Balken können die Abweichungswerte direkt in der Grafik angezeigt werden. Die Boxplots der Regentage und Sonnenscheindauer sind im Bereich witterungsabhängige Kenntage dargestellt."}
                            it={"All'area dei giorni di riferimento dipendenti dalla temperatura vengono sommati i seguenti:  giorni estivi, tropicali (o caldi) e desertici, nonché notti tropicali. Il grafico a barre corrispondente sotto il boxplot mostra la deviazione dell'anno corrente dal valore mediano a lungo termine. Cliccando sulla barra corrispondente, sarà possibile visualizzare dettagliatamente i valori delle deviazioni nel grafico. I grafici dei giorni di riferimento dipendenti dal tempo (giorni di pioggia e durata dell'irraggiamento solare) sono spostati in una nuova area."}
                            en={"In the section “Rain days and Sunshine duration” boxplots of the monthly data of these parameters are displayed. The course of the selected year is shown as a line; for comparison, a boxplot per month shows the spread of the monthly comparative values for the previous 30 years. The colored box marks the area in which the middle 50% of the measured values of the past years are located, whereby the line in the middle of the box represents the median value of the previous 30 years, which indicates the middle of the data distribution. The vertical lines (whiskers) extend from the lowest to the highest measured value. The bar chart below the box plot shows the deviation of the current year from the long-term median value. The deviation values can be displayed directly in the graph by clicking on the corresponding bar."}
                        />
                    </Grid>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("kenntage")} />
                    </Grid>

                    <Grid item className="p-2">
                        {/* <ExcelButton callback={downloadExcel} /> */}
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
