import * as d3 from "d3";

import { useState } from "react";
import { useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

import ColorButton from "./ColorButton";

export default function SensorButton ({sensor, svgref, callback, index}) {

    const { t, i18n } = useTranslation();

    function merge (a, b, c)
    {
        return a + " " + (b === null ? "" : b) + " [" + c + "]";
    }

    return (

        <>
            <ColorButton
                className="flex-item"
		sx={{alignItems: "center"}}
                color={sensor.color}
                variant={sensor.active ? "contained" : "outlined"}
                callback={callback}
                name={merge (t(sensor.description), sensor.level, sensor.unit)}
                active={sensor.active}
            />
        </>

    )

}