import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../js/datetime';

import { GraphContext } from "../GraphContext";
import "../../styles.css";
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import { borders, addLine, addArea } from "../../components/d3/legend/legend";
import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';

import DatePicker from '../../components/DatePicker';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';
import { timeformat, getWidth, responsivness } from '../../components/d3/d3timeformat';


export const RainOverview = ({ description, list }) => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();

    const [parameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const [data, setData] = useState([]);

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Tagesmittelwerte', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional                                                                                                                              
        })
    }, [trackPageView, location])

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/rain/overview/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [year]);

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        draw(svg);

        let labels = [t("_sums"), t("long_sums"), t("above_middle"), t("underneath_middle")]

        let r = responsivness();
        if (r.dialog) {
            borders(svg, labels, 300, 70, 10);
            addLine(svg, 0, 2.5, "black", labels[0]);
            addLine(svg, 1, 1.5, "black", labels[1]);
            addArea(svg, 2, "green", labels[2]);
            addArea(svg, 3, "orange", labels[3]);
        }

    }, [resize, data, i18n.language]);

    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/overview/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }



    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    var xScaleTime;
    var xScaleBand;
    let brush;
    let xAxis;
    let xGrid;
    let clip;

    let yScale;
    let yScaleN;

    var height = 500;
    var width = 0;
    var margin = { top: 10, right: 20, bottom: 40, left: 70, bottonHeight: 100, offset: 20 }

    var min;
    var max;

    function draw(svg) {

        timeformat(t);

        // Clear
        svg.selectAll("*").remove();

        // setup SVG
        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        // Getting width
        width = parseInt(svg.style('width'));

        // SVG
        svg
            .attr("width", width)
            .attr("height", height);

        // Add X axis --> it is a date format
        xScaleTime = d3.scaleTime()
            .domain([d3.timeParse("%Y-%m-%d")(year + "-01-01"), d3.timeParse("%Y-%m-%d")((year + 1) + "-01-01")])
            .range([margin.left, width - margin.right]);

        let dates = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
        let filtered = dates.filter((d) => { return (d.getYear() + 1900) === year });

        xScaleBand = d3.scaleBand()
            .domain(filtered)
            .range([margin.left, width - margin.right]);


        xAxis = svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .attr("class", "axes")
            .call(d3.axisBottom(xScaleTime))
            .style("font-size", "12px");

        let bottomScale = d3.axisBottom(xScaleTime);

        // Add Y axis
        min = 0;
        max = d3.max([d3.max(data, function (d) { return +d.longterm; }),
        d3.max(data, function (d) { return +d.actualsum; })]);

        // X grid
        xGrid = svg.append("g")
            .attr('id', 'xgrid')
            .attr('transform', 'translate(0 ,' + (height - margin.bottom) + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(bottomScale.tickSize(-height + margin.bottom + margin.top, 0).tickFormat(""));

        // Y grid
        yScale = d3.scaleLinear()
            .domain([min, max])
            .range([height - margin.bottom - margin.bottonHeight - margin.offset, margin.top]);

        // Y under
        yScaleN = d3.scaleLinear()
            .domain([0, 100])
            .range([height - margin.bottom, height - margin.bottom - margin.bottonHeight]);

        // brush
        brush = d3.brushX()
            .extent([[margin.left, 0], [width - margin.right, height - margin.bottom]])
            .on("end", updateChart); // brushended

        svg.append("g")
            .attr('class', 'brush')
            .call(brush);

        // Clip
        clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        svg.append("defs")
            .append("svg:clipPath")
            .attr('id', 'clip')
            .append("svg:rect")
            .attr("x", margin.left)
            .attr("y", 0)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height);

        // Y Axis temperatur
        let axisLeft = d3.axisLeft(yScale);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .attr("class", "axes")
            .call(axisLeft.ticks(4, "0.1f").tickFormat((x) => { return x + " mm" }))
            .style("font-size", "12px");

        // Y Axis Regen
        let axisLeftN = d3.axisLeft(yScaleN);
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .attr("class", "axes")
            .call(axisLeftN.ticks(4, "0f").tickFormat((x) => { return x + " mm" }))
            .style("font-size", "12px");

        // Grid 
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeft.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        // Grid X
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(axisLeftN.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));


        // Add the lines group
        const lines = clip
            .append("g")
            .attr('id', 'lines');

        //const X = d3.map(data, (d) =>{return d3.timeParse("%Y-%m-%d")(d.date)});
        const Y1 = d3.map(data, (d) => { return d.longterm });
        //const Y2 = d3.map(data, (d) => {return d.longterm});
        //const I = d3.range(data.length);
        //const defined = (d, i) => { return Y1[i] != null };

        //const D = d3.map(data, defined);

        //const line = (y) => d3.line().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y(y)(I);
        //const area = (y0, y1) => d3.area().defined(i => D[i]).curve(d3.curveLinear).x(i => xScale(X[i])).y0(y0).y1(y1)(I);


        lines.append("path")
            .datum(data)
            .attr('id', 'path1')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "grey")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScaleTime(ld) })
                .y((d, i) => { return yScale(d.longterm) })
                .defined((d) => { return d.longterm !== null })
            );

        // Add zero line
        lines.append("line")
            .attr('id', 'zeroLine')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.0)
            .attr("x1", margin.left)
            .attr("y1", yScale(0))
            .attr("x2", width - margin.right)
            .attr("y2", yScale(0));

        // Add the line
        lines.append("path")
            .datum(data)
            .attr('id', 'path2')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .y((d, i) => { return yScale(d.actualsum) })
                .defined((d) => { return d.actualsum !== null; })
            );



        //var colors = ['rgba(0,0,0,0)', '#084594'];
        /*var getColor = d3.scaleLinear()
            .domain([0, 100])
            .range(colors);*/

        const box = clip
            .append("g");

        // 
        box.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar2")
            .style("pointer-events", "none")
            .attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", margin.top)
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr('fill', function (d) { return (d.actual == null ? '#084594' : '#084594') })
            .attr('opacity', function (d) { return (d.rain === null ? 0 : d.actual / 100) })
            .attr("height", height - margin.bottom - margin.bottonHeight - margin.offset - margin.top);

        box.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("y", (d) => { return yScaleN(d.actual) + height - margin.bottom - yScaleN(0) })
            .attr("width", (d) => { return xScaleBand.bandwidth() })
            .attr("height", (d) => { return yScaleN(0) - yScaleN(d.actual) })
            .attr('fill', '#084594');

        svg.append('line')
            .attr('id', 'cursorLine')
            .attr('stroke', 'grey')
            .attr('display', null);

        var dotgroup = svg.append("g").attr('id', 'dotgroup');

        dotgroup.append("circle")
            .attr('id', 'dot1')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);


        createArea();

        var tooltip = svg.append("g")
            .attr('id', 'tooltip')
            .style("pointer-events", "none");

        tooltip.append("path")
            .attr('id', 'pathtool')
            .data([,])
            .attr("class", "shadow")
            .attr("fill", "white")
            .attr("stroke", 'black')
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round");

        var tooltiptext = tooltip.append("text")
            .attr('id', 'tooltiptext')
            .style("pointer-events", "none");

        // Date
        tooltiptext
            .append("tspan")
            .attr('id', 'date')
            .attr("x", 15)
            .attr("y", 0)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value')
            .attr("x", 25)
            .attr("y", 20)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'delta')
            .attr("x", 25)
            .attr("y", 40)
            .attr("stroke", 'black')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        xlabel(svg, t("year"), width / 2, height - 5);

        ylabel(svg, t("rain"), -height * 0.3, 15);
        ylabel(svg, t("rain"), -height * 0.85, 15);
        logo(svg);
    }

    function createArea() {
        //const svg = d3.select(svgRef.current);

        //const X = d3.map(data, (d) =>{return d3.timeParse("%Y-%m-%d")(d.date)});
        const Y1 = d3.map(data, (d) => { return d.actualsum });
        //const Y2 = d3.map(data, (d) => {return d.longterm});
        //const I = d3.range(data.length);

        const defined = (d, i) => { return Y1[i] != null };

        clip.append('clipPath')
            .datum(data.filter(defined))
            .attr('id', 'clippath_up')
            .append('path')
            .attr('id', 'clippath_up_path')
            .attr("d", d3.area()
                //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })
                .x((d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.longterm) })
                .y1(function (d) { return yScale(max) })
                .defined((d) => d.longterm !== null)
            );

        clip.append("path")
            .datum(data.filter(defined))
            .attr('clip-path', "url(#clippath_up)")
            .attr('class', 'area1')
            .attr("fill", "green")
            .attr('opacity', 0.5)
            .attr("stroke-width", 0)
            .attr("d", d3.area()
                .x((d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.actualsum) })
                .y1(function (d) { return yScale(min) })
                .defined((d) => d.actualsum !== null)
            )

        clip.append('clipPath')
            .datum(data.filter(defined))
            .attr('id', 'clippath_down')
            .append('path')
            .attr('id', 'clippath_down_path')
            .attr("d", d3.area()
                .x((d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(min) })
                .y1(function (d) { return yScale(d.longterm) })
                .defined((d) => d.longterm !== null)
            );

        clip.append("path")
            .datum(data.filter(defined))
            .attr('clip-path', "url(#clippath_down)")
            .attr('class', 'area2')
            .attr("fill", "orange")
            .attr('opacity', 0.5)
            .attr("stroke", "#69b3a2")
            .attr("stroke-width", 0)
            .attr("d", d3.area()
                .x((d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.actualsum) })
                .y1(function (d) { return yScale(max) })
                .defined((d) => d.actualsum !== null)
            )
    }

    function pointermoved(event) {

        const X = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
        const I = d3.map(data, (_, i) => i);
        const i = d3.bisectCenter(X, xScaleTime.invert(d3.pointer(event)[0] - 5));

        // cursorline
        const svg = d3.select(svgRef.current);
        svg.selectAll('#cursorLine')
            .style('display', null)
            .attr('x1', d3.pointer(event)[0] - 5)
            .attr('x2', d3.pointer(event)[0] - 5)
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom);


        svg
            .selectAll('#dot1')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(i > 0 ? data[i - 1].actualsum : 0));
        svg
            .selectAll('#dot2')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(i > 0 ? data[i - 1].longterm : 0));

        // Textbox with values
        var tooltiptext = svg.select("#tooltiptext");
        tooltiptext.attr("transform", `translate(${0},${30 - 0})`);

        const { width: w, height: h } = tooltiptext.node().getBBox();

        var path = svg.select("#pathtool");

        if (xScaleTime(d3.timeParse("%Y-%m-%d %H:%M:%S")(data[i].date)) === width - margin.right) {
            svg.select("#tooltip")
                .style("display", 'none');
        }

        let rb = xScaleTime(X[i]) > width - 120 ? width - 120 : xScaleTime(X[i]);
        svg.select("#tooltip")
            .style("display", null)
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${rb},0)`);


        path
            .style('display', null)
            .transition()
            .duration(100)
            .attr('d', `M 0 20 l 10 -10 H${w + 20} V${h + 25} H10 V25 Z`);

        svg.select("#date")
            .transition()
            .duration(200)
            .text(data[i].date + ':\n');


        svg.select("#value")
            .transition()
            .duration(200)
            .text(Math.round(data[I[i]].actualsum * 10) / 10 + " mm ");

        svg.select("#delta")
            .transition()
            .duration(200)
            .text('Δ ' + Math.round((data[I[i]].actualsum - data[I[i]].longterm) * 10) / 10 + " mm");

        let x_pos = d3.pointer(event)[0];
        if (x_pos < 400) {
            //svg.selectAll('#legend').attr('display', 'none');
            svg.selectAll('#legend').attr('opacity', 0.1)
        }
        else {
            //svg.selectAll('#legend').attr('display', null);
            svg.selectAll('#legend').attr('opacity', 1)
        }

        if (data[I[i]].actualsum === null) {
            svg.selectAll('#tooltip').attr('display', 'none');
            svg.selectAll('#dot1').attr('display', 'none');
        }
        else {
            svg.selectAll('#tooltip').attr('display', null);
            svg.selectAll('#dot1').attr('display', null);
        }

    }

    function pointerleft() {
        const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)
    }

    var idleTimeout

    function idled() { idleTimeout = null; }

    function updateChart(event) {

        const svg = d3.select(svgRef.current);

        var extentX = event.selection
        var t1;

        if (!extentX) {
            //RESET

            if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

            //xScale.domain(d3.extent(data, function (d) { return d3.timeParse("%Y-%m-%d")(d.date); }));
            xScaleTime.domain([d3.timeParse("%Y-%m-%d")(year + "-01-01"), d3.timeParse("%Y-%m-%d")((year + 1) + "-01-01")])

            let dates = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
            let filtered = dates.filter((d) => { return (d.getYear() + 1900) === year });
            //xScaleBand.domain(d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date)));
            xScaleBand.domain(filtered);
        }
        else {
            var from = xScaleTime.invert(extentX[0]);//datetime
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
            //from.setDate(from.getDate() + 1);
            var to = xScaleTime.invert(extentX[1]);
            to.setHours(0);
            to.setMinutes(0);
            to.setSeconds(0);
            //to.setDate(to.getDate() + 1);

            // Auf und abrunden
            xScaleTime.domain([from, to]);
            //.x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScale(ld) })

            var t = d3.map(data, d => d3.timeParse("%Y-%m-%d")(d.date));
            //var t = d3.map(data, d => {let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return ld});
            t1 = t.filter(item => item >= from && item < to);
            t1.forEach((d) => { d.setDate(d.getDate() - 1) });
            xScaleBand.domain(t1);

            // This remove the grey brush area as soon as the selection has been done
            svg.select(".brush").call(brush.move, null)

        }


        // Bottom
        xAxis.transition()
            .duration(1000)
            .call(d3.axisBottom(xScaleTime));

        // Bottom grid
        xGrid.transition()
            .duration(1000)
            .call(d3.axisBottom(xScaleTime).tickSize(- height + margin.top + margin.bottom, 0).tickFormat(""));

        // lines
        svg.selectAll("#path1")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.longterm) }));
        // lines
        svg.selectAll("#path2")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.actualsum) }));


        clip.selectAll("#clippath_up_path")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScaleTime(ld) })
                .y0(function (d) { return yScale(d.longterm) })
                .y1(function (d) { return yScale(max) })
            );
        svg.selectAll(".area1")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x(function (d) { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.actualsum) })
                .y1(function (d) { return yScale(min) })
            )

        svg.selectAll("#clippath_down_path")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x((d, i) => { let ld = new Date(d.date); ld.setDate(ld.getDate() + 1); return xScaleTime(ld) })
                .y0(function (d) { return yScale(min) })
                .y1(function (d) { return yScale(d.longterm) })
            );
        svg.selectAll(".area2")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x(function (d) { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0(function (d) { return yScale(d.actualsum) })
                .y1(function (d) { return yScale(max) })
            )

        // react
        svg.selectAll(".bar")
            .transition()
            .duration(1000)
            .attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("width", () => { return xScaleBand.bandwidth() });
        svg.selectAll(".bar2")
            .transition()
            .duration(1000)
            .attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d")(d.date)) })
            .attr("width", () => { return xScaleBand.bandwidth() });

    }


    return (
        <>
            <Stack spacing={1}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

            </Stack>

            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Die Niederschlagsgrafik vergleicht den Niederschlagsverlauf innerhalb eines rechts oben frei wählbaren Jahres mit dem Verlauf des langjährigen Mittelwertes. Die Grafik bezieht sich auf die Daten der ausgewählten Wetterstation und eines bestimmten Jahres. Im oberen Bereich werden die im Jahresverlauf aufsummierten Tageswerte mit den Mittelwerten der jeweils vorangehenden 30 Jahre dargestellt. Bereiche, die über dem langjährigen Durchschnitt liegen, werden grün markiert, während Bereiche unter dem Durchschnitt gelb eingefärbt sind. Durch einen Mausklick auf den entsprechenden Bereich kann der aufsummierte Wert und die tägliche Abweichung zum langjährigen Mittelwert (Δ) abgelesen werden. Zusätzlich werden im unteren Bereich, die täglichen Niederschläge gezeigt, welche auch im Hintergrund des oberen Bereiches eingeblendet werden. Die aktuellen Werte des laufenden Jahres werden täglich aktualisiert."}
                        it={"Il grafico delle precipitazioni confronta l'andamento delle precipitazioni di un anno liberamente selezionabile in alto a destra con l'andamento della media a lungo termine. Il grafico si riferisce ai dati della stazione meteorologica selezionata e a un anno specifico. Nella parte superiore, i valori giornalieri totalizzati nel corso dell'anno sono mostrati con i valori medi dei 30 anni precedenti. Le aree superiori alla media a lungo termine sono evidenziate in verde, mentre quelle inferiori alla media sono colorate in giallo. Facendo clic sull'area corrispondente, è possibile leggere il valore totale e la deviazione giornaliera dalla media a lungo termine (Δ). Inoltre, nell'area inferiore vengono mostrate le precipitazioni giornaliere, visualizzate anche sullo sfondo dell'area superiore. I valori attuali per l'anno in corso vengono aggiornati quotidianamente."}
                        en={"The precipitation diagram compares the course of precipitation within a freely selectable year at the top right with the course of the long-term average. The graph refers to the data of the selected weather station and a specific year. In the upper area, the daily values summed up over the course of the year are shown with the average values of the previous 30 years. Areas above the long-term average are highlighted in green, while areas below the average are colored yellow. By clicking on the corresponding area, the summed value and the daily deviation from the long-term average (Δ) can be read. In addition, the daily precipitation is shown in the lower area, which is also displayed in the background of the upper area. The current values for the current year are updated daily."}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("rain_overview")} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>

            </Grid>

        </>
    );

}
