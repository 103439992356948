import { useState, useContext, useEffect } from "react";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { GraphContext } from "../../../GraphContext";

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Cols } from './Cols';

import { useTranslation } from 'react-i18next';

// date.substring(1).replaceAll('S', '-')

export const Row = ({ date, row, keys, loc, color }) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <TableRow sx={{ overflow: 'auto', '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: color !== undefined ? color : "#ffffff" }}>
                
                <TableCell key={date} align="right">
                    {date === 1 ? t('january') : ''}
                    {date === 2 ? t('february') : ''}
                    {date === 3 ? t('march') : ''}
                    {date === 4 ? t('april') : ''}
                    {date === 5 ? t('may') : ''}
                    {date === 6 ? t('june') : ''}
                    {date === 7 ? t('july') : ''}
                    {date === 8 ? t('august') : ''}
                    {date === 9 ? t('september') : ''}
                    {date === 10 ? t('october') : ''}
                    {date === 11 ? t('november') : ''}
                    {date === 12 ? t('december') : ''}

                    {date === "total" ? t('year') : ''}
                    {date === "mid" ? t('mid_30') : ''}
                    {date === "long" ? t('mid_total') : ''}

                </TableCell>

                {keys.map((key, index) => {
                    let i = Math.floor(Math.random() * 10000);
                    return (
                        <>
                            <Cols key={i} index={index} values={row[key.name]} cat={key.name} loc={loc}/>
                        </>
                    )
                })}

            </TableRow>
        </>
    )

}