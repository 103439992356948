import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { saveAs } from 'file-saver';
import Button from '@mui/material/Button';
import { GraphContext } from '../routes/GraphContext';

export default function GraphicButton({ object, filename }) {

    const { trackPageView, trackEvent } = useMatomo()

    const { t, i18n } = useTranslation();

    const [parameter, setParameter] = useContext(GraphContext);

    const exportDiagramm = (() => {

        console.log("Downloading ...", object);
        trackEvent({ category: 'Grafic Download', action: filename })

        const svg = document.getElementById(object);

        if (svg === null)
            return;

        //get svg source.
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svg);

        //add name spaces.
        if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        //convert svg source to URI data scheme.
        var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

        let date = new Date();
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        var station = parameter.station_name === undefined ? "Laimburg" : parameter.station_name;
        
        saveAs(url, station + "-" + filename + "-" + formattedDate + ".svg");

        trackEvent({
            category: 'Downloads',
            action: 'SVG',
            name: filename + "-" + formattedDate + ".svg", // optional
            href: 'https://meteo.laimburg.it'
        })
    });

    return (
        <>
            <Button
                onClick={exportDiagramm}
                color="primary"
                style={{
                    backgroundColor: "#65626",//"#656263", #B1914E
                    margin: "10px",
                    fontSize: "14px"
                }}
                className="m-1"
                variant="contained"
            >
                {t("export_graphic")}
            </Button>
        </>
    )

}
