import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../../js/datetime';

import { GraphContext } from "../../GraphContext";
import "../../../styles.css";
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import { preferences } from "../../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from '@mui/material/styles';

import ExcelButton from '../../../components/ExcelButton';
import GraphicButton from '../../../components/GraphicButton';
import InfoButton from '../../../components/InfoButton';

import { xlabel, ylabel, logo } from '../../../components/d3/charts/chart';

import ComboBox from '../../../components/ComboBox';
import DatePicker from '../../../components/DatePicker';
import SensorComboBox from '../../../components/SensorComboBox';

import { borders, legend, addLine, addArea } from '../../../components/d3/legend/legend';
import { timeformat, getWidth, responsivness, getMonths } from '../../../components/d3/d3timeformat';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const Proplot = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const [t, i18n] = useTranslation();
    const [parameter] = useContext(GraphContext);
    const [sensors, setSensors] = useState();
    const [fromdjs, setFromdjs] = useState(dayjs());
    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());

    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());
    const [classification, setClassification] = useState(1);
    const [sensor, setSensor] = useState(1);
    const [sensorstring, setSensorstring] = useState(); // "airtemp2m"

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Allgemein Boxplot', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }); // , [resize, data]

    /* Sensors */
    useEffect(() => {

        let station = parameter.station;
        var url = preferences.address + '/rest/sensors.php?station=' + station;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                data.map((sensor) => {
                    sensor.main = true;
                    sensor.min = false;
                    sensor.max = false;
                    return 0;
                })

                setSensors(data);
                setSensorstring("airtemp2m");
                setSensor(2);
            });
    }, [parameter])

    useEffect(() => {
        setData(undefined);
        if (sensorstring !== undefined) {
            switch (classification) {
                case 1:
                    d3.json(preferences.address + "/query/seasons/proplot/season/get.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year)
                        .then((data) => {
                            setData(data);
                        });
                    break;
                case 2:
                    d3.json(preferences.address + "/query/seasons/proplot/month/get.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year)
                        .then((data) => {
                            setData(data);
                        });
                    break;
                default:
                    break;
            }
        }
    }, [i18n.language, parameter, year, classification, sensorstring]);

    const downloadExcel = () => {

        var url;
        switch (classification) {
            case 1:
                url = preferences.address + "/query/seasons/proplot/season/excel.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year;
                break;
            case 2:
                url = preferences.address + "/query/seasons/proplot/month/excel.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year;
                break;
            default:
                break;
        }


        let block = {};
        block.station = parameter.station;
        block.sensor = sensorstring;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }


    let width = 0;

    function draw(svg) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 10,
            right: 100,
            left: 80,
            bottom: 40
        }; // margin of chart

        // Getting width
        width = parseInt(svg.style("width"));

        let height = 400;
        let inner_width = width - margin.right - margin.left;

        let min = 0;
        let max = 0;

        if (data !== undefined) {
            let keys = Object.keys(data.keys);
            min = -100;
            max = 100;
        }

        svg
            .attr("width", width)
            .attr("height", height);

        //let seasons = data.keys;
        // Create scale

        // NULL Linie
        let xScaleLinear = d3.scaleLinear()
            .domain([1, data.keys.length])
            .range([0, width - margin.right - margin.left])

        let xScaleBand = d3.scaleBand()
            .domain(data.keys)
            .range([0, width - margin.right - margin.left])
            .padding(0.3);

        let xScaleBandLabel = d3.scaleBand()
            //.domain(data.labels.map((d) => {return t(d) })) // seasons
            .domain(classification === 1 ? data.labels.map((d) => { return t(d) }) : getMonths(t))
            .range([0, width - margin.right - margin.left])
            .padding(0.3);

        let xScaleBandGrid = d3.scaleBand()
            .domain(data.keys)
            .range([0, width - margin.right - margin.left]);

        //let s = xScaleBand.bandwidth() / 2;

        /*let xScaleLinear = d3.scaleLinear()
            .domain([d3.min(data.keys), d3.max(data.keys)])
            .range([margin.left + s, width - s - margin.right]);*/

        // Add scales to axis
        var x_axis = d3.axisBottom()
            .scale(xScaleBandLabel);

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + (height - margin.bottom) + ")")
            .attr("class", "axes")
            .call(x_axis);

        // Create scale
        var yScale = d3.scaleLinear()
            .domain([-100, 100])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale)

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("class", "axes")
            .call(y_axis.ticks(10, "0.1f").tickFormat((x) => { return x + " %" }));

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ", 0)")
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-inner_width, 0).tickFormat(""));

        svg
            .selectAll("xgrid")
            .data(data.keys)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("y1", height - margin.bottom)
            .attr("y2", margin.top)
            .attr("stroke", "grey")
            .attr("stroke-width", 1);
        svg
            .selectAll("xgrid")
            .data(data.keys)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) })
            .attr("y1", height - margin.bottom)
            .attr("y2", margin.top)
            .attr("stroke", "grey")
            .attr("stroke-width", 1);

        var yScaleRight = d3.scaleLinear()
            .domain([-5, 5])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        var y_axis = d3.axisRight()
            .scale(yScaleRight);

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + (width - margin.right) + ", 0)")
            .call(y_axis.ticks(10, "0.1f").tickFormat((x) => { return x + " °C" }));

        // Grid horizontal
        /*svg.append("g")
            .attr('transform', 'translate(' + margin.left + ", 0)")
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-inner_width, 0).tickFormat(""));*/

        // rectangle for the main box

        let s = xScaleBand.bandwidth() / 2;

        let datagroup = svg.append('g').attr('id', 'datagroup');
        let dataline = svg.append('g').attr('id', 'dataline');

        /*svg.append("path")
            .datum (data.sun)
                .attr("transform", "translate("+margin.left+"," + (margin.top) + ")")
            .attr("id", "sun")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
            .x((d, i) => { return xScaleBand(d.month) + xScaleBand.bandwidth()/2 })
            .y((d) => { return yScale(d.diff) })
            )
    
        svg.append("path")
            .datum (data.rain)
                .attr("transform", "translate("+margin.left+"," + (margin.top) + ")")
            .attr("id", "rain")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", "blue")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
            .x((d, i) => { return xScaleBand(d.month) + xScaleBand.bandwidth()/2 })
            .y((d) => { return yScale(d.diff) })
            .defined((d) => {return d.diff != null})
            );*/

        // Grouped bar chart
        // color palette = one color per subgroup
        var color = d3.scaleOrdinal()
            .domain(data.keys)
            .range(['#e41a1c', '#377eb8', '#4daf4a'])

        svg.append("path")
            .datum(data.temperature)
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("id", "temperature")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScaleLinear(d.month) })
                .y((d) => { return yScaleRight(0) })
            )

        // Show the bars
        svg.selectAll(".bar")
            .data(data.sun)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ",  0)")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", (d) => { return xScaleBand(d.month) })
            .attr("y", (d) => { return yScale(d.diff) < yScale(0) ? yScale(d.diff) : yScale(0) })
            .attr("width", xScaleBand.bandwidth() / 2)
            .attr("height", (d) => { return yScale(d.diff) > yScale(0) ? yScale(d.diff) - yScale(0) : yScale(0) - yScale(d.diff) })
            .attr("stroke", "black")
            .attr("opacity", 0.7)
            .attr("fill", "orange")

        svg.selectAll(".bar2")
            .data(data.rain)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + margin.left + ",0 )")
            .attr("class", "bar")
            .style("pointer-events", "none")
            .attr("x", (d) => { return xScaleBand(d.month) })
            .attr("y", (d) => { return yScale(d.diff) < yScale(0) ? yScale(d.diff) : yScale(0) })
            .attr("width", xScaleBand.bandwidth() / 2)
            .attr("height", (d) => { return yScale(d.diff) > yScale(0) ? yScale(d.diff) - yScale(0) : yScale(0) - yScale(d.diff) })
            .attr("stroke", "black")
            .attr("opacity", 0.7)
            .attr("fill", "steelblue")

        /* Temperatur */
        svg.append("path")
            .datum(data.temperature)
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("id", "temperature")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x((d, i) => { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
                .y((d) => { return yScaleRight(d.diff) })
            )

        svg.selectAll("circles")
            .data(data.temperature)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("fill", "white")
            .attr("stroke", "green")
            .attr('display', (d, i) => { return d.diff === null ? 'none' : ''; })
            .attr("cx", function (d, i) { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
            .attr("cy", function (d) { return yScaleRight(d.diff) })
            .attr("r", 4)


        /*datagroup
            .selectAll("vertLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) })
            .attr("y1", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        datagroup
            .selectAll("upperLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        datagroup
            .selectAll("lowerLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)*/


        const colors = ["#641e16", "#78281f", "#512e5f", "#154360", "#1b4f72", "#0e6251", "#0b5345", "#0b5345",
            "#7d6608", "#7e5109", "#784212", "#6e2c00", "#7b7d7d", "#626567", "#4d5656", "#424949", "#1b2631"];

        /*datagroup
            .selectAll("boxes")
            .data(data.seasons)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", function (d, index) { return xScaleBand(index + 1) })
            .attr("y", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.75)) })
            .attr("height", function (d) { return (yScale(d3.quantile(d.map((e) => { return e.val }), 0.25)) - yScale(d3.quantile(d.map((e) => { return e.val }), 0.75))) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", colors[sensor])
            .attr("rx", 5);

        datagroup
            .selectAll("horLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + 2*s })
            .attr("y1", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("y2", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        const l = [];
        //console.log (data.seasons);
        try {
                data.seasons.map((e, i) => {
                    e.map((j, k) => { let t = {}; t.season = j.season; t.val = j.val; l.push(t); return 0;}); return 0; 
                });
        } catch (err) {console.log (err);}

        var jitterWidth = 50;
        datagroup
            .selectAll("points")
            .data(l)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("cx", function (d, index) { return xScaleBand(d.season) - jitterWidth / 2 + Math.random() * jitterWidth })
            .attr("cy", function (d, index) { return yScale(d.val) })
            .attr("r", 2)
            .style("fill", "white")
            .attr("stroke", "blue");

        // Add the line current year
        dataline.append("path")
            .datum(data.current)
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x(function (d, index) { return xScaleBand(d.season) })
                .y(function (d) { return yScale(d.val) })
            )

        dataline.selectAll("circles")
            .data(data.current)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "white")
            .attr("stroke", "orange")
            .attr("cx", function (d) { return xScaleBand(d.season)})
            .attr("cy", function (d) { return yScale(d.val) })
            .attr("r", 4)*/

        xlabel(svg, t(data.xLabel), width / 2, height - 5);

        ylabel(svg, t(data.yLabel), -height * 0.5, 15);
        logo(svg);

        let res = responsivness();
        if (res.dialog) {
            borders(svg, ['', '', ''], 300, width - 400, 10);
            addLine(svg, 0, 5, "green", "Δ " + t("temperature") + " [°C]");
            addArea(svg, 1, "steelblue", "Δ " + t("precipitation") + " [%]");
            addArea(svg, 2, "orange", "Δ " + t("dursunshine") + " [%]");
        }
    }

    const handleChangeClassification = (event) => {
        setClassification(event.target.value);
    };

    let c = [t("seasons"), t("months")];

    return (
        <>
            <Stack spacing={0}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

                <Item>
                    <ComboBox
                        title={"classification"}
                        value={classification} items={c}
                        callback={handleChangeClassification} />
                </Item>

            </Stack>

            <div id="svgchart">
                {data === undefined ? <CircularProgress /> : <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>}
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"<p>Die interaktive Analysegrafik „Proplot“ zeigt auf Monats- oder Jahreszeitenbasis die Abweichung der Temperatur, des Niederschlags und der Sonnenscheindauer eines ausgewählten Jahres vom Mittel der vorangegangenen 30 Jahre. Die Wetterstation und die Einteilung in Monate oder Jahreszeiten können über das Auswahlmenü bestimmt werden.</p><p>In der Grafik wird die Abweichung der Temperatur, Niederschlag und Sonnenscheindauer auf Jahreszeiten und Monatsbasis im Vergleich zum langjährigen Vergleichswert der vorhergehenden 30 Jahre gezeigt. Die Wetterstation und die Einteilung in Monate oder Jahreszeiten können über das Menu rechts oben ausgewählt werden.</p>"}
                        it={"<p>Il grafico di analisi interattivo “Proplot” mostra la deviazione della temperatura, delle precipitazioni e della durata dell'insolazione di un anno selezionato rispetto alla media dei 30 anni precedenti su base mensile o stagionale. La stazione meteorologica e la suddivisione in mesi o stagioni possono essere determinate tramite il menu a destra sopra il grafico.</p> <p>Il grafico interattivo “Proplot” mostra su base mensile o stagionale le deviazioni della temperatura, della precipitazione e dell'insolazione solare di un anno selezionato rispetto alla media dei 30 anni precedenti. La stazione meteorologica e la suddivisione in mesi o stagioni possono essere determinate tramite il menu a selezione. </p>"}
                        en={"<p>The interactive analysis graphic “Proplot” shows the deviation of the temperature, precipitation and sunshine duration of a selected year from the average of the previous 30 years on a monthly or seasonal basis. The weather station and the division into months or seasons can be determined via the selection menu. The graph shows the deviation of temperature, precipitation and sunshine duration on a seasonal and monthly basis compared to the long-term reference value of the previous 30 years. The weather station and the division into months or seasons can be displayed via the menu at the top right.</p>"}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object={"svg_chart"} filename={t("boxplot") + "-" + t(sensorstring)} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>
            </Grid>

        </>
    );

}
