import * as d3 from "d3";
import { useTranslation } from "react-i18next";
import { timeformat }from "../d3timeformat";


function setup (svg, data, sensors, level = 1, top, bottom, left, right, t = null) {

	if (typeof (data.timeline) == "undefined")
		return;

	/*var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);*/
	//timeformat (t);
	

	var format = d3.timeFormat("%d.%m - %H:%M");

	const tickFormat = function (date) {
		if (date.getMinutes()) return d3.timeFormat('%H:%M')(date);
		if (date.getHours()) return d3.timeFormat('%H:%M')(date);
		if (date.getDay() && date.getDate() !== 1) return d3.timeFormat('%d.%m')(date); // Neuer Tag
		if (date.getDate() !== 1) return d3.timeFormat('%d.%m.')(date);
		if (date.getMonth()) return d3.timeFormat('%B')(date);
		return d3.timeFormat('%Y')(date);
	}

	// Clean
	svg.selectAll("*").remove();

	svg
		.attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
		.style("overflow", "visible")
		.style("-webkit-tap-highlight-color", "transparent")
		.on("pointerenter pointermove", pointermoved)
		.on("pointerleave", pointerleft)
		.on("touchstart", event => event.preventDefault());

	// variable
	var width = parseInt(svg.style('width'));
	var height = data.height;

	var margins = { width: width, height: height, top: top, right: right, bottom: bottom, left: left }

	var width_netto = width - margins.left - margins.right;

	svg.attr('width', width)
		.attr('height', height)
		.append('g')
		.attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

	let xScale = d3.scaleTime()
		.domain([data.minTime, data.maxTime])
		.range([margins.left, margins.width - margins.right]);

	let bottomScale = d3.axisBottom(xScale);
	let xAxis = svg.append("g")
		.attr('id', 'xAxis')
		.attr('transform', 'translate(0 ,' + (margins.height - margins.bottom) + ')')
		.attr("class", "axes")
		.style("font-size", "12px")
		.call(bottomScale.tickFormat((d) => { return tickFormat(d); }));

	let xScaleBand = d3.scaleBand()
		.domain(d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d)))
		.range([margins.left, margins.width - margins.right]).padding(0);

	// Grid
	var xGrid = svg.append("g")
		.attr('id', 'xgrid')
		.attr('transform', 'translate(0 ,' + (margins.height - margins.bottom) + ')')
		.style("pointer-events", "none")
		.attr("class", "gridline")
		.call(bottomScale.tickSize(-margins.height + margins.top + margins.bottom, 0).tickFormat(""));

	const brush = d3.brushX()
		.extent([[margins.left, margins.top], [width - margins.right, height - margins.bottom]])
		.on("end", updateChart); // brushended

	svg.append("g")
		.attr('class', 'brush')
		.call(brush);

	// ---------------
	var clip = svg.append('g')
		.attr("clip-path", "url(#clip)")

	svg.append("defs")
		.append("svg:clipPath")
		.attr('id', 'clip')
		.append("svg:rect")
		.attr("x", margins.left)
		.attr("y", 0)
		.attr("width", margins.width - margins.left - margins.right)
		.attr("height", margins.height + 500);

	var yScale = [];
	const boxes = data.boxes;

	/* Scale & Y-ticks */
	if (typeof (boxes) !== "undefined") {
		boxes.map((box, i) => {

			var box2 = clip.append("g").attr('id', "box_" + i);

			switch (box.typ) {
				case ("line"):

					yScale[i] = d3.scaleLinear()
						.domain([box.min, box.max])
						.range([margins.top + box.offset + box.height, margins.top + box.offset]);

					let axisLeft = d3.axisLeft(yScale[i]);

					// Ticks
					svg.append("g")
						.attr('transform', 'translate(' + margins.left + ' , 0)')
						.style("font-size", "12px")
						.call(axisLeft.ticks(4, "0.1f").tickFormat(x => x + " " + box.unit));

					//grid
					svg.append("g")
						.attr('transform', 'translate(' + margins.left + ' , 0)')
						.style("pointer-events", "none")
						.attr("class", "gridline")
						.call(axisLeft.tickSize(-width_netto, 0).tickFormat(""));
					break;
				case ("stick"):
				case ("time"):
				case ("direction"):

					yScale[i] = d3.scaleLinear()
						.domain([box.min, box.max])
						.range([margins.top + box.offset + box.height, margins.top + box.offset]);

					box2
						.append("rect")
						.attr("width", margins.width - margins.right - margins.left)
						.attr('fill', 'none')
						.attr('stroke', '#000000')
						.attr("stroke-width", 1.5)
						.attr("x", margins.left)
						.attr("y", margins.top + box.offset)
						.attr("height", 20);
					break;
			}
		})
	}

	/* Dot group */
	var dotgroup = svg.append("g").attr('id', 'dotgroup');

	var myColorsA = ['rgba(0,0,0,0)', d3.hsl("orange")];
	var colorsWind = ['rgba(0,0,0,1)', 'rgba(0,255,0,1)', 'rgba(255,0,0,1)', 'rgba(0,0,255,1)', 'rgba(0,0,0,1)', 'rgba(0,255,0,1)',];

	var colors = ['rgba(0,0,0,0)', '#084594'];// '#084594'
	var getColor = d3.scaleLinear()
		.domain([0, 5])
		.range(colors);


	var getColorTime = d3.scaleLinear()
		.domain([0, 3600])
		.range(myColorsA);


	d3.scaleLinear().domain([0, 3600]).range(myColorsA);

	var getColorWind = d3.scaleLinear()
		.domain([0, 90, 180, 270, 360, 450])
		.range(colorsWind);

	function colorFunc (c){
		let s;
		switch (level)
		{
			case 1:
				s = 3600*24;
				break;
			case 2:
				s = 3600;
				break;
			case 3:
				s = 600;
				break;
		}
		return d3.scaleLinear().domain([0, s]).range(['rgba(255,255,255, 1)', c]);
	}  

	let index = 0;

	/* Data */
	if (typeof (boxes) !== "undefined") {
		boxes.map((p, i) => {

		    var box = clip.select("#box_" + i);
			
		    // X Label
		    svg.append("text")
			//.attr("class", "x label")
			.attr("text-anchor", "start")
			.attr("x", 70)
			.attr("y", p.offset + 15)
			.attr("font-size", "14px")
			.text(t === null ? "" : t(p.title) );
		    
			p.buckets.map((a, j) => {
				switch (p.typ) {

					case ("line"):

						dotgroup.append("circle")
							.attr('id', 'dot' + index)
							.style('display', 'none')
							.style("stroke", "grey")
							.style("fill",data.sensors[index].color)
							.attr("r", 3);

						var line = d3.line()
							.x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.y((d) => { return (d.value === null ? null : yScale[i](d.value)) })
							.defined(((d) => { return d.value !== null;} ));
							
						var area = d3.area()
							.x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.y0((d) => { return (d.value === null ? null : yScale[i](d.value)) })
							.y1((d) => { return (yScale[i](0)) })
							.defined(((d) => { return d.value !== null;} ));

						switch (a.typ){
						    case 1:
						    case 3:
							//console.log (d3.color(data.sensors[index].color).attr('opacity', 0.5));
							if (a.sensor.cat !== "globalrad")
							{
							box.append("path")
								.attr('id', 'path_' + index)
								.datum(a.list)
								.style("pointer-events", "none")
								.attr("fill", 'none')
								.attr("stroke", data.sensors[index].color)//d3.schemeSet1[index])
								.attr("stroke-width", 1.5)
								.attr('display', sensors[index].active ? null : 'none')
								.attr("d", line);
							} else {
							let col = data.sensors[index].color;
							//col.opacity=0.3;
							box.append("path")
								.attr('id', 'path_' + index)
								.datum(a.list)
								.style("pointer-events", "none")
								.attr("fill", data.sensors[index].color)
								.style("opacity", 0.8)
								.attr("stroke", "rgb(0,0,0,1)" )//d3.schemeSet1[index])
								.attr("stroke-width", 1)
								.attr('display', sensors[index].active ? null : 'none')
								.attr("d", area);
							}
							break;
						    default:
							box.append('g')
								.selectAll("#rect_" + index)
								.data(a.list)
								.enter().append("rect")
								.attr("class", "bar" + index)
								.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
								.attr("y", (d) => { return yScale[index](d.value) })
								.attr("width", () => { return xScaleBand.bandwidth() })
								.attr('fill', data.sensors[index].color)
								.attr("height", (d) => { return yScale[index](0) - yScale[index](d.value) })
								.attr('display', sensors[index].active ? null : 'none')
								;
							break;
						}
						break;

					case ("stick"):
						box.append('g')
							.selectAll("#rect_" + index)
							.data(a.list)
							.enter().append("rect")
							.attr("class", "bar" + index)
							.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.attr("y", margins.top + p.offset)
							.attr("width", () => { return xScaleBand.bandwidth() })
							.attr('fill', function (d) { return (d.value === null ? "rgba(0,0,0,0)" : getColor(d.value)) })
							.attr('stroke', 'none')
							.attr('display', sensors[index].active ? null : 'none')
							.attr("height", 20);
						break;
					case ("time"):
						box.append('g')
							.selectAll("#rect_" + index)
							.data(a.list)
							.enter().append("rect")
							.attr("class", "bar" + index)
							.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.attr("y", margins.top + p.offset)
							.attr("width", () => { return xScaleBand.bandwidth() })
							//.attr('fill', function (d) { return (d.value == null ? "rgba(0,0,0,0)" : getColorTime(d.value)) })
							.attr('fill', function (d) { return (d.value == null ? "rgba(0,0,0,0)" : colorFunc(data.sensors[index].color)(d.value)) })
							//.attr('fill', function (d) { return (d.value === null ? "rgba(0,0,0,0)" : d3.scaleLinear().domain([0, 600]).range(d.color)(d.value) ) })
							.attr('stroke', 'none')
							.attr('display', sensors[index].active ? null : 'none')
							.attr("height", 20);
						break;
					case ("direction"):
						box.append('g')
							.selectAll("#rect_" + index)
							.data(a.list)
							.enter().append("rect")
							.attr("class", "bar" + index)
							.attr("x", function (d) { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
							.attr("y", margins.top + p.offset)
							.attr("width", () => { return xScaleBand.bandwidth() })
							.attr('fill', function (d) { return (d.arc === null ? "rgba(0,0,0,0)" : getColorWind(d.arc)) })
							.attr('stroke', 'none')
							.attr('display', sensors[index].active ? null : 'none')
							.attr("height", 20);
						break;
				}
				index++;
			})

		})

	}

	/* Time points */
	const X = d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d));
	/* Index points */
	const I = d3.map(data.timeline, (_, i) => i);

	var legend = svg.append("g")
		.attr('id', 'legend')
		.attr("class", "legend")
		.attr("x", width - 65)
		.attr("y", 25)
		.attr("height", margins.height)
		.attr("width", margins.width);

	// Legend
	index = 0;
	let index2 = 0;
	if (typeof (boxes) !== "undefined") {

		var tooltip = svg.append("g")
			.style("pointer-events", "none")
			.attr('id', 'tooltip');

		/*  */
		var path = tooltip.append("path")
			.attr('id', 'path')
			.data([,])
			.attr("class", "shadow")
			.attr("fill", "white")
			.attr("stroke", 'black')
			.attr("stroke-width", 1.5)
			.attr("stroke-linejoin", "round")
			.attr("stroke-linecap", "round");

		svg.append('line')
			.attr('id', 'cursorLine')
			.attr('stroke', 'grey')
			.attr('display', null);

		var tooltiptext = tooltip.append("text")
			.attr('id', 'tooltiptext')
			.style("pointer-events", "none")
			.attr('id', 'tooltiptext');

		// Date
		tooltiptext
			.append("tspan")
			.attr('id', 'date')
			.attr('text-anchor', 'start')
			.attr('font-size', 'smaller');

		// Tooltiptext Values
		let local = 0;
		if (typeof (boxes) != "undefined") {
			boxes.map((p, i) => {
				p.buckets.map((a, j) => {
					if (sensors[index].active) local++;
					tooltiptext
						.append("tspan")
						.attr('id', 'value' + index)
						.attr("x", 15)
						.attr("y", local * 20)
						.attr("stroke", sensors[index].color)
						.attr('text-anchor', 'start')
						.attr('display', sensors[index].active ? null : 'none')
						.attr('font-size', 'smaller');
					index++;
					
				})
			})
		}
	}


	function pointermoved(event) {

		//const boxes = data.boxes;
		//const X = d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d));
		const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0] - 5));

		svg.selectAll('#dotgroup')
			.style('display', null);

		// dots
		var dotgroup = svg.select('#dotgroup')

		// cursorline
		svg.selectAll('#cursorLine')
			.style('display', null)
			.attr('x1', xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])))
			.attr('x2', xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])))
			.attr('y1', margins.top)
			.attr('y2', height - margins.bottom);


		// Tooltip text
		let index = 0;
		boxes.map((p, m) => {
			p.buckets.map((a, n) => {
				svg.select("#date")
					.attr("x", 15)
					.attr("y", (_, i) => `${index * 1.1}`)
					.transition()
					.duration(200)
					.text(format(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])));
				switch (p.typ) {
					case ("line"):
						dotgroup
							.select('#dot' + index)
							.style('display', null)
							.attr("cx", xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])))
							.attr("cy", +yScale[m](a.list[i] !== undefined ? a.list[i].value: 0 ))
							.attr('display', sensors[index].active ? null : 'none');
						svg.select("#value" + index)
							.transition()
							.duration(200)
							.text(a.list[I[i]].value !== null ? Math.round(a.list[I[i]].value * 10) / 10 + " " + a.sensor.unit : "");
							//.text(Math.round(a.list[I[i]] !== "" ? a.list[I[i]].value * 10 : 0) / 10 + " " + a.sensor.unit);
						var rb = xScale(X[i])> width - 100 ? width - 100 : xScale(X[i]);
						svg.select("#tooltip")
							.style("display", null)
							.attr("text-anchor", "middle")
							.attr("transform", `translate(${rb},0)`);
						break;
					case ("stick"):
					case ("direction"):
						svg.select("#value" + index)
							.transition()
							.duration(200)
							.text(Math.round(a.list[I[i]] != undefined ? a.list[I[i]].arc * 10 : 0) / 10 + " " + "°");
						var rb = xScale(X[i])> width - 100 ? width - 100 : xScale(X[i]);
						svg.select("#tooltip")
							.style("display", null)
							.attr("text-anchor", "middle")
							.attr("transform", `translate(${rb},0)`);
						break;
					case ("time"):
						svg.select("#value" + index)
							.transition()
							.duration(200)
							.text(Math.round(a.list[I[i]] != undefined ? a.list[I[i]].value * 10 : 0) / 10 + " " + a.sensor.unit);
						var rb = xScale(X[i])> width - 100 ? width - 100 : xScale(X[i]);
						svg.select("#tooltip")
							.style("display", null)
							.attr("text-anchor", "middle")
							.attr("transform", `translate(${rb},0)`);
						break;
				}
				index++;
			})
		})

		// Textbox with values
		var tooltiptext = svg.select("#tooltiptext");
		tooltiptext.attr("transform", `translate(${0},${30 - 0})`);

		const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

		var path = svg.select("#path");

		if (xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.timeline[i])) === margins.width - margins.right) {
			svg.select("#tooltip")
				.style("display", 'none');
		}

		path
			.style('display', null)
			.transition()
			.duration(200)
			.attr('d', `M 0 20 l 10 -10 H${w + 20} V${h + 25} H10 V25 Z`);

	}

	function pointerleft() {
		const boxes = data.boxes;

		svg.select("#tooltip")
			.style("display", 'none');

		svg.node().value = null;
		svg.dispatch("input", { bubbles: true });

		let index = 0;
		boxes.map((p, i) => {
			p.buckets.map((a, j) => {
				a.list.map((d, f) => {
					svg.select("#dot" + index++)
						.style('display', 'none');
				})
			})
		})

		var cursorLine = svg.selectAll('#cursorLine')
			.style('display', 'none');

		svg.selectAll('#path')
			.style('display', 'none')
	}


	function brushended(event) {
		const selection = event.selection;
		if (!event.sourceEvent || !selection) return;
		const [x0, x1] = selection.map(d => d3.timeHour.every(2).round(xScale.invert(d)));
		d3.select(this).transition().call(brush.move, x1 > x0 ? [x0, x1].map(xScale) : null);
	}

	var idleTimeout
	function idled() { idleTimeout = null; }



	// A function that update the chart for given boundaries
	function updateChart(event) {
		var extentX = event.selection

		var t1;

		// If no selection, back to initial coordinate. Otherwise, update X axis domain
		if (!extentX) {
			if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

			xScale.domain([data.minTime, data.maxTime]);
			xScaleBand.domain(d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d)));
		}
		else {
			var from = xScale.invert(extentX[0]);
			var to = xScale.invert(extentX[1]);

			xScale.domain([xScale.invert(extentX[0]), xScale.invert(extentX[1])]);

			var t = d3.map(data.timeline, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d));
			t1 = t.filter(item => item >= from && item <= to);
			xScaleBand.domain(t1);

			// This remove the grey brush area as soon as the selection has been done
			svg.select(".brush").call(brush.move, null)
		}

		// Update axis and circle position
		xAxis.transition()
			.duration(1000)
			.call(d3.axisBottom(xScale).tickFormat((d) => { return tickFormat(d); }));
			//.call(bottomScale.tickFormat((d) => { return tickFormat(d); }));

		// Bottom
		xGrid.transition()
			.duration(1000)
			.call(d3.axisBottom(xScale).tickSize(-margins.height + margins.top + margins.bottom, 0).tickFormat(""));

		var index = 0;
		if (typeof (boxes) != "undefined") {
			boxes.map((p, i) => {
				p.buckets.map((a, j) => {
					switch (p.typ) {
						case ("line"):
							var line = d3.line()
								.x((d) => {return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
								.y((d) => {return (d.value === null ? null : yScale[i](d.value)) })
								.defined(((d) => { return d.value !== null} )); 

							var area = d3.area()
								.x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
								.y0((d) => { return (d.value === null ? null : yScale[i](d.value)) })
								.y1((d) => { return (yScale[i](0)) })
								.defined(((d) => { return d.value !== null;} ));

							if (a.typ == 1 || a.typ == 3) {
								if (a.sensor.cat !== "globalrad")
								{
									clip.select("#path_" + index)
										.transition()
										.duration(1000)
										.attr("d", line);
								} else {
									clip.select("#path_" + index)
										.transition()
										.duration(1000)
										.attr("d", area);
								}
							}
							else {
								clip.select("#box_" + i).selectAll(".bar" + index)
									.transition()
									.duration(1000)
									.attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
									.attr("width", () => { return xScaleBand.bandwidth() });
							}
							break;
						case ("stick"):
						case ("time"):
						case ("direction"):
							clip.select("#box_" + i).selectAll(".bar" + index)
								.transition()
								.duration(1000)
								.attr("x", d => { return xScaleBand(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
								.attr("width", () => { return xScaleBand.bandwidth() })
								.attr("y", margins.top + p.offset);
							break;
					}
					index++;
				})
			})
		}
	}

}

export { setup };
