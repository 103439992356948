import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
//import { saveAs } from 'file-saver';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import StationButton from "../../components/StationButton";

import StackRow from "../../components/StackRow";
import ComboBox from '../../components/ComboBox';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubTitle from "../../components/SubTitle";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from "../../components/Title";

import { logo } from "../../components/d3/charts/chart";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';


export const PhenologyOverview = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [data, setData] = useState();

    const [variety, setVariety] = useState(1);



    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            if (data.length > 0)
                draw(svg);
            else {
                svg.selectAll("*").remove();
            }

    }, [resize, data]);


    useEffect(() => {

        d3.json(preferences.address + "/query/phenology/overview/get.php?station=" + parameter.station + "&variety=" + variety)
            .then((data) => {
                setData(data);
            });
    }, [parameter, variety]);


    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Phänologie Übersicht', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])


    function range(start, end) {
        let a = [];
        if (start > end) {
            for (let i = start; i >= end; i--)
                a.push(i);
            return a;
        }
        else {
            for (let i = start; i <= end; i++)
                a.push(i);
            return a;
        }
    }

    /*const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/dry/excel.php';

        let block = {};
        block.station = parameter.station;
        //block.year = year;
        block.period = period;
        block.level = threshold;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }*/

    function compareDates(d1, d2) {
        let y = (d1.getFullYear() === d2.getFullYear());
        let m = (d1.getMonth() === d2.getMonth());
        let d = (d1.getDate() === d2.getDate());

        return y && m && d;
    }

    function createIntervall(year, min, max) {
        var date = new Date(year + "-02-20");
        var enddate = new Date(year + "-11-30");
        let list = [];
        while (date < enddate) {
            let nd = new Date(date.getTime());
            list.push(nd); //Duplicate
            date.setDate(date.getDate() + 1);
        }
        return list;
    }


    function setStadion(d, g) {
        if (compareDates(new Date(d.aufbruch), g)) { return 1 };
        if (compareDates(new Date(d.mausohr), g)) { return 2 };
        if (compareDates(new Date(d.greenknospen), g)) { return 3 };
        if (compareDates(new Date(d.redknospen), g)) { return 4 };

        if (compareDates(new Date(d.bloomstart), g)) { return 5 };
        if (compareDates(new Date(d.bloom), g)) { return 6 };
        if (compareDates(new Date(d.bloomend), g)) { return 7 };
        if (compareDates(new Date(d.tstadium), g)) { return 8 };
        if (compareDates(new Date(d.erntebeginn), g)) { return 9 };

        return 0;
    }

    function getColor(stadion) {
        if (stadion === 0) return 'white';
        return d3.schemeTableau10[stadion];

    }

    function dayOfTheYear(date) {
        return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
    }


    function drawLeft(svg, margin, aufteilung, yScale) {
        let width = parseInt(svg.style("width"));

        const rgd = svg.append("g").attr('id', 'left_block');

        var xScaleBandLeft = d3.scaleBand()
            .domain([...Array(110).keys()].map(i => i + 50))
            .range([0, width * aufteilung - 10]);

        let calObject = [];
        data.map((s) => {
            createIntervall(s.year).map((day) => {
                let o = {};
                o.year = s.year;
                o.dayOfYear = dayOfTheYear(day);
                o.date = day;
                o.stadion = setStadion(s, day);
                calObject.push(o);
                return 0;
            })
            return 0;
        });

        // Einteilung Monate
        let months = [];
        data.map((d) => {
            var o = {};
            o.year = d.year;
            o.v = dayOfTheYear(new Date(d.year + "-03-01"));
            o.name = "_mar";
            months.push(o);
            o = {};
            o.year = d.year;
            o.v = dayOfTheYear(new Date(d.year + "-04-01"));
            o.name = "_apr";
            months.push(o);
            o = {};
            o.year = d.year;
            o.v = dayOfTheYear(new Date(d.year + "-05-01"));
            o.name = "_may";
            months.push(o);
            o = {};
            o.year = d.year;
            o.v = dayOfTheYear(new Date(d.year + "-06-01"));
            o.name = "_jun";
            months.push(o);
            return 0;
        })

        rgd
            .selectAll("horLines")
            .data(months)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left - 1) + "," + (margin.top + 12) + ")")
            .attr("x1", function (d, index) { return xScaleBandLeft(d.v) })
            .attr("x2", function (d, index) { return xScaleBandLeft(d.v) })
            .attr("y1", function (d) { return yScale(d.year) - 15 })
            .attr("y2", function (d) { return yScale(d.year) - 5 })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 30);

        // Monatsbeschriftung
        rgd
            .selectAll("monthNames")
            .data(months)
            .enter()
            .append("text")
            .attr("transform", "translate(" + (margin.left - 1) + "," + (margin.top + 12) + ")")
            .attr("x", (d) => { return xScaleBandLeft(d.v) + 20 })
            .attr("y", (d) => { return yScale(d.year) - 5 })
            .attr("text-anchor", "middle")
            .text(function (d) { return t(d.name); });

        // Aufbruch bis rote Knospen; Blühbeginn bis Blühende
        // Einfärbungen
        let sections_green = [];
        data.map((d) => {
            let o = {};
            o.year = d.year;
            o.v = d.aufbruch !== null ? dayOfTheYear(new Date(d.aufbruch)) : dayOfTheYear(new Date(d.mausohr));
            o.b = dayOfTheYear(new Date(d.redknospen));
            sections_green.push(o);
            return 0;
        })

        // Grünes Stadium
        rgd
            .selectAll("sections")
            .data(sections_green)
            .join("rect")
            .attr("transform", "translate(" + (margin.left - 2) + "," + (margin.top + 10) + ")")
            .attr('x', (d, i) => { return xScaleBandLeft(d.v) })
            .attr('y', (d, i) => { return yScale(d.year) })
            .attr('width', (d) => { return xScaleBandLeft(d.b) - xScaleBandLeft(d.v) + xScaleBandLeft.bandwidth() })
            .attr('height', 18)
            .attr('stroke', 'none')
            .attr('fill', (d, i) => { return "green" })
            .attr('opacity', 0.6);

        // Gelbes Stadium
        let sections_yellow = [];
        data.map((d) => {
            let o = {};
            o.year = d.year;
            o.v = d.bloomstart !== null ? dayOfTheYear(new Date(d.bloomstart)) : dayOfTheYear(new Date(d.bloom));
            o.b = dayOfTheYear(new Date(d.bloomend));
            sections_yellow.push(o);
            return 0;
        })
        rgd
            .selectAll("sections")
            .data(sections_yellow)
            .join("rect")
            .attr("transform", "translate(" + (margin.left - 2) + "," + (margin.top + 10) + ")")
            .attr('x', (d, i) => { return xScaleBandLeft(d.v) })
            .attr('y', (d, i) => { return yScale(d.year) })
            .attr('width', (d) => { return xScaleBandLeft(d.b) - xScaleBandLeft(d.v) + xScaleBandLeft.bandwidth() })
            .attr('height', 18)
            .attr('stroke', 'none')
            .attr('fill', (d, i) => { return "yellow" })
            .attr('opacity', 0.4)
            .append("title")
            .text((d) => { return d.date });


        // Kalenderansicht
        rgd
            .selectAll('cal')
            .data(calObject)
            .join("rect")
            .attr("transform", "translate(" + margin.left + "," + (margin.top + 12) + ")")
            .attr('x', (d, i) => { return xScaleBandLeft(d.dayOfYear) })
            .attr('y', (d, i) => { return yScale(d.year) })
            .attr('width', xScaleBandLeft.bandwidth() - 4)
            .attr('height', 14)
            .attr('stroke', 'black')
            .attr('fill', (d, i) => { return getColor(d.stadion) })
            .append("title")
            .text((d) => { if (d.date !== undefined) { return d.date.toDateString() } });
    }

    function drawRight(svg, margin, aufteilung, yScale) {
        let width = parseInt(svg.style("width"));
        //t = d3.min([data.map ((d) => {return (d.erntebeginn)})]);

        // Berechne Start des Kalenders
        let b = data.filter((d) => { return d.erntebeginn !== null });
        let days_min = d3.min(b.map((d) => { return dayOfTheYear(new Date(d.erntebeginn)) })) - 12;
        let days_max = d3.max(b.map((d) => { return dayOfTheYear(new Date(d.erntebeginn)) })) + 8


        // Kalendertage
        // min bis max
        let days = [...Array(days_max - days_min).keys()].map(i => i + days_min);

        var xScaleBandRight = d3.scaleBand()
            .domain(days)
            .range([width * aufteilung + 10, width - margin.right]);

        const block = svg.append("g").attr('id', 'right_block');

        // Kalender
        let calObject = [];
        data.map((s) => {
            createIntervall(s.year).map((day) => {
                if (dayOfTheYear(day) > d3.min(days) && dayOfTheYear(day) < d3.max(days)) {
                    let o = {};
                    o.year = s.year;
                    o.dayOfYear = dayOfTheYear(day);
                    o.date = day;
                    o.stadion = setStadion(s, day);
                    calObject.push(o);
                }
                return 0;
            })
            return 0;
        });

        // Monatseinteilung
        let months = [];
        var o = {};
        data.map((d) => {

            if (dayOfTheYear(new Date(d.year + "-08-01")) > days_min && dayOfTheYear(new Date(d.year + "-08-01")) < days_max) {
                o = {};
                o.year = d.year;
                o.v = dayOfTheYear(new Date(d.year + "-08-01"));
                o.name = "_aug";
                months.push(o);
            }
            if (dayOfTheYear(new Date(d.year + "-09-01")) > days_min && dayOfTheYear(new Date(d.year + "-09-01")) < days_max) {
                o = {};
                o.year = d.year;
                o.v = dayOfTheYear(new Date(d.year + "-09-01"));
                o.name = "_sep";
                months.push(o);
            }
            if (dayOfTheYear(new Date(d.year + "-10-01")) > days_min && dayOfTheYear(new Date(d.year + "-10-01")) < days_max) {
                o = {};
                o.year = d.year;
                o.v = dayOfTheYear(new Date(d.year + "-10-01"));
                o.name = "_oct";
                months.push(o);
            }
            if (dayOfTheYear(new Date(d.year + "-11-01")) > days_min && dayOfTheYear(new Date(d.year + "-11-01")) < days_max) {
                o = {};
                o.year = d.year;
                o.v = dayOfTheYear(new Date(d.year + "-11-01"));
                o.name = "_nov";
                months.push(o);
            }
            return 0;
        })

        block
            .selectAll("horLines")
            .data(months)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left - 1) + "," + (margin.top + 12) + ")")
            .attr("x1", function (d, index) { return xScaleBandRight(d.v) })
            .attr("x2", function (d, index) { return xScaleBandRight(d.v) })
            .attr("y1", function (d) { return yScale(d.year) - 15 })
            .attr("y2", function (d) { return yScale(d.year) - 5 })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 30);

        // Monatsbeschriftung
        block
            .selectAll("monthNames")
            .data(months)
            .enter()
            .append("text")
            .attr("transform", "translate(" + (margin.left - 1) + "," + (margin.top + 12) + ")")
            .attr("x", (d) => { return xScaleBandRight(d.v) + 20 })
            .attr("y", (d) => { return yScale(d.year) - 5 })
            .attr("text-anchor", "middle")
            .text(function (d) { return t(d.name); });

        block
            .selectAll('cal')
            .data(calObject)
            .join("rect")
            .attr("transform", "translate(" + margin.left + "," + (margin.top + 12) + ")")
            .attr('x', (d, i) => { return xScaleBandRight(d.dayOfYear) })
            .attr('y', (d, i) => { return yScale(d.year) })
            .attr('width', xScaleBandRight.bandwidth() - 4)
            .attr('height', 14)
            .attr('stroke', 'black')
            .attr('fill', (d, i) => { return getColor(d.stadion) })
            .append("title")
            .text((d) => { if (d.date !== undefined) { return d.date.toDateString() } });

    }

    function draw(svg) {

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 10,
            right: 100,
            bottom: 40,
            left: 65,
        }; // margin of chart

        const offset = 0;

        let width = parseInt(svg.style("width"));


        // Create scale
        let step = 40; // Pro zeile, Jahr

        let max = d3.max(data.map((d) => { return d.year }));
        let min = d3.min(data.map((d) => { return d.year }));

        // Anzahl Jahre
        let years = max - min;
        if (years === 0) years = 1;

        let height = years * step + margin.top + margin.bottom;
        if (height < 200) height = 200;
        svg.attr("height", height);

        var yScale = d3.scaleBand()
            .domain(range(max, min))
            .range([0, years * step]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale);

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(y_axis);

        // Y Label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", 20)
            .text(t("years"));

        var date = new Date("2024-02-20");
        var enddate = new Date("2024-06-05");
        let list1 = [];
        while (date < enddate) {
            list1.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        let aufteilung = 0.75;

        //var xScaleBandLeft = 
        d3.scaleBand()
            .domain([...Array(105).keys()].map(i => i + 50))
            .range([offset, width * aufteilung - 10]);

        drawLeft(svg, margin, aufteilung, yScale);
        drawRight(svg, margin, aufteilung, yScale);

        logo(svg);

        //bordersH (svg, ["", ""], 260, 70, 0);
        //addAreaH (svg, 0, "orange", t("dry"))

        //let colors = ['rgba(255,255,255,1)', '#084594', '#000000'];
        //label_mid (svg, colors, 0, 20, 80, "mm", width - 50, 30);

        //addAreaH (svg, 1, "#084594", t("rain"))

    }

    let stadien = []

    stadien.push([
        { number: 'B', numberBBCH: '53', icon: '', title: 'Blütenknospenaufbruch', image: 'stadien/001.png', description: 'Grüne Blätter, die das Blütenbüschel umhüllen, werden sichtbar' },
        { number: 'C3', numberBBCH: '54', icon: '', title: 'Mausohrstadium', image: 'stadien/002.png', description: 'Grüne Blattspitzen überragen Blütenknospenschuppen um 10mm, erste Blätter spreitzen sich ab' },
        { number: 'D3', numberBBCH: '56', icon: '', title: 'Grünknospenstadium', image: 'stadien/003.png', description: 'Noch geschlossene Einzelblüten, beginnen sich voneinander zu lösen' },
        { number: 'E', numberBBCH: '57', icon: '', title: 'Rotknospenstadium', image: 'stadien/004.png', description: 'Blütenstiele strecken sich, Kelchblätter sind leicht geöffnet, Blütenblätter gerade erkennbar' },
        { number: 'F', numberBBCH: '61', icon: '', title: 'Beginn der Blüte', image: 'stadien/005.png', description: 'Etwa 10% der Blüten geöffnet' },
        { number: 'F2', numberBBCH: '65', icon: '', title: 'Vollblüte', image: 'stadien/006.png', description: 'Mindestens 50% der Blüten geöffnet, erste Blütenblätter fallen ab' },
        { number: 'H', numberBBCH: '69', icon: '', title: 'Ende der Blüte', image: 'stadien/007.png', description: 'Alle Blütenblätter abgefallen' },
        { number: 'J', numberBBCH: '38', icon: '', title: 'T-Stadium', image: 'stadien/008.png', description: 'Fruchtdurchmesser bis 40mm, Frucht steht aufrecht, Fruchtunterseite und Stiel bilden ein T' },
        { number: '', numberBBCH: '-', icon: '', title: 'Erntebeginn', image: 'stadien/009.png', description: '...' },
    ]);

    stadien.push([
        { number: 'B', numberBBCH: '53', icon: '', title: 'Rigonfiamento gemme', image: 'stadien/001.png', description: 'Le gemme si rigonfiano, le scaglie si rigonfiano e mostrano una punteggiatura chiara, sono visibili le scaglie interne, pallide e parzialmente pelose.' },
        { number: 'C3', numberBBCH: '54', icon: '', title: 'Orecchiette di topo', image: 'stadien/002.png', description: 'Le punte delle foglie sono nettamente divaricate anche se le foglie non sono ancora emerse; le punte delle foglie superano di circa 10 mm le scaglie delle gemme.' },
        { number: 'D3', numberBBCH: '56', icon: '', title: 'Bottoni verdi', image: 'stadien/003.png', description: 'I singoli fiori ancora chiusi si separano.' },
        { number: 'E', numberBBCH: '57', icon: '', title: 'Bottoni rosa', image: 'stadien/004.png', description: 'I peduncoli dei bottoni fiorali si allungano, i sepali si separano e lasciano intravedere i petali.' },
        { number: 'F', numberBBCH: '61', icon: '', title: 'Inizio fioritura (apertura del fiore centrale)', image: 'stadien/005.png', description: 'Il fiore centrale dei corimbi si apre.' },
        { number: 'F2', numberBBCH: '65', icon: '', title: 'Piena fioritura', image: 'stadien/006.png', description: 'Tutti i fiori del corimbo sono completamente aperti.' },
        { number: 'H', numberBBCH: '69', icon: '', title: 'Fine caduta petali', image: 'stadien/007.png', description: 'Cadono gli ultimi petali; a partire dal fiore centrale i ricettacoli si ingrossano e sono di dimensioni ancora inferiori a 5 mm.' },
        { number: 'J', numberBBCH: '38', icon: '', title: 'Ingrossamento dei frutti', image: 'stadien/008.png', description: 'I frutti continuano ad ingrossarsi.' },
        { number: '', numberBBCH: '-', icon: '', title: 'Inizio raccolta', image: 'stadien/009.png', description: '...' },
    ]);


    function getLang() {
        switch (i18n.language) {
            case "de":
                return 0;
            case "it":
                return 1;
            default:
                return 0;
        }
    }

    let c = ["Golden Delicious", "Cripps Pink", "Gala Schnitzer"];

    const handleSelector = (event) => {
        setVariety(event.target.value);
    };


    return (
        <>
            <div className="stagearea grid">

                <Title text={t("phenology_overview")} />

                <StackRow text={t("station")} component={<StationButton />} />

                <Stack spacing={0}>

                    <Item>
                        <ComboBox
                            title={"variety"}
                            value={variety} items={c}
                            callback={handleSelector} />
                    </Item>

                </Stack>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >

                        <SubTitle text={t("die_stadien")} />

                    </AccordionSummary>
                    <AccordionDetails>


                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>{t("Nach Fleckinger")}</strong></TableCell>
                                        <TableCell><strong>BBCH-Code</strong></TableCell>
                                        <TableCell><strong>{t("Abbildung")}</strong></TableCell>
                                        <TableCell><strong>{t("Stadium")}</strong></TableCell>
                                        <TableCell><strong>{t("Beschreibung")}</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {stadien[getLang()].map((row, index) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },

                                                padding: '0px',
                                                marging: '0px'
                                            }}
                                        >
                                            <TableCell sx={{ padding: '0px', backgroundColor: d3.schemeTableau10[index + 1], minWidth: '40px' }} component="dh" scope="row" align="center" >
                                                {row.number}
                                            </TableCell>
                                            <TableCell sx={{ padding: '0px', backgroundColor: d3.schemeTableau10[index + 1], minWidth: '40px' }} component="dh" scope="row" align="center" >
                                                {row.numberBBCH}
                                            </TableCell>

                                            <TableCell sx={{ padding: '0px' }} component="td" scope="row" align="center">
                                                <img src={row.image} alt={row.image} />
                                            </TableCell>
                                            <TableCell align="left">{row.title}</TableCell>
                                            <TableCell align="left">{row.description}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>

                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>



                <div id="svgchart">

                    {data !== undefined && data.length > 0 ?
                        <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
                        :
                        <div>No data available</div>
                    }
                </div>

                <Grid container flexDirection={"row"}>

                    <Grid item className="p-2" flexGrow={1}>
                        <InfoButton
                            de={""}
                            it={""}
                            en={""}
                        />
                    </Grid>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("dry")} />
                    </Grid>

                    <Grid item className="p-2">
                        {/* <ExcelButton callback={downloadExcel} /> */}
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
