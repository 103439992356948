import { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { GraphContext } from "../../../GraphContext";

import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import { useTranslation } from 'react-i18next';

export const Head = ({ head, loc }) => {

    const { t, i18n } = useTranslation();
    
    const StyledTableCell = styled (TableCell)(({ theme }) => ({
                [`&.${tableCellClasses.head}`]: {
                  backgroundColor: '#008051',
                  color: theme.palette.common.white,
                  fontSize: 16,
          padding: "2px"
                },
                [`&.${tableCellClasses.body}`]: {
                  fontSize: 12,
                },
          }));


    return (
        <>
            <TableHead>
                <TableRow>
                    <StyledTableCell sx={{backgroundColor: '#dddddd'}} ></StyledTableCell>
                    {(head === null) ?
                        '' :
                        head.keys.map((key, index) => {
                            let name = key.name;
                            return (
                                <>

                                    {(name === 'airtemp2m' && (loc=== 0 || index === 0)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)}> {t(key.name)} [2m] </StyledTableCell>}

                                    {name === 'humidity' &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)}> {t(key.name)} </StyledTableCell>}

                                    {(name === 'globalrad' && loc < 6) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)}> {t(key.name)} </StyledTableCell>}

                                    {(name === 'dursunshine' && loc < 5) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)}> {t(key.name)} </StyledTableCell>}

                                    {name === 'rain' &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)}> {t(key.name)} </StyledTableCell>}

                                    {((name === 'soiltemp1' || key.name === 'soiltemp2') && (loc < 3)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)+" ["+key.level+"]"}> {t(key.name)} [{key.level}] </StyledTableCell>}

                                    {(name === 'windspeed' && (loc < 10)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)}> {t(key.name)} </StyledTableCell>}

                                    {(name === 'level' && (loc < 2)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={(loc === 0) ? key.cols : 1} title={t(key.name)}> {t(key.name)} </StyledTableCell>}

                                    {(name === 'meteorology' && (loc < 4)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" className="ellipsize" colSpan={key.cols}> {t(name)} </StyledTableCell>}
                                </>
                            )
                        })
                    }
                </TableRow>
                <TableRow>
                    <StyledTableCell sx={{backgroundColor: '#dddddd'}}></StyledTableCell>
                    {(head === null) ?
                        '' :
                        head.keys.map((key) => {
                            let name = key.name;
                              return (
                                <>
                                    {name === 'airtemp2m' &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}>{key.unit} </StyledTableCell>}

                                    {name === 'humidity' &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(name === 'globalrad' && loc < 6) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(name === 'dursunshine' && loc < 5) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> hh:mm:ss </StyledTableCell>}

                                    {name === 'rain' &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {((name === 'soiltemp1' || key.name === 'soiltemp2') && (loc < 3)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(name === 'windspeed' && (loc < 10)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(name === 'level' && (loc < 2)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={(loc === 0) ? key.cols : 1}> {key.unit} </StyledTableCell>}

                                    {(name === 'meteorology' && (loc < 4)) &&
                                        <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center" colSpan={key.cols}> {t("Klimatologische Kenntage")} </StyledTableCell>}
                                </>
                            )
                        })
                    }
                </TableRow>

                <TableRow>
                    <StyledTableCell sx={{backgroundColor: '#dddddd'}}></StyledTableCell>
                    {(head === null) ?
                        '' :
                        head.keys.map((key) => {
                            let name = key.name;
                            //let cols = key.cols;
                            return (
                                <>
                                    {key.sub.map((c, index) => {
                                        return (
                                            <>
                                        {(name === 'airtemp2m' && (loc === 0 || index === 0)) &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {name === 'humidity' &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {(name === 'globalrad' && loc < 6) &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {(name === 'dursunshine' && loc < 5) &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {(name === 'rain' && (loc === 0 || index === 0)) &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {((name === 'soiltemp1' || key.name === 'soiltemp2') && (loc < 3)) &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {(name === 'windspeed' && (loc < 10)) &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {(name === 'level' && loc < 2) &&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
    
                                        {((name === 'meteorology') && loc < 4)&&
                                            <StyledTableCell sx={{backgroundColor: '#dddddd'}} align="center"> {t(c)} </StyledTableCell>}
                                        </>
                                        )
                                    })}

                                </>
                            )
                        })
                    }
                </TableRow>
            </TableHead>

        </>
    )

}
