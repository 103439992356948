import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../../js/datetime';

import { GraphContext } from "../../GraphContext";
import "../../../styles.css";
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import { preferences } from "../../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../../components/ExcelButton';
import GraphicButton from '../../../components/GraphicButton';
import InfoButton from '../../../components/InfoButton';

import { xlabel, ylabel, logo } from '../../../components/d3/charts/chart';

import ComboBox from '../../../components/ComboBox';
import SensorComboBox from '../../../components/SensorComboBox';
import DatePicker from '../../../components/DatePicker';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import { borders, addPoint } from '../../../components/d3/legend/legend';

export const Scatterplot = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();

    const [parameter] = useContext(GraphContext)

    const [sensors, setSensors] = useState([]);

    const [season, setSeason] = useState(0);
    const [month, setMonth] = useState(0);

    const [sensorx, setSensorx] = useState(1);
    const [sensory, setSensory] = useState(2);
    const [sensorString, setSensorString] = useState({ x: "airtemp2m", y: "rain" });

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());
    const [interval, setInterval] = useState(100);
    const [classification, setClassification] = useState(1);

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Allgemein Scatterplot', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])


    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined) {
            draw(svg);

            switch (classification) {
                case 2:
                    if (season === 0) {
                        let l1 = [t('winter'), t('spring'), t('summer'), t('autumn')];
                        borders(svg, [t('winter'), t('spring'), t('summer'), t('autumn')], 150, 70, 10);
                        l1.map((item, index) => {
                            return addPoint(svg, index, d3.schemePaired[index], item);
                        })
                    }
                    break;
                case 3:
                    if (month === 0) {
                        let l2 = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
                        borders(svg, [t('_jan'), t('_feb'), t('_mar'), t('_apr'), t('_may'), t('_jun'), t('_jul'), t('_aug'), t('_sep'), t('_oct'), t('_nov'), t('_dec')], 150, 70, 10);
                        l2.map((item, index) => {
                            return addPoint(svg, index, d3.schemePaired[index], item);
                        })
                    }
                    break;
                default:
                    break;
            }
        }
    }, [resize, data, classification, season, month, draw, t]);//, [resize, data]

    useEffect(() => {

        let station = parameter.station;
        var url = preferences.address + '/rest/sensors.php?station=' + station;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                data.map((sensor, index) => {
                    sensor.main = true;
                    sensor.min = false;
                    sensor.max = false;
                    if (sensor.cat === "airtemp2m") setSensorx(index + 1);
                    if (sensor.cat === "rain") setSensory(index + 1);
                    return 0;
                })
                setSensors(data);
            });
    }, [parameter])


    useEffect(() => {
        switch (classification) {
            case 1:
                d3.json(preferences.address + "/query/seasons/scatterplot/year/get.php?station=" + parameter.station + "&x=" + sensorString.x + "&y=" + sensorString.y + "&year=" + year)
                    .then((data) => {
                        setData(data);
                    });
                break;
            case 2:
                d3.json(preferences.address + "/query/seasons/scatterplot/season/get.php?station=" + parameter.station + "&x=" + sensorString.x + "&y=" + sensorString.y + "&year=" + year)
                    .then((data) => {
                        setData(data);
                    });
                break;
            case 3:
                d3.json(preferences.address + "/query/seasons/scatterplot/month/get.php?station=" + parameter.station + "&x=" + sensorString.x + "&y=" + sensorString.y + "&year=" + year)
                    .then((data) => {
                        setData(data);
                    });
                break;
            default:
                break;

        }

    }, [i18n.language, parameter, year, interval, sensorString, classification]);

    const downloadExcel = () => {

        var url;
        switch (classification) {
            case 1:
                url = preferences.address + '/query/seasons/scatterplot/year/excel.php';
                break;
            case 2:
                url = preferences.address + '/query/seasons/scatterplot/season/excel.php';
                break;
            case 3:
                url = preferences.address + '/query/seasons/scatterplot/month/excel.php';
                break;
            default:
                break;
        }

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.x = sensorString.x;
        block.y = sensorString.y;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    var width = 0;

    function draw(svg) {


        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 10,
            right: 40,
            left: 80,
            bottom: 40
        }; // margin of chart

        // Getting width
        width = parseInt(svg.style('width'));

        let height = 400;

        let inner_width = width - margin.right - margin.left;
        let inner_height = height - margin.top - margin.bottom;

        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        svg
            .attr("width", width)
            .attr("height", height);

        // Add dots
        let dots = [];

        // 
        let min_temp = 0;
        let max_temp = 0;

        if (data !== undefined) {

            dots = data.seasons.filter((item) => { return item.temp !== null && item.rain !== null; });

            if (season > 0 && classification === 2) {
                dots = dots.filter((item) => { return item.div === season })
            }

            if (month > 0 && classification === 3) {
                dots = dots.filter((item) => { return item.div === month })
            }

            min_temp = d3.min(dots.map((d) => { return d.temp }));
            max_temp = d3.max(dots.map((d) => { return d.temp }));

        }

        let xScale = d3.scaleLinear()
            .domain([min_temp, max_temp])
            .range([0, inner_width]);

        var x_axis = d3.axisBottom()
            .scale(xScale);

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", " + (height - margin.bottom) + ")")
            .attr("class", "axes")
            .call(x_axis.ticks(10, "0.1f").tickFormat((x) => { return x + " " + data.xUnit }));

        // Grid vertically
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ", " + margin.top + ")")
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(x_axis.tickSize(inner_height, 0).tickFormat(""));


        let min_rain = 0;
        let max_rain = 0;

        if (data !== undefined) {

            if (season > 0 && classification === 2) {
                dots = dots.filter((item) => { return item.div === season })
            }

            if (month > 0 && classification === 3) {
                dots = dots.filter((item) => { return item.div === month })
            }
            min_rain = d3.min(dots.map((d) => { return d.rain }));
            max_rain = d3.max(dots.map((d) => { return d.rain }));
        }

        // Create scale
        var yScale = d3.scaleLinear()
            .domain([min_rain, max_rain])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale);

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("class", "axes")
            .call(y_axis.ticks(10, "0.1f").tickFormat((x) => { return x + " " + data.yUnit }));

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ", 0)")
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-inner_width, 0).tickFormat(""));

        if ((month > 0 && classification === 3) || (season > 0 && classification === 2) || classification === 1) {
            svg.append('rect')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .attr('x', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.25)))
                .attr('y', yScale(max_rain))
                .attr('width', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.75)) - xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.25)))
                .attr('height', yScale(min_rain) - yScale(max_rain))
                .attr('fill', 'green')
                .attr('stroke', 'none')
                .attr('opacity', 0.1);
            svg.append('line')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .style('stroke', "black")
                .style('stroke-dasharray', "5 5")
                .attr('x1', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.10)))
                .attr('y1', yScale(max_rain))
                .attr('x2', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.10)))
                .attr('y2', yScale(min_rain))
                .attr('opacity', 0.7);
            svg.append('line')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .style('stroke', "black")
                .style('stroke-width', 2.5)
                .attr('x1', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.50)))
                .attr('y1', yScale(max_rain))
                .attr('x2', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.50)))
                .attr('y2', yScale(min_rain))
                .attr('opacity', 0.7);
            svg.append('line')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .style('stroke', "black")
                .style('stroke-dasharray', "5 5")
                .attr('x1', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.90)))
                .attr('y1', yScale(max_rain))
                .attr('x2', xScale(d3.quantile(dots.map((d, i) => { return d.temp }), 0.90)))
                .attr('y2', yScale(min_rain))
                .attr('opacity', 0.7);

            svg.append('rect')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .attr('x', xScale(min_temp))
                .attr('y', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.75)))
                .attr('width', xScale(max_temp) - xScale(min_temp))
                .attr('height', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.25)) - yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.75)))
                .attr('fill', 'steelblue')
                .attr('stroke', 'none')
                .attr('opacity', 0.1);
            svg.append('line')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .style('stroke', "black")
                .style('stroke-dasharray', "5 5")
                .attr('x1', xScale(min_temp))
                .attr('y1', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.90)))
                .attr('x2', xScale(max_temp))
                .attr('y2', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.90)))
                .attr('opacity', 0.7);
            svg.append('line')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .style('stroke', "black")
                .style('stroke-width', 2.5)
                .attr('x1', xScale(min_temp))
                .attr('y1', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.50)))
                .attr('x2', xScale(max_temp))
                .attr('y2', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.50)))
                .attr('opacity', 0.7);
            svg.append('line')
                .attr("transform", "translate(" + margin.left + ", 0)")
                .style('stroke', "black")
                .style('stroke-dasharray', "5 5")
                .attr('x1', xScale(min_temp))
                .attr('y1', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.10)))
                .attr('x2', xScale(max_temp))
                .attr('y2', yScale(d3.quantile(dots.map((d, i) => { return d.rain }), 0.10)))
                .attr('opacity', 0.7);
        }

        svg.append('g')
            .selectAll("dot")
            //.data(data.seasons.filter((item) => { return item.temp !== null && item.rain !== null; }))
            .data(dots)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("cx", function (d) { return xScale(d.temp); })
            .attr("cy", function (d) { return yScale(d.rain); })
            .attr("r", function (d) { if (parseInt(year) === d.year) return 5.0; return 3.5 })
            .style("fill", function (d) { return d3.schemePaired[d.div - 1]; })
            .style("stroke", function (d) { return 'black'; })
            .style("stroke-width", function (d) { if (parseInt(year) === d.year) return 3; return 1 })
            .append('title')
            .text((d) => `${d.div}/${d.year}: ${d.temp} - ${d.rain}`)

        if ((month > 0 && classification === 3) || (season > 0 && classification === 2) || classification === 1) {
            svg
                .selectAll("label")
                .data(dots)
                .enter()
                .append("text")
                .attr("transform", "translate(" + margin.left + ", 0)")
                .attr("class", "label")
                .attr("x", (d) => { return xScale(d.temp) })
                .attr("y", (d) => { return yScale(d.rain) - 5 }) //(Math.random() < 0.5 ? 5 : -15) })
                .text((d) => { return d.year });
        }


        xlabel(svg, t(data.xLabel), width / 2, height - 5);
        ylabel(svg, t(data.yLabel), -height * 0.5, 15);
        logo(svg);
    }

    function pointermoved(event) {
        //console.log (event);

        const svg = d3.select(svgRef.current);

        let x_pos = d3.pointer(event)[0];
        if (x_pos < 250) {
            //svg.selectAll('#legend').attr('display', 'none');                                                                     
            svg.selectAll('#legend').attr('opacity', 0)
        }
        else {
            //svg.selectAll('#legend').attr('display', null);                                                                           
            svg.selectAll('#legend').attr('opacity', 1)
        }


    }

    function pointerleft() {
        const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)
    }

    const handleChange = (event) => {
        setClassification(event.target.value);
    };

    const handleChangeSeasons = (event) => {
        setSeason(event.target.value - 1);
    };

    const handleChangeMonths = (event) => {
        setMonth(event.target.value - 1);
    };

    const handleSensorx = (event) => {

        let index = event.target.value - 1;

        setSensorx(event.target.value);
        setSensorString((previous) => ({ ...previous, x: sensors[index].cat }));
    }

    const handleSensory = (event) => {
        let index = event.target.value - 1;

        setSensory(event.target.value);
        setSensorString((previous) => ({ ...previous, y: sensors[index].cat }));
    }

    let c = [t("years"), t("seasons"), t("months")];

    return (
        <>
            <Stack spacing={0}>
                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

                <Item>
                    <ComboBox
                        title={"classification"}
                        value={classification} items={c}
                        callback={handleChange} />
                </Item>

                {classification === 2 &&
                    <Item>
                        <ComboBox
                            title={"classification"}
                            value={season + 1} items={['', 'winter', 'spring', 'summer', 'autumn']}
                            callback={handleChangeSeasons} />
                    </Item>
                }

                {classification === 3 &&
                    <Item>
                        <ComboBox
                            title={"classification"}
                            value={month + 1} items={['', t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')]}
                            callback={handleChangeMonths} />
                    </Item>
                }


                <Item>
                    <SensorComboBox
                        title={t("sensor") + " X"}
                        value={sensorx} items={sensors}
                        callback={handleSensorx} />
                </Item>
                <Item>

                    <SensorComboBox
                        title={t("sensor") + " Y"}
                        value={sensory} items={sensors}
                        callback={handleSensory} />
                </Item>


            </Stack>



            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"<p>Das interaktive Diagramm 'Scatterplot' ermöglicht es, die Beziehung zwischen zwei Parametern einer bestimmten Wetterstation darzustellen. Dabei können die Benutzer durch die Drop-down-Liste im Bereich Einteilung die Jahreswerte eines ausgewählten Jahres oder Monatswerte bzw. Werte der Jahreszeiten definieren. Die dick umkreisten Punkte stellen das ausgewählte Jahr dar, alle anderen Punkte hingegen die jeweils vorangehenden Jahre seit Messbeginn des Parameters. Mittels Mauszeiger können die Werte der beiden Parameter direkt in der Grafik angezeigt werden.</p><p>Die eingefärbten Streifen markieren den Bereich, in dem sich die mittleren 50% der Messwerte vom 25. bis 75. Perzentil der Daten befinden, wobei die Linie in der Mitte der Box den Medianwert der vorangehenden 30 Jahre darstellt, welcher die Mitte der Datenverteilung angibt. Jeder Punkt repräsentiert den Durchschnittswert bzw. Summenwert eines bestimmten Monats, einer bestimmten Jahreszeit bzw. eines Jahres. Mittels Mausklick auf den Punkte eines entsprechenden Jahres können die Werte der beiden Parameter direkt in der Grafik angezeigt werden.</p>"}
                        it={"<p>Il diagramma interattivo 'Scatterplot' permette di rappresentare la relazione tra due parametri di una determinata stazione meteorologica. Gli utenti possono definire i valori annuali di un anno selezionato e i valori mensili o i valori delle stagioni attraverso il menu a tendina nell'area di suddivisione. I punti cerchiati in grassetto rappresentano l'anno selezionato, mentre tutti gli altri punti rappresentano gli anni precedenti dall'inizio della misurazione del parametro. Utilizzando il puntatore del mouse, sarà possibile visualizzare direttamente i valori dei due parametri nel grafico.</p> <p>Le bande verdi delimitano l'area in cui si trovano i valori mediani del 50% dei dati dal 25° al 75° percentile, con la linea al centro del box che rappresenta il valore mediano dei dati degli ultimi 30 anni, indicando il centro della distribuzione dei dati. Ogni punto rappresenta il valore medio o sommato di un determinato mese, stagione o anno. Cliccando sui punti di un determinato anno, è possibile visualizzare direttamente i valori dei due parametri nel grafico. Le linee spesse tracciano il valore mediano mentre quelle tratteggiate sono il 10° e 90° percentile.</p>"}
                        en={"<p>The interactive diagram 'Scatterplot' makes it possible to display the relationship between two parameters of a specific weather station. Users can use the drop-down list in the settings area to define the annual values of a selected year or monthly values or values of the seasons. The bold circled points represent the selected year, while all other points represent the previous years since the start of measurement of the parameter. The values of the two parameters can be displayed directly in the graph using the mouse pointer. The colored stripes mark the area in which the middle 50% of the measured values from the 25th to 75th percentile of the data are located, with the line in the middle of the box representing the median value of the previous 30 years, which indicates the middle of the data distribution. Each point represents the average value or total value of a particular month, season or year. By clicking on the points of a corresponding year, the values of the two parameters can be displayed directly in the graph.</p>"}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object={"svg_chart"} filename={t("scatterplot") + "-" + t(sensorString.x) + "-" + t(sensorString.y)} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>
            </Grid>

        </>
    );

}
