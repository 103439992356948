import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import i18n from "i18next";
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
  addButton: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
    color: "primary",
    zIndex: 20,
    backgroundColor: 'transparent',
    "&:hover": {
      backgroundColor: "yellow"
    }
  },
  switcher: {
    //fill: "white",
    backgroundColor: 'transparent'
  }
});

export default function Language() {

  const classes = useStyles();

  useEffect(() => {

    /*if (i18n.language === "de_DE") {
      i18n.changeLanguage("de");
    }*/

    if (i18n.language === "it_IT") {
      i18n.changeLanguage("it");
    }
    else {
      i18n.changeLanguage("de");
    }

    /*if (i18n.language === "en_US") {
      i18n.changeLanguage("de");
    }*/
  }, [])


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (index) => {

    console.log(index);
    switch (index) {
      case 1:
        i18n.changeLanguage("de");
        break;
      case 2:
        i18n.changeLanguage("it");
        break;
      case 3:
        i18n.changeLanguage("en");
        break;
      default:
        i18n.changeLanguage("de");
    }

    setAnchorEl(null);
  };

  const languages = ['de', 'it', 'en']

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>

      <Fab size="small" aria-label="language" className={classes.switcher}
        sx={{
          margin: '5px 5px 5px 0px',
          border: 1,
          verticalAlign: 'top',
          boxShadow: 0
        }}
        onClick={handleClick}

      >
        <Typography>{i18n.language}</Typography>

      </Fab>

      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {languages.map((item, index) => {
            return (
              <Button sx={{ p: 2 }} onClick={() => handleClose(index + 1)}>{item}</Button>
            )
          })}
        </Popover>
      </div>

    </>
  )
}
