import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import i18n from "i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export default function InfoButton ({de, it, en, img}) {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [arr, setArr] = useState([]);

    const handleClickOpen = () => {
	setOpen(true);
    };

    const handleClose = () => {
	setOpen(false);
    };

    const theme = createTheme({
	components: {
	    // Name of the component
	    MuiPaper: {
		styleOverrides: {
		    // Name of the slot
		    root: {
			// Some CSS
			maxWidth: "800px",
			borderRadius: "100px",
			boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
		    },
		},
	    },
	},
    });
    
    useEffect(() => {

	if (i18n.language.includes("de"))
	    setArr(de.split("<br>"));

	if (i18n.language.includes("it"))
	    setArr(it.split("<br>"));

	if (i18n.language.includes("en"))
	    setArr(en.split("<br>"));

    }, [i18n.language, de, it, en])


return (

    <>
       <Button 
        style={{
            backgroundColor: "#f79413",
            }}
            className="m-1"
            variant="contained"
            onClick={handleClickOpen}>
               {t("info")}
            </Button>

	<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    fullWidth
    open={open}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "80%",
          maxWidth: "800px",  // Set your width here
        },
      },
    }}
        >

        <DialogTitle id="alert-dialog-title">
          {"Info"}
        </DialogTitle>

        <DialogContent>
	<Box sx={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>

	    <Grid item flexGrow={1}>
		<DialogContentText id="alert-dialog-description">
		{arr.map((p) => {
		    return (<div align="justify" className="p-2"  dangerouslySetInnerHTML={{ __html: p }} />)
		})}
            </DialogContentText>

	    </Grid>

		{img !== undefined && 
		    <Grid item className="p-2">
			<img src={img} alt="picture.png" />
		    </Grid>
		}

	</Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} autoFocus>
              {t('close')}
          </Button>
        </DialogActions>
	</Dialog>
    

    </>

)

}
