// Stationinfo.js
import { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import jpg from "../Terreno_Conc.jpg";
import "../styles.css";
import { Typography } from "@mui/material";
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import Info from '../components/Info';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export default function Home() {

	const { t, i18n } = useTranslation();
	const isAuthenticated = useIsAuthenticated()
	const { trackPageView, trackEvent } = useMatomo()
	const location = useLocation();
	
	const auth = useAuthUser()

		// Track page view
		useEffect(() => {
			trackPageView({
				documentTitle: 'Startseite:' , // optional
				href: 'https://meteo.laimburg.it' + location.pathname, // optional
				customDimensions: [
					{
						id: 1
					},
				], // optional                                                                                                                              
			})
		}, [trackPageView, location])

	return (

		<>
			<div className="stagearea grid p-2">
				<Box sx={{
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column',
					height: '100%'
				}}>
					<div>
						{isAuthenticated() &&
							<>
								Username: {auth().name} <br />
								ROLES: {auth().role.map((d) => { return d + " " })} <br />
								ADMIN: {auth().role.find((d) => { return d === "admin" })} <br />
							</>
						}
					</div>

					<Typography className="center" variant="h4" display="block" >{t("welcome")}</Typography>

					<div className="center_image">
						<img className="center_image" src={jpg} alt="station" />
					</div>

					<Info />


				</Box>

			</div>
			<div className="footer p-2">
				<div className="flex-col footer-text p-2">
					<span><strong>{t("has_1")}</strong></span>
					<span>{t("has_2")}</span>
				</div>
			</div>

		</>

	);
}
