import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
//import { saveAs } from 'file-saver';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import DatePicker from '../../components/DatePicker';
//import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import { logo } from "../../components/d3/charts/chart";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';
import { timeformat, getWidth, responsivness, getMonths } from '../../components/d3/d3timeformat';

import { getCurrentYear, } from '../../js/datetime';

import { borders, addLine, addArea, addLabelToSVG } from '../../components/d3/legend/legend';

export const WinecultureWitterung = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [year, setYear] = useState(getCurrentYear());

    const [data, setData] = useState();
    const [anlage, setAnlage] = useState(1);
    const [anlagen, setAnlagen] = useState([]);
    const [fromdjs, setFromdjs] = useState(dayjs());

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }, [resize, data, anlage, i18n.language]);

    useEffect(() => {
        setData(undefined);
        d3.json(preferences.address + "/query/wineculture/witterung/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [year, parameter.station]);

    useEffect(() => {

        d3.json(preferences.address + "/query/anlagen.php")
            .then((data) => {
                setAnlagen(data);
            });
    }, []);

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Phänologie Ganttchart', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])

    /*const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/dry/excel.php';

        let block = {};
        block.station = parameter.station;
        //block.year = year;
        //block.period = period;
        //block.level = threshold;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }*/
    function avg(list) {
        let sum = 0;
        list.forEach((item) => { sum += item; })
        return sum / list.length;
    }


    function draw(svg) {

        /*var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);*/
        timeformat(t);

        // Clear
        svg.selectAll("*").remove();


        const margin = {
            top: 20,
            right: 80,
            bottom: 20,
            left: 100,
            middle: 100,
            graph: 200
        }; // margin of chart

        //let FROM = year+"-03-10";
        //let TO = year+"-09-01";

        let width = parseInt(svg.style("width"));

        //console.log (width);
        let parseTime = d3.timeParse("%Y-%m-%d");

        // Create scale
        let step = 40; // Pro zeile, Jahr

        // Anzahl Jahre
        let years = data.keys.length;
        if (years === 0) years = 1;

        let height = margin.top + margin.bottom + 2 * margin.graph + (2) * margin.middle;
        svg.attr("height", height);

        // Add scales to axis
        //var xScale = d3.scaleLinear()
        var xScaleBand = d3.scaleBand()
            .domain([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
            .range([0, width - margin.right - margin.left])
            .padding(0.6)

        // X-Axis
        /*svg.append("g")
            .attr("transform", `translate(${margin.left}, ${height-margin.bottom})`)
            .call(x_axis.tickFormat((x) => {return x + " " + t("month") }).ticks(12)) */
        // X-grid

        let months = getMonths(t); //[t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];
        var xScaleLabel = d3.scaleBand()
            .domain(months.map((d) => { return t(d) }))
            .range([0, width - margin.left - margin.right])
            .padding(0.6);
        var x_axis = d3.axisBottom()
            .scale(xScaleLabel);
        svg.append("g")
            .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
            .call(x_axis.tickFormat((x) => { return x }).ticks(12))
        svg.append("g")
            .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
            .attr("class", "gridline")
            .call(x_axis.tickSize(-height + margin.bottom + margin.top).tickFormat("").ticks(12));

        // Add scales to axis
        var yScale1 = d3.scaleLinear()
            .domain([0, 31])
            .range([margin.top + margin.graph, margin.top])
        var y_axis1 = d3.axisLeft()
            .scale(yScale1);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis1.tickFormat((x) => { return x + " " + t("days") }).ticks(5))
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis1.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))

        var yScale1a = d3.scaleLinear()
            .domain([-15, 15])
            .range([margin.top + margin.graph + margin.middle - 15, margin.top + margin.graph + 15])
        var y_axis1a = d3.axisLeft()
            .scale(yScale1a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis1a.tickFormat((x) => { return x + " " + t("days") }).ticks(3))
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis1a.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))



        // Add scales to axis
        var yScale2 = d3.scaleLinear()
            .domain([0, 400])
            .range([margin.top + 2 * margin.graph + (2 - 1) * margin.middle, margin.top + (2 - 1) * margin.graph + (2 - 1) * margin.middle])

        var y_axis2 = d3.axisLeft()
            .scale(yScale2);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis2.tickFormat((x) => { return x + " " + t("hours") }).ticks(10))
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis2.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(10))

        var yScale2a = d3.scaleLinear()
            .domain([-100, 100])
            .range([margin.top + margin.graph * 2 + margin.middle * 2 - 15, margin.top + margin.graph * 2 + margin.middle + 15])
        var y_axis2a = d3.axisLeft()
            .scale(yScale2a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis2a.tickFormat((x) => { return x + " " + t("hours") }).ticks(3))
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis2a.tickSize(-width + margin.left + margin.right).tickFormat("").ticks(5))


        // Add scales to axis
        /*var yScale3 = d3.scaleLinear()
            .domain([0, 31])
            .range([margin.top + 3 * margin.graph + (3-1) * margin.middle, margin.top + (3-1) * margin.graph + (3-1) * margin.middle])
    
            var y_axis3 = d3.axisLeft()
                .scale(yScale3);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis3.tickFormat((x) => {return x + " " + t("days") }).ticks(5)) 
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis3.tickSize(-width+margin.left+margin.right).tickFormat("").ticks(5)) 
    
        var yScale3a = d3.scaleLinear()
            .domain([-15, 15])
            .range([margin.top + margin.graph * 3 + margin.middle*3 - 15, margin.top + margin.graph * 3 + margin.middle*2 + 15])
            var y_axis3a = d3.axisLeft()
                .scale(yScale3a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis3a.tickFormat((x) => {return x + " " + t("days") }).ticks(3)) 
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis3a.tickSize(-width+margin.left+margin.right).tickFormat("").ticks(5)) 
    
            // Add scales to axis
        var yScale4 = d3.scaleLinear()
            .domain([0, 31])
            .range([margin.top + 4 * margin.graph + (4-1) * margin.middle, margin.top + (4-1) * margin.graph + (4-1) * margin.middle])
    
            var y_axis4 = d3.axisLeft()
                .scale(yScale4);
        // Axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis4.tickFormat((x) => {return x + " " + t("days") }).ticks(5)) 
        // grid
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis4.tickSize(-width+margin.left+margin.right).tickFormat("").ticks(5)) 
    
        var yScale4a = d3.scaleLinear()
            .domain([-15, 15])
            .range([margin.top + margin.graph * 4 + margin.middle*4 - 15, margin.top + margin.graph * 4 + margin.middle*3 + 15])
            var y_axis4a = d3.axisLeft()
                .scale(yScale4a);
        svg.append("g")// Axis
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(y_axis4a.tickFormat((x) => {return x + " " + t("days") }).ticks(3)) 
        svg.append("g")// grid
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(y_axis4a.tickSize(-width+margin.left+margin.right).tickFormat("").ticks(5)) */

        var thick = 3.5
        var thin = 1.5;

        var graph = svg.append("g");

        // Regentage
        let color = "steelblue";
        graph.append("path")
            .datum(data)
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("id", "line")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", thick)
            .attr("d", d3.line()
                .x((d) => { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
                .y((d) => { return yScale1(d.rain) })
            )
        graph
            .selectAll("vertLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", (d, index) => { return xScaleBand(index + 1) })
            .attr("x2", (d, index) => { return xScaleBand(index + 1) })
            .attr("y1", (d, index) => { return yScale1(d3.min(data[index].rain_mid)) })
            .attr("y2", (d, index) => { return yScale1(d3.max(data[index].rain_mid)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40);
        graph
            .selectAll("boxes")
            .data(data)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale1(d3.quantile(data[index].rain_mid, 0.75)) })
            .attr("height", (d, index) => { return yScale1(d3.quantile(data[index].rain_mid, 0.25)) - yScale1(d3.quantile(data[index].rain_mid, 0.75)) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.7)
            .attr("rx", 5);
        graph
            .selectAll("horLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("y1", function (d, index) { return yScale1(d3.quantile(data[index].rain_mid, 0.5)) })
            .attr("y2", function (d, index) { return yScale1(d3.quantile(data[index].rain_mid, 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40)
        graph
            .selectAll("boxes")
            .data(data.filter((d, i) => { if (year < dayjs().year()) return true; return (i - 1) < (dayjs().month()); }))
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale1a(data[index].rain > data[index].rain_avg ? data[index].rain - data[index].rain_avg : 0) })
            .attr("height", (d, index) => { return yScale1a(0) - yScale1a(data[index].rain_avg > data[index].rain ? data[index].rain_avg - data[index].rain : data[index].rain - data[index].rain_avg) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.9)
            .append('title')
            .text((d) => Math.round((d.rain - d.rain_avg) * 10) / 10);
        graph.append('line')
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("x1", margin.left)
            .attr("x2", width - margin.right)
            .attr("y1", yScale1a(0))
            .attr("y2", yScale1a(0))

        // Sonnenscheindauer
        color = "orange";
        graph.append("path")
            .datum(data)
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("id", "line")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", thick)
            .attr("d", d3.line()
                .x((d) => { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 })
                .y((d) => { return yScale2(d.sun) })
            )
        graph
            .selectAll("vertLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", (d, index) => { return xScaleBand(index + 1) })
            .attr("x2", (d, index) => { return xScaleBand(index + 1) })
            .attr("y1", (d, index) => { return yScale2(d3.min(data[index].sun_mid)) })
            .attr("y2", (d, index) => { return yScale2(d3.max(data[index].sun_mid)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40);
        graph
            .selectAll("boxes")
            .data(data)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale2(d3.quantile(data[index].sun_mid, 0.75)) })
            .attr("height", (d, index) => { return yScale2(d3.quantile(data[index].sun_mid, 0.25)) - yScale2(d3.quantile(data[index].sun_mid, 0.75)) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.7)
            .attr("rx", 5);
        graph
            .selectAll("horLines")
            .data(data)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + xScaleBand.bandwidth() })
            .attr("y1", function (d, index) { return yScale2(d3.quantile(data[index].sun_mid, 0.5)) })
            .attr("y2", function (d, index) { return yScale2(d3.quantile(data[index].sun_mid, 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .style("width", 40)

        graph
            .selectAll("boxes")
            .data(data.filter((d, i) => { if (year < dayjs().year()) return true; return (i - 1) < (dayjs().month()); }))
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", (d, index) => { return xScaleBand(index + 1) })
            .attr("y", (d, index) => { return yScale2a(data[index].sun > data[index].sun_avg ? data[index].sun - data[index].sun_avg : 0) })
            .attr("height", (d, index) => { return yScale2a(0) - yScale2a(data[index].sun_avg > data[index].sun ? data[index].sun_avg - data[index].sun : data[index].sun - data[index].sun_avg) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", color)
            .attr("opacity", 0.9)
            .append('title')
            .text((d) => Math.round((d.sun - d.sun_avg) * 10) / 10);
        graph.append('line')
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("x1", margin.left)
            .attr("x2", width - margin.right)
            .attr("y1", yScale2a(0))
            .attr("y2", yScale2a(0))

        // X Label
        svg.append("text")
            .attr("class", "x label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", 15)
            .text(t("months"));

        const box = svg.append("g").attr('id', 'block');

        logo(svg);

        let labels = [t("rainday"), t("dursunshine")]
        let colors = ["steelblue", "orange"];

        let r = responsivness();
        if (r.dialog) {
            addLabelToSVG(svg, 100, margin.top, colors[0], labels[0])
            addLabelToSVG(svg, 100, margin.top + margin.graph + margin.middle, colors[1], labels[1])
        }
    }

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }


    return (
        <>
            <div className="stagearea grid p-2">

                <Stack spacing={0}>

                    <Item>
                        <DatePicker
                            title="year"
                            value={fromdjs}
                            callback={handleSelector}
                            view={["year"]}
                            format="YYYY"
                        />
                    </Item>

                </Stack>


                <div id="svgchart">
                    {data !== undefined && data.length > 0
                        ?
                        <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
                        :
                        <div>No data available</div>
                    }
                </div>

                <Grid container flexDirection={"row"}>

                    <Grid item className="p-2" flexGrow={1}>
                        <InfoButton
                            de={"Die Boxplots der Regentage und Sonnenscheindauer sind im Bereich witterungsabhängige Kenntage dargestellt. Der Verlauf des ausgewählten Jahres wird als Linie dargestellt. Zum Vergleich gibt ein Boxplot pro Monat die Streuung der monatlichen Vergleichswerte der vorangehenden 30 Jahre wieder. Die eingefärbte Box markiert den Bereich, in dem sich die mittleren 50% der Messwerte der vergangenen Jahre befinden, wobei die Linie in der Mitte der Box den Medianwert der vorangehenden 30 Jahre darstellt, welcher die Mitte der Datenverteilung angibt. Die vertikalen Linien (Schnurrhaare) erstrecken sich vom niedrigsten und zum höchsten gemessenen Wert. Das Balkendiagramm unter dem Boxplot zeigt die Abweichung des aktuellen Jahres vom langjährigen Medianwert an. Mittels Mausklick auf den entsprechenden Balken können die Abweichungswerte direkt in der Grafik angezeigt werden."}
                            it={"I boxplot dei giorni di pioggia e della durata dell’ insolazione sono mostrati nell'area dei giorni di riferimento delle condizioni meteo. L'andamento dell'anno selezionato è rappresentato da una linea. A titolo di confronto, un boxplot per ogni mese mostra la dispersione dei valori mensili comparativi per i 30 anni precedenti. Il riquadro colorato indica l'area in cui si trova il 50% medio dei valori misurati negli anni precedenti, mentre la linea al centro del riquadro rappresenta il valore mediano dei 30 anni precedenti, che indica la parte centrale della distribuzione dei dati. Le linee verticali (baffi) si estendono dal valore più basso a quello più alto mai misurato. Il grafico a barre sotto il riquadro mostra la deviazione dell'anno corrente dal valore mediano a lungo termine. I valori di deviazione possono essere visualizzati direttamente nel grafico facendo clic sulla barra corrispondente."}
                            en={"In the section “Rain days and Sunshine duration” boxplots of the monthly data of these parameters are displayed. The course of the selected year is shown as a line; for comparison, a boxplot per month shows the spread of the monthly comparative values for the previous 30 years. The colored box marks the area in which the middle 50% of the measured values of the past years are located, whereby the line in the middle of the box represents the median value of the previous 30 years, which indicates the middle of the data distribution. The vertical lines (whiskers) extend from the lowest to the highest measured value. The bar chart below the box plot shows the deviation of the current year from the long-term median value. The deviation values can be displayed directly in the graph by clicking on the corresponding bar."}
                        />
                    </Grid>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("kenntage")} />
                    </Grid>

                    <Grid item className="p-2">
                        {/* <ExcelButton callback={downloadExcel} /> */}
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
