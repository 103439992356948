import * as d3 from "d3";

const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;


const timeformat = (t) => {
    var ID_Time = null;

    if (getWidth() > 900) {
        ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
    }
    else {
        ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("w_0"), t("w_1"), t("w_2"), t("w_3"), t("w_4"), t("w_5"), t("w_6")],
            "shortDays": [t("w_0"), t("w_1"), t("w_2"), t("w_3"), t("w_4"), t("w_5"), t("w_6")],
            "months": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
    }
    d3.timeFormatDefaultLocale(ID_Time);
}

function responsivness() {

    let res = {};
    if (getWidth() < 800)
        res.dialog = false;
    else
        res.dialog = true;

    return res;
}

const getMonths = (t) => {
    if (getWidth() > 900)
        return [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")];
    else
        return [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")];
}


export { timeformat, getWidth, responsivness, getMonths };