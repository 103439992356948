import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../../js/datetime';

import { GraphContext } from "../../GraphContext";
import "../../../styles.css";
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import { preferences } from "../../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../../components/ExcelButton';
import GraphicButton from '../../../components/GraphicButton';
import InfoButton from '../../../components/InfoButton';

import { xlabel, ylabel, logo } from '../../../components/d3/charts/chart';
import { timeformat, getWidth, responsivness, getMonths } from '../../../components/d3/d3timeformat';

import ComboBox from '../../../components/ComboBox';
import DatePicker from '../../../components/DatePicker';
import SensorComboBox from '../../../components/SensorComboBox';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const Boxplot = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const [t, i18n] = useTranslation();
    const [parameter] = useContext(GraphContext);
    const [sensors, setSensors] = useState();
    const [fromdjs, setFromdjs] = useState(dayjs());
    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());

    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());
    const [classification, setClassification] = useState(1);
    const [sensor, setSensor] = useState(1);
    const [sensorstring, setSensorstring] = useState(); // "airtemp2m"

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Allgemein Boxplot', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }); // , [resize, data]

    /* Sensors */
    useEffect(() => {

        let station = parameter.station;
        var url = preferences.address + '/rest/sensors.php?station=' + station;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                data.map((sensor) => {
                    sensor.main = true;
                    sensor.min = false;
                    sensor.max = false;
                    return 0;
                })

                setSensors(data);
                setSensorstring("airtemp2m");
                setSensor(2);
            });
    }, [parameter])

    useEffect(() => {
        if (sensorstring !== undefined) {
            switch (classification) {
                case 1:
                    d3.json(preferences.address + "/query/seasons/boxplot/season/get.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year)
                        .then((data) => {
                            setData(data);
                        });
                    break;
                case 2:
                    d3.json(preferences.address + "/query/seasons/boxplot/month/get.php?station=" + parameter.station + "&sensor=" + sensorstring + "&year=" + year)
                        .then((data) => {
                            setData(data);
                        });
                    break;
                default:
                    break;
            }
        }
    }, [i18n.language, parameter, year, classification, sensorstring]);

    const downloadExcel = () => {

        var url;
        switch (classification) {
            case 1:
                url = preferences.address + '/query/seasons/boxplot/season/excel.php';
                break;
            case 2:
                url = preferences.address + '/query/seasons/boxplot/month/excel.php';
                break;
            default:
                break;
        }


        let block = {};
        block.station = parameter.station;
        block.sensor = sensorstring;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }


    //var width = 0;

    function draw(svg) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 10,
            right: 20,
            left: 80,
            bottom: 40
        }; // margin of chart

        // Getting width
        let width = parseInt(svg.style("width"));
        //console.log (width);

        let height = 400;
        //let width = svg.attr("width");

        let inner_width = width - margin.right - margin.left;
        //let inner_height = height - margin.top - margin.bottom;

        let min = 0;
        let max = 0;

        if (data !== undefined) {
            let keys = Object.keys(data.seasons);

            min = d3.min(keys.map((l) => { return d3.min(data.seasons[l].map((d) => { return d.val })) }));
            let minC = d3.min(data.current.map((d) => { return d.val }));
            min = d3.min([minC, min]);
            max = d3.max(keys.map((l) => { return d3.max(data.seasons[l].map((d) => { return d.val })) }));
            let maxC = d3.max(data.current.map((d) => { return d.val }));
            max = d3.max([d3.max(data.current.map((d) => { return d.val })), max, maxC]);
        }

        svg
            .attr("width", width)
            .attr("height", height);

        // Create scale
        let xScaleBand = d3.scaleBand()
            .domain(data.keys) // seasons
            .range([0, width - margin.right - margin.left])
            .padding(0.1);

        let xScaleBandLabel = d3.scaleBand()
            //.domain(data.labels.map((d) => {return t(d) })) // seasons
            .domain(classification === 1 ? data.labels.map((d) => { return t(d) }) : getMonths(t))
            .range([0, width - margin.right - margin.left])
            .padding(0.1);

        // Add scales to axis
        var x_axis = d3.axisBottom()
            .scale(xScaleBandLabel);

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + (height - margin.bottom) + ")")
            .attr("class", "axes")
            .call(x_axis);

        // Create scale
        var yScale = d3.scaleLinear()
            .domain([min, max])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale)

        // Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("class", "axes")
            .call(y_axis.ticks(10, "0.1f").tickFormat((x) => { return x + " " + data.unit }));

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ", 0)")
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-inner_width, 0).tickFormat(""));

        // rectangle for the main box

        let s = xScaleBand.bandwidth() / 2;

        let datagroup = svg.append('g').attr('id', 'datagroup');
        let dataline = svg.append('g').attr('id', 'dataline');
        // Show the main vertical line
        //console.log (data.seasons);

        datagroup
            .selectAll("vertLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) })
            .attr("y1", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        datagroup
            .selectAll("upperLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        datagroup
            .selectAll("lowerLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)


        const colors = ["#641e16", "#78281f", "#512e5f", "#154360", "#1b4f72", "#0e6251", "#0b5345", "#0b5345",
            "#7d6608", "#7e5109", "#784212", "#6e2c00", "#7b7d7d", "#626567", "#4d5656", "#424949", "#1b2631"];

        datagroup
            .selectAll("boxes")
            .data(data.seasons)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", function (d, index) { return xScaleBand(index + 1) })
            .attr("y", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.75)) })
            .attr("height", function (d) { return (yScale(d3.quantile(d.map((e) => { return e.val }), 0.25)) - yScale(d3.quantile(d.map((e) => { return e.val }), 0.75))) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", colors[sensor])
            .attr("rx", 5);

        datagroup
            .selectAll("horLines")
            .data(data.seasons)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + 2 * s })
            .attr("y1", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("y2", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        const l = [];
        //console.log (data.seasons);
        try {
            data.seasons.map((e, i) => {
                e.map((j, k) => { let t = {}; t.season = j.season; t.val = j.val; l.push(t); return 0; }); return 0;
            });
        } catch (err) { console.log(err); }

        var jitterWidth = 50;
        datagroup
            .selectAll("points")
            .data(l)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("cx", function (d, index) { return xScaleBand(d.season) - jitterWidth / 2 + Math.random() * jitterWidth })
            .attr("cy", function (d, index) { return yScale(d.val) })
            .attr("r", 2)
            .style("fill", "white")
            .attr("stroke", "blue");

        // Add the line current year
        dataline.append("path")
            .datum(data.current)
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x(function (d, index) { return xScaleBand(d.season) })
                .y(function (d) { return yScale(d.val) })
            )

        dataline.selectAll("circles")
            .data(data.current)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "white")
            .attr("stroke", "orange")
            .attr("cx", function (d) { return xScaleBand(d.season) })
            .attr("cy", function (d) { return yScale(d.val) })
            .attr("r", 4)

        xlabel(svg, t(data.xLabel), width / 2, height - 5);

        ylabel(svg, t(data.yLabel), -height * 0.5, 15);
        logo(svg);
    }

    const handleChangeClassification = (event) => {
        setClassification(event.target.value);
    };

    const handleSensor = (event) => {

        //let station = parameter.station;
        let index = event.target.value - 1;

        setSensor(event.target.value);
        setSensorstring(sensors[index].cat);
    }

    let c = [t("seasons"), t("months")];

    return (
        <>
            <Stack spacing={0}>

                <Item>

                    {sensors !== undefined &&
                        <SensorComboBox
                            title={"sensor"}
                            value={sensor} items={sensors}
                            callback={handleSensor} />
                    }

                </Item>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

                <Item>
                    <ComboBox
                        title={"classification"}
                        value={classification} items={c}
                        callback={handleChangeClassification} />
                </Item>

            </Stack>



            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"<p>Im Bereich Jahreszeiten werden die Werte der 4 Jahreszeiten Frühjahr, Sommer, Herbst und Winter angezeigt . Die Monate März, April, Mai werden zum Frühjahr zusammengefasst, die Monate Juni, Juli und August zum Sommer, die Monate September, Oktober und November zum Herbst und die Monate Dezember des vorherigen Jahres mit den Monaten Jänner und Februar zum Winter. Dies gilt für alle drei Darstellungsarten: Boxplot, Scatterplot und Proplot. Im Feld Sensor kann der gewünschte Parameter einer ausgewählten Wetterstation eingestellt werden.</p> <p>Die interaktive Darstellung „Boxplot“ zeigt den Verlauf der monatlichen oder jahreszeitlichen Werte eines Sensors (orange Linie) des ausgewählten Jahres an. Zum Vergleich gibt ein Boxplot die Streuung und den Median der monatlichen Vergleichswerte der vorangehenden 30 Jahre wieder. Jeder Punkt repräsentiert den Durchschnittswert bzw. Summenwert eines bestimmten Monats bzw. einer bestimmten Jahreszeit. Die eingefärbte Box markiert den Bereich, in dem sich die mittleren 50% der Messwerte der vergangenen Jahre befinden, wobei die Linie in der Mitte der Box den Medianwert der vorangehenden 30 Jahre darstellt, welcher die Mitte der Datenverteilung angibt. Die vertikalen Linien (Schnurrhaare) erstrecken sich vom niedrigsten und zum höchsten gemessenen Wert.</p>"}
                        it={"<p>Nella sezione Stagioni vengono visualizzati i valori delle quattro stagioni (Primavera, Estate, Autunno e Inverno). I mesi di marzo, aprile e maggio vengono raggruppati come Primavera, i mesi di giugno, luglio e agosto come Estate, i mesi di settembre, ottobre e novembre come Autunno e i mesi di dicembre dell'anno precedente insieme ai mesi di gennaio e febbraio come Inverno. Questo raggruppamento vale per tutte le modalità di visualizzazione: Boxplot, Scatterplot e Proplot. Nel campo “Sensore” è possibile impostare il parametro desiderato di una stazione meteorologica selezionata.</p><p> La visualizzazione interattiva 'Boxplot' mostra l'andamento dei valori mensili o stagionali di un sensore (linea arancione) dell'anno selezionato. Un Boxplot rappresenta la distribuzione dei dati e il mediano dei valori mensili di confronto dei 30 anni precedenti. Ogni punto rappresenta il valore medio o cumulativo di un determinato mese o stagione. Il Box colorato indica l'intervallo in cui si trovano i valori medi del 50% delle misurazioni degli anni precedenti, con la linea al centro del Box che rappresenta il mediano dei 30 anni precedenti che indica il centro della distribuzione dei dati. Le linee verticali (whiskers) si estendono dal valore minimo a quello massimo misurato.</p>"}
                        en={"<p>In the seasons area, the values of the 4 seasons spring, summer, fall and winter are displayed. The months of March, April and May are grouped together as spring, the months of June, July and August as summer, the months of September, October and November as fall and the months of December of the previous year with the months of January and February as winter. This applies to all three display types: box plot, scatter plot and pro plot. The desired parameter of a selected weather station can be set in the sensor field. The interactive “Boxplot” display shows the monthly or seasonal values of a sensor (orange line) for the selected year. For comparison, a boxplot shows the dispersion and the median of the monthly comparison values of the previous 30 years. Each point represents the average value or total value of a specific month or season. The colored box marks the area in which the middle 50% of the measured values of the past years are located, whereby the line in the middle of the box represents the median value of the previous 30 years, which indicates the middle of the data distribution. The vertical lines (whiskers) extend from the lowest to the highest measured value.</p>"}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object={"svg_chart"} filename={t("boxplot") + "-" + t(sensorstring)} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>
            </Grid>

        </>
    );

}
