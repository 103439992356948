import * as d3 from "d3";


/*
  
*/
function legend (svg, margins, labels)
{

    let breite = 180;

    var legend = svg.append("g")
    .attr('id', 'legend')
    .attr("class", "legend")
    .attr('transform', 'translate(' + (margins.width - margins.right - breite) + ' ,'+margins.top+')');

    // Frame
    legend.append("rect")
    .attr("class", "legend")
    .attr("x", 0 )
    .attr("y", 0)
    .attr("rx", 5)
    .attr("ry", 5)
    .attr("fill", "white")
    .attr("stroke", "black")
    .attr("stroke-width", 1.5)
    .attr("opacity", 0.8)
    .attr("height", labels.length * 25)
    .attr("width", breite)

    labels.map ((item, index) => {

        // Checkbox
        legend.append("rect")
        .attr("x", 10)
        .attr("y", 15 + index*20)
        .attr("rx", 1)
        .attr("ry", 1)
        .attr("fill", "white")
        .attr("stroke", "black")
        .attr("stroke-width", 2)
        .attr("height", 10)
        .attr("width", 10)
        .on("click", function () {
            var path = svg.select('#path_' + index);
            if (path.style('display') == 'inline')
                path.style('display', 'none');
            else
                path.style('display', null);
            /*var dot = svg.select('#dot_' + index);
            if (dot.style('display') == 'inline')
                dot.style('display', 'none');
            else
                dot.style('display', null);*/
            });

        // Linie
        legend.append("line")
        .attr("x1", 35)
        .attr("y1", 20 + index*20 )
        .attr("x2", 70)
        .attr("y2", 20 + index*20 )
        .attr("stroke", d3.schemeSet1[index])
        .attr("stroke-width", 6);

        // Text
        legend.append("text")
        .attr('class', 'legend')
        .attr('id', 1)
        .attr("stroke",  "black")
        .attr("x", 85)
        .attr("y", 25 + index*20 )
        .text(item);

    })
}

function borders (svg, labels, breite, left, top)
{
    let h = svg.attr('height');
    let w = svg.attr('width');
    
    var legend = svg.append("g")
    .attr('id', 'legend')
    .attr("class", "legend")
    .attr('transform', 'translate(' + (left) + ' ,'+top+')');

    // Frame
    legend.append("rect")
    .attr("class", "legend")
    .attr("x", 0 )
    .attr("y", 0)
    .attr("rx", 5)
    .attr("ry", 5)
    .attr("fill", "white")
    .attr("stroke", "black")
    .attr("stroke-width", 1.5)
    .attr("opacity", 0.8)
    .attr("height", 20 + labels.length * 20)
    .attr("width", breite)
}

function bordersH (svg, labels, breite, left, top)
{
    let h = svg.attr('height');
    let w = svg.attr('width');
    
    var legend = svg.append("g")
    .attr('id', 'legend')
    .attr("class", "legend")
    .attr('transform', 'translate(' + (left) + ' ,'+top+')');

    // Frame
    legend.append("rect")
    .attr("class", "legend")
    .attr("x", 0 )
    .attr("y", 0)
    .attr("rx", 5)
    .attr("ry", 5)
    .attr("fill", "white")
    .attr("stroke", "black")
    .attr("stroke-width", 1.5)
    .attr("opacity", 0.8)
    .attr("height", 30)
    .attr("width", breite)
}

function addLine (svg, index, stroke, color, label, marker)
{
    var legend = svg.selectAll("#legend");

    // Linie
        legend.append("line")
        .attr("x1", 20)
        .attr("y1", 20 + index * 20) 
        .attr("x2", 70)
        .attr("y2", 20 + index * 20)
        .attr("stroke", color)
        .attr("stroke-width", stroke);

        // Text
        let t = legend.append("text")
        .attr('class', 'legend')
        .attr('id', 1)
        .attr("stroke",  "black")
        .attr("x", 85)
        .attr("y", 25 + index * 20 )
        .text(label);

	if (marker !== undefined)
	t.on("click", () => {
	    let e = svg.selectAll(marker);
	    if (e.attr("display") === "none")
		e.attr("display", null);
	    else
		e.attr("display", "none");
	})

}


function addLineH (svg, index, stroke, color, label)
{
    var legend = svg.selectAll("#legend");

    // Linie
        legend.append("line")
        .attr("x1", 20)
        .attr("y1", 20 + index * 20) 
        .attr("x2", 70)
        .attr("y2", 20 + index * 20)
        .attr("stroke", color)
        .attr("stroke-width", stroke);

        // Text
        legend.append("text")
        .attr('class', 'legend')
        .attr('id', 1)
        .attr("stroke",  "black")
        .attr("x", 85)
        .attr("y", 25 + index * 20 )
        .text(label);

}

function addArea (svg, index, color, label, legend="#legend")
{
    var legend = svg.selectAll(legend);

        legend.append("rect")
        .attr("x", 20)
        .attr("y", 15 + index*20)
        .attr("rx", 1)
        .attr("ry", 1)
        .attr("fill", color)
        .attr("stroke", "black")
        .attr("stroke-width", 2)
        .attr("height", 10)
        .attr("width", 50)
	.attr("opacity", 0.5);

        // Text
        legend.append("text")
        .attr('class', 'legend')
        .attr('id', 1)
        .attr("stroke",  "black")
        .attr("x", 85)
        .attr("y", 25 + index*20 )
        .text(label);
}

function addLabelToSVG (svg, x, y, color, label)
{
    let legend = svg.append('g').attr('transform', "translate("+x+", "+y+")");

        legend.append("rect")
        .attr("x", 20)
        .attr("y", 15)
        .attr("rx", 1)
        .attr("ry", 1)
        .attr("fill", color)
        .attr("stroke", "black")
        .attr("stroke-width", 2)
        .attr("height", 10)
        .attr("width", 50)
	.attr("opacity", 0.5);

        // Text
        legend.append("text")
        .attr('class', 'legend')
        .attr('id', 1)
        .attr("stroke",  "black")
        .attr("x", 85)
        .attr("y", 25)
        .text(label);
}

function addAreaH (svg, index, width, color, label)
{
    var legend = svg.selectAll("#legend");

        legend.append("rect")
        .attr("x", 20 + index*width)
        .attr("y", 7.5 )
        .attr("rx", 1)
        .attr("ry", 1)
        .attr("fill", color)
        .attr("stroke", "black")
        .attr("stroke-width", 1)
        .attr("height", 15)
        .attr("width", 50)
	.attr("opacity", 0.7);

        // Text
        legend.append("text")
        .attr('class', 'legend')
        .attr('id', 1)
        .attr("stroke",  "black")
        .attr("x", 85 + index*width)
        .attr("y", 20 )
        .text(label);
}

function addPoint (svg, index, color, label)
{
    var legend = svg.selectAll("#legend");

        legend.append("circle")
        .attr("cx", 20)
        .attr("cy", 20 + index*20)
        .attr("fill", color)
        .attr("stroke", color)
	.attr("r", 6)
	.attr("opacity", 0.5);

        // Text
        legend.append("text")
        .attr('class', 'legend')
        .attr('id', 1)
        .attr("stroke",  "black")
        .attr("x", 45)
        .attr("y", 25 + index*20 )
        .text(label);
}

export { legend, borders, bordersH, addLine, addArea, addAreaH, addPoint, addLabelToSVG };
