import * as React from 'react';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import FadeMenu from "./FadeMenu";

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

import logo from '../logo.png';
import ki from '../images/Header.png';
import LoginDialog from '../components/LoginDialog';
import Language from '../components/Language';
import { useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useSignOut } from 'react-auth-kit'

const settings = ['Login'];

function NavBar() {

  const isAuthenticated = useIsAuthenticated()
  const auth = useAuthUser()
  const signOut = useSignOut()

  const [t] = useTranslation();

  const [login, setLogin] = useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setLogin(true);
  };

  const handleOpenLogin = () => {
    setLogin(true);
  };

  const menuList = [];

    const menu = {
			"reports": [{"name": t("monatsreport"), "link": "/report/month", "auth": ""},
				 {"name": t("monatstabelle"), "link": "/overview/month", "auth": ""},
				 {"name": t("jahrestabelle"), "link": "/overview/year", "auth": ""},
				 {"name": t("witterungsberichte"), "link": "/overview/witterung", "auth": ""},
				 {"name": t("ranking"), "link": "/overview/stat", "auth": ""}],

			"verlauf": [{"name": t("temperature"), "link": "/temperature", "auth": ""},
				{"name": t("rain"), "link": "/rain", "auth": ""},
				{"name": t("seasons"), "link": "/seasons", "auth": ""},
				{"name": t("Kenntage"), "link": "/kenntage", "auth": ""},
				{"name": t("Jahresverläufe"), "link": "/development", "auth": ""},
        {"name": t("heatmap"), "link": "/heatmap", "auth": ""},
				{"name": t("ranking"), "link": "/absolute", "auth": ""}],

			"phenology_pom": [{"name": t("phenology_overview"), "link": "/phenology/overview", "auth": ""},
				{"name": t("phenology_stats"), "link":"/phenology/states", "auth": ""},
				{"name": t("phenology_compare"), "link":"/phenology/diagramm", "auth": ""},
				{"name": t("phenology_correlation"), "link":"/phenology/correlation", "auth": ""},
				{"name": t("phenology_harvest"), "link": "/phenology/harvest", "auth": "user"}],

			"phenology_wine": [{"name": t("phenology_stats"), "link": "/phenology/wineculture", "auth": ""},
					    {"name": t("phenology_analysis"), "link": "/phenology/wineanalysis", "auth": ""},
				],

			"admin": [	{"name": t("witterungsberichte"), "link": "/private/witterungsberichte", "auth": ""},
				{"name": t("datenfehler"), "link": "/private/errors", "auth": ""},
				{"name": t("phenology_pom"), "link": "/private/phenologypomo", "auth": ""},
				{"name": t("phenology_wine"), "link": "/private/phenologywine", "auth": ""},
				{"name": t("reifedaten"), "link": "/private/reifedaten", "auth": ""}
				 ],

			"modells": [	{"name": t("clever"), "link": "/models/clever", "auth": ""},
				{"name": t("saturation"), "link": "/models/saturation", "auth": ""},
				{"name": t("mills"), "link": "/models/mills", "auth": ""},
				{"name": t("marschall"), "link": "/models/marschall", "auth": ""},
				{"name": t("Extreme"), "link": "/extreme", "auth": ""}
				]
};

  function addToMenu(n, l, a = "") {
    if (a.length === 0)
        menuList.push({ menu: t(n), link: l })
    else
    {
	if (isAuthenticated() && (auth().role.includes("user")))
    	    menuList.push({ menu: t(n), link: l })
    }
  }

  addToMenu("diagramme&daten", "/graph");

    menu.reports.forEach ((item) => { addToMenu(t("report") + " - " + item.name, item.link, item.auth);})
    menu.verlauf.forEach ((item) => { addToMenu(t("LangjährigeVerläufe") + " - " + item.name, item.link, item.auth);})
    menu.phenology_pom.forEach ((item) => { addToMenu(t("phenology_pom") + " - " +item.name, item.link, item.auth);})

    if (isAuthenticated() && (auth().role.includes("user")))
	menu.phenology_wine.forEach ((item) => { addToMenu(t("phenology_wine") + " - " +item.name, item.link, item.auth);})

  addToMenu(t("InfoStationen"), "/info");
  addToMenu(t("Stationsvergleich"), "/general");

  if (isAuthenticated() && (auth().role.includes("admin"))) {
    menu.admin.forEach ((item) => { addToMenu(t("admin") + " - " +item.name, item.link, item.auth);})
  }

  if (isAuthenticated()) {
    menu.modells.forEach ((item) => { addToMenu(t("modells") + " - " +item.name, item.link, item.auth);})
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (open) => (event) => {

    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, top: !state.top });
  };

  {/* Drawer menu list */ }
  const list = (anchor) => (
    <Box
      className="topdrawer"
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        backgroundColor: '#40516f'
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Divider />

      <List>
        {menuList.map((row, index) => (
          <>
            <Divider />
            <Link to={row.link}>
              <ListItem key={index} disablePadding>
                <ListItemButton >
                  <ListItemText sx={{
                    textDecoration: 'none',
                    color: 'white'
                  }}
                    primary={row.menu} />
                </ListItemButton>
              </ListItem>
            </Link>
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{
      backgroundColor: "#40516f"
    }}>
      <Container maxWidth="xl">

        <Toolbar >

          {/* <Box sx={{ display: { xs: 'none', md: 'flex', ld: 'flex' }, mr: 1 }}> */}
          <Box className="navbar-logo">
            <NavLink to="/">
              <img className="logo" src={logo} alt="Logo" />
            </NavLink>
          </Box>

          <Typography className="navbar-logo"
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              /*display: { xs: 'none', md: 'flex' },*/
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Meteo
          </Typography>

	    {/* 
          <Box className="navbar-logo">
            <NavLink to="/">
	      <img className="logo" src={ki} alt="Apples&Bloom" height="75px"/>
            </NavLink>
          </Box>
	    */}

          <Box className="navbar-hamburger">

            <MenuIcon onClick={toggleDrawer(true)} />

            <SwipeableDrawer
              anchor='top'
              open={state['top']}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              {list('top')}
            </SwipeableDrawer>


            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', ld: 'none' },
              }}
            >
            </Menu>
          </Box>

          <Box className="navbar-logo-2">
            <NavLink to="/"  >
              <img className="logo" src={logo} alt="Logo" />
            </NavLink>
          </Box>

          <Typography className="navbar-logo-2"
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Meteo App
          </Typography>


          <Box className="navbar-menu" sx={{ flexGrow: 1 }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Language />
          </Box>


          <Box sx={{ flexGrow: 0 }}>

            {isAuthenticated() ?
              <Tooltip title="Logout">
                <LockIcon onClick={() => signOut()} style={{ color: 'white' }} />
              </Tooltip>
              :
              <Tooltip title="Login">
                <IconButton onClick={handleOpenLogin} sx={{ padding: "5px" }}>
                  <LockOpenIcon style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            }

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>

        <div className="secondRow">
          <Toolbar>
            {/* 2. Zeile */}
            <Box className="navbar-menu">
              
              <NavLink to="/graph" sx={{ textDecoration: 'none' }}>
                <Button
                  key={"i1"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t("diagramme&daten")}
                </Button>
              </NavLink>

              <FadeMenu
                title={t("berichte")}
                items={menu.reports}
              />

              <FadeMenu
                title={t("LangjährigeVerläufe")}
                items={menu.verlauf}
              />

                <FadeMenu
                  title={t("phenology_pom")}
                  items={menu.phenology_pom}
                />

              {isAuthenticated() &&
              <>
                <FadeMenu
                  title={t("phenology_wine")}
                  items={menu.phenology_wine}
                />
              </>
              }

              <NavLink to="/info" sx={{ textDecoration: 'none' }}>
                <Button
                  key={"i1"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t("InfoStationen")}
                </Button>
              </NavLink>


              <NavLink to="/general" sx={{ textDecoration: 'none' }}>

                <Button
                  key={"i1"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t("Stationsvergleich")}
                </Button>
              </NavLink>


              {isAuthenticated() && (auth().role.includes("admin")) &&
                <FadeMenu
                  title={t("Admin")}
                  items={menu.admin}
                />
              }

              {isAuthenticated() &&
                <FadeMenu
                  title={t("Modelle")}
                  items={menu.modells}
                />
              }
            </Box>
          </Toolbar>
        </div>
      </Container>

      <LoginDialog open={login} setOpen={setLogin} />

    </AppBar>
  );
}
export default NavBar;
