import * as d3 from "d3";
import { useContext } from "react";
import { useTranslation } from 'react-i18next';

import { GraphContext } from "../routes/GraphContext";
import Button from '@mui/material/Button';

export default function PDFButton ({callback}) {

    const { t, i18n } = useTranslation();
    const [parameter, setParameter] = useContext(GraphContext);


    // onClick={exportDiagramm}

return (

    <>
       <Button 
        style={{
            backgroundColor: "#776400",
            }}
            className="m-1"
            variant="contained"
            onClick={callback}>
               {t("export_pdf")}
            </Button>
    </>

)

}