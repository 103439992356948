import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

export default function ExcelButton ({callback}) {

   const { t } = useTranslation();

return (

    <>
       <Button 
        style={{
            backgroundColor: "#006400",
            }}
            className="m-1"
            variant="contained"
            onClick={callback}>
               {t("export_excel")}
            </Button>
    </>

)

}