import { useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/it';


import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

// import GraphicButton from '../../components/GraphicButton';
//  <GraphicButton func={exportDiagramm}/>
export default function DatePicker (props) {

    const { t, i18n } = useTranslation();

    return (

        <>
            <Box
                sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                className="p-1">

                <Typography className="stackrow" component={'h5'} sx={{ verticalAlign: "middle" }}>
                    {t(props.title)}
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                    <DesktopDatePicker className="datepicker"
                        views={props.view !== undefined ? props.view : ["year", "month", "day"]}
                        sx={{
                            '& .MuiInputBase-input': {
                                width: '200px',
                                padding: '7px 20px 7px',
                                fontSize: "14px"
                            },
                            '& .MuiFormControl-root': {
                                padding: '0px',
                            }
                        }}
                        format={props.format !== undefined ? props.format : "DD MMM YYYY"}
                        value={props.value}
                        onChange={props.callback}
                        minDate = {props.mindate === undefined ? undefined : dayjs().year(props.mindate)}
                        maxDate = {dayjs()} 
                    />
                </LocalizationProvider>
            </Box>
        </>

    )

}