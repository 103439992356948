// Graph
import * as d3 from "d3";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import StationButton from "../../components/StationButton";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { GraphContext } from "../GraphContext";

import DownloadDialog from './components/DownloadDialog';
import GraphicButton from '../../components/GraphicButton';
import ExcelButton from '../../components/ExcelButton';

import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';

import Tempchart from "./charts/Tempchart";
import Luftchart from "./charts/Luftchart";
import Rainchart from "./charts/Rainchart";
import Sunchart from "./charts/Sunchart";
import Windchart from "./charts/Windchart";

import TimeButton from "../../components/TimeButton";
import LevelButton from "../../components/LevelButton";

import StackRow from "../../components/StackRow";

import { getCurrentYear, now, addDays, addMonths, addYears } from '../../js/datetime';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar'

import Info from "../../components/Info";
import Title from "../../components/Title";

require('dayjs/locale/de');

const useStyles = makeStyles(() => ({
	inputBaseRoot: {
		width: '300px',
		padding: '2px 5px'
	},

}));

const Item = styled(Paper)(({ theme }) => ({

	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));


// Main part with time buttons ans graph
const GraphContent = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();
    
	const { t, i18n } = useTranslation();

	const [parameter, setParameter] = useContext(GraphContext);

	/* Jahr, Monat, ... */
	const [button, setButton] = useState(parameter.time);

	/* Tageswert, Stundenwert, Rohwert */
	/*const [level, setLevel] = useState(2);*/

	/* von, bis*/
	const [from, setFrom] = useState(addDays(-2));
	const [to, setTo] = useState(now())

	/* Snakbar */;
	const [ snakbar, setSnakbar ] = useState (false);

    const [open, setOpen] = useState(false);

        // Track page view                                                                                                                           
    useEffect(() => {
      trackPageView({
        documentTitle: 'Diagramme Stationen', // optional                                                                                             
        href: 'https://meteo.laimburg.it'+location.pathname, // optional                                                                         
        customDimensions: [
          {
            station: parameter.station,
          },
        ], // optional                                                                                                                           
      })
    }, [trackPageView, location])
    

	const downloadFile = (() => {
		handleClickToOpen();
	})

	const handleClickToOpen = () => {
		setOpen(true);
	};

	const closeDialog = () => {
		setOpen(false);
	};

	const action = (
	    <React.Fragment>
	    </React.Fragment>
	)

	return (

		<>
			{/* Diagramme */}
			<div className="stagearea grid p-2">

				<Title text={t("diagramme&daten")} />

				<DownloadDialog open={open} setOpen={setOpen} closeDialog={closeDialog} />

				{/* Pass down functions*/}
				<Grid>
				<Grid item md={6}>
				<Stack spacing={0}>

					<StackRow text={t("station")} component={<StationButton />} />

					<StackRow text={t("period")} component={<TimeButton />} />

					<StackRow text={t("resolution")} component={<LevelButton />} />

					<StackRow text={t("data_")} component={<Button key="1"
						sx={{
							fontSize: "12px",
							padding: "4px",
							whiteSpace: 'nowrap'
						}}
						variant="contained" color="success" onClick={downloadFile}>
						{t("download_data")}
					       </Button>} />
		
					{/* <Info /> */}

				</Stack>
				</Grid>
				</Grid>


			    <Tempchart />
			    {/*<Box sx={{ flexGrow: 1, padding: '10px' }}>
					<Grid container spacing={2}>

						<Grid item container xs={12} sx={{ justifyContent: 'space-between' }}>
							<Typography variant="h6">
								{t("temperatures")}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Tempchart />
							<GraphicButton object={"tempchart"} filename={t("temperature")}/>

						</Grid>

					</Grid>
					</Box>*/}

				<Luftchart />
				{/* <Box sx={{ flexGrow: 1, padding: '10px' }}>
					<Grid container spacing={0}>

						<Grid item container xs={12} sx={{ justifyContent: 'space-between' }}>
							<Typography variant="h6">
								{t("Luftdruck&Feuchte")}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Luftchart />
							<GraphicButton object={"airchart"} filename={t("humidity")}/>
						</Grid>

					</Grid>
				</Box>*/}

			    <Rainchart />
			    {/*<Box sx={{ flexGrow: 1, padding: '10px' }}>
					<Grid container spacing={0}>

						<Grid item container xs={12} sx={{ justifyContent: 'space-between' }}>
							<Typography variant="h6">
								{t("rain")}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Rainchart />
							<GraphicButton object={"rainchart"}  filename={t("rain")}/>
						</Grid>

					</Grid>
					</Box>*/}

			    <Sunchart />
			    {/*
				<Box sx={{ flexGrow: 1, padding: '10px' }}>
					<Grid container spacing={0}>

						<Grid item container xs={12} sx={{ justifyContent: 'space-between' }}>
							<Typography variant="h6">
								{t("sun")}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Sunchart />
							<GraphicButton object={"sunchart"} filename={t("sun")}/>
						</Grid>

					</Grid>
				</Box>
			     */}

			    <Windchart />
			    {/*	<Box sx={{ flexGrow: 1, padding: '10px' }}>
					<Grid container spacing={0}>

						<Grid item container xs={12} sx={{ justifyContent: 'space-between' }}>
							<Typography variant="h6">
								{t("Wind")}
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<Windchart />
							<GraphicButton object={"windchart"} filename={t("wind")}/>
						</Grid>

					</Grid>
					</Box>*/}

			</div>

			<Snackbar
			    open={snakbar}
			    autoHideDuration={6000}
			    message="Downloading ..."
			    action={action}
			/>

		</>
	);
}

export default GraphContent; 
