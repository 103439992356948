import { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { GraphContext } from "../GraphContext";
import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import StationButton from "../../components/StationButton";

import SensorComboBox from '../../components/SensorComboBox';
import StackRow from "../../components/StackRow";
import { useIsAuthenticated } from 'react-auth-kit';

import DatePicker from '../../components/DatePicker';

import { Errors } from "./Errors";
import { Correction } from "./Correction";

export const Corr = ({ active }) => {

  const isAuthenticated = useIsAuthenticated()

  const [ fromdjs, setFromdjs ] = useState(dayjs());

  const handleSelector = (event) => {

      setFromdjs(event);
      //console.log (event);
	}

  const [parameter, setParameter] = useContext(GraphContext);

  const { t, i18n } = useTranslation();

  const [sensors, setSensors] = useState([]);
  const [sensor, setSensor] = useState(1);

  // const theme = useTheme();
  //const { count, page, rowsPerPage, onPageChange } = props;


  const Item = styled(Paper)(({ theme }) => ({

    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  
  const handleSensor = (event) => {

    setSensor(event.target.value);
  
    /*setSensor(sensors[event.target.value-1]);*/

    console.log (event.target.value);
    console.log (sensors[event.target.value-1]);
  }

  useEffect(() => {

    let station = parameter.station;

    var url = preferences.address + '/rest/sensors.php?station=' + station;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setSensors(data);
      });
  }, [parameter])


  const cb = (date) => {
    console.log (date);
    let day = dayjs (date);
    setFromdjs(day);

  };

  return (
    <>
      {isAuthenticated() &&
      <div className="stagearea grid p-2">

        <Stack spacing={0} >

          <StackRow text="Station" component={<StationButton />} />

          <Item>
            <SensorComboBox
              title={"sensor"}
              value={sensor} items={sensors}
              callback={handleSensor} />
          </Item>


          <Item>
              <DatePicker
                  title="year"
                  value={fromdjs} 
                  callback={handleSelector}
                  view={["month", "year", "day"]}
                  format="DD MMM YYYY"
              />
          </Item>

        </Stack>

        <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'inherit',
                justifyContent: 'flex-start',
                alignItems: 'stretch'
                }}>

        <Box sx={{display: 'inline-flex', flexGrow: 1, p: 1}}>
            <Errors station={parameter.station} sensor={sensors[sensor-1]} callback={cb} />
        </Box>

        <Box sx={{display: 'inline-flex', flexGrow: 1, p: 1}}>
            <Correction station={parameter.station} sensor={sensors[sensor-1]} date={fromdjs} />
        </Box>

        </Box>


      </div>
    }
    </>
  );
}
