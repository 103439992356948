import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../js/datetime';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { y_axis, x_axis, y_array, xy_matrix, label } from "../../components/Calendar/calendar";
import { xlabel, logo } from '../../components/d3/charts/chart';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const TempMapDev = ({ description, list }) => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();

    const [parameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setYear(1900 + date.getYear());
    }

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Temperaturabweichungen vom langjährigen Mittel', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }, [resize, data, i18n.language]);

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/temperature/deviation/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [parameter, year]);


    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/temperature/deviation/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })

            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    function draw(svg) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 40,
            right: 50,
            bottom: 30,
            left: 50,
        }; // margin of chart

        let width = parseInt(svg.style("width")) > 1000 ? 1000 : parseInt(svg.style("width"));

        let max = d3.max(data.years.map((d) => { return d.year }));
        let min = d3.min(data.years.map((d) => { return d.year }));

        const cellsize = 20;
        const cellborder = 2;
        const gap = 0;
        const x_offset = width * 0.04 + margin.left;
        const y_offset = 5;

        const sec_left = (width - 70) * 0.2;
        const cellwidth = sec_left - margin.left;

        const sec_right = (width - 70) * 0.8;

        let height = (max - min) * cellsize + margin.top + margin.bottom;
        if (height < 100) height = 200;

        svg
            .attr("width", width)
            .attr("height", height);

        const colors = ['grey', 'royalblue', 'white', 'pink', 'red'];
        const colorFn = d3.scaleLinear()
            .domain([-10, -2, 0, 2, 10])
            .range(colors)
            .interpolate(d3.interpolateCubehelix);

        y_axis(svg, data, y_offset, cellsize, margin.left, margin.top);

        let f1 = (d) => { return Math.round((d.val - data.basis.val) * 10) / 10 + "°C" };
        let c1 = (d) => colorFn(d.val - data.basis.val);
        y_array(svg, data, cellwidth, cellsize,
            cellborder, 0, margin.top,
            55, y_offset, colorFn,
            c1, f1);

        // Create scale
        let xScale = x_axis(svg, sec_left, width - (margin.right + 70), 20, margin.top, t);

        // months
        let f2 = (d) => { return Math.round((d.val - (data.basisb[d.month - 1] !== undefined ? data.basisb[d.month - 1].val : 0)) * 10) / 10 + "°C" };

        /*let b = 0;
        try {
            b = data.basisb[d.month-1].val;
        } catch (error){
        }*/
        //let c2 = (d, i) => colorFn(d.val - data.basisb[d.month-1].val );
        let c2 = (d, i) => colorFn(d.val - (data.basisb[d.month - 1] !== undefined ? data.basisb[d.month - 1].val : 0));

        xy_matrix(svg, data, xScale, cellsize, cellborder,
            gap, max, 20, margin.top, x_offset, colorFn, c2, f2);

        label(svg, colors, -2, 2, "°C", width - 50, 30);

        xlabel(svg, t("months"), width * 0.55, 15);
        xlabel(svg, t("years"), width * 0.1, 15);
        logo(svg);
    }

    return (
        <>
            <div>
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Die Grafik der Temperaturabweichungen zeigt die monatlichen und jährlichen Temperaturabweichungen seit Beginn der Wetteraufzeichnungen an der ausgewählten Wetterstation. Diese Abweichungen werden im Vergleich zum langjährigen Mittelwert berechnet, der sich aus den Temperaturdaten der jeweils vorangehenden 30 Jahre zusammensetzt. Durch einen Mausklick auf das entsprechende Feld können die genauen Abweichungswerte abgerufen werden. Darüber hinaus sind die verschiedenen Abweichungskategorien anhand einer Farbskala dargestellt. Starke Temperaturabweichungen nach unten sind dunkelblau, starke Abweichungen nach oben dunkelrot eingefärbt."}
                        it={"Il grafico delle deviazioni di temperatura mostra le deviazioni di temperatura mensili e annuali dall'inizio delle registrazioni meteorologiche nella stazione meteorologica selezionata. Queste deviazioni sono calcolate rispetto alla media aritmetica a lungo termine, che è costituita dai dati di temperatura dei 30 anni precedenti.  I valori esatti delle deviazioni possono essere richiamati cliccando sul campo corrispondente. Inoltre, le varie categorie di deviazione sono indicate con una scala di colori. Le forti deviazioni di temperatura verso il basso sono colorate in blu scuro, quelle verso l'alto in rosso scuro."}
                        en={"The graph of temperature deviations shows the monthly and annual temperature deviations since the start of weather recordings at the selected weather station. These deviations are calculated in comparison to the long-term average, which refers to the temperature data from the previous 30 years. By clicking on the corresponding field, the exact deviation values are displayed on the screen. In addition, the various deviation categories are shown using a color scale. Strong downward temperature deviations are colored dark blue, strong upward deviations dark red."}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("temperature_divs")} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>

            </Grid>

        </>
    );

}
