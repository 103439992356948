// Footer.js
import { useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Button, FormControl, Col, Row, Form } from "react-bootstrap";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import "../styles.css";
import logo_d3 from '../l_d3.png';
import logo_laimburg from '../images/Laimburg.png';
import { useTranslation } from 'react-i18next';

export default function Footer() {

	const { t, i18n } = useTranslation();

	return (

		<div className="footer">

			<Grid container flexDirection="row" alignItems="center">

				<Grid item>
					<img className="logo-laimburg p10" src={logo_laimburg} />
				</Grid>

				<Grid item flexGrow="1">

					<Grid container className="p10 padding footer-text" flexDirection="column" flexGrow="1">
						<Grid item className="p5">{t("vzl")}</Grid>
						<Grid item className="p5">{t("vzl_post")}</Grid>
						<Grid item className="p5">{t("vzl_tel")}</Grid>
					</Grid>

				</Grid>

				<Grid item className="p10">
					<img className="m-1" src={logo_d3} />
				</Grid>

			</Grid>

		</div>


	);
} 
