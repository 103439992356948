import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../js/datetime';

import { GraphContext } from "../GraphContext";
import "../../styles.css";
import { saveAs } from 'file-saver';

import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import DatePicker from '../../components/DatePicker';
import ComboBox from '../../components/ComboBox';

import { borders, addLine } from '../../components/d3/legend/legend';
import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';
import { timeformat, getWidth, responsivness } from '../../components/d3/d3timeformat';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';


export const TempMinmax = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [data, setData] = useState();
    const [cat, setCat] = useState(1);

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    const handleSelectorCat = (event) => {
        setCat(event.target.value);
    }

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Temperaturmittelwerte min und max', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

        //= [t("temperature_mids"), t("temperature_maps"), t("above_middle"), t("underneath_middle")]
        let labels = [];
        if (cat === 1)
            labels = [t("max_temperature_mids"), t("temperature_mids"), t("annual_periode"), t("min_temperature_mids")]
        if (cat === 2)
            labels = [t("max_temperature_day"), t("temperature_mids"), t("annual_periode"), t("min_temperature_day")]

        let r = responsivness();
        if (r.dialog) {
            borders(svg, labels, 350, 70, 10);
            addLine(svg, 0, 1.5, "red", labels[0]);
            addLine(svg, 1, 1.5, "#8c564b", labels[1]);
            addLine(svg, 2, 2.5, "green", labels[2]);
            addLine(svg, 3, 1.5, "steelblue", labels[3]);
        }

    }, [resize, data, i18n.language, cat]);

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/temperature/margins/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [parameter, year]);

    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/temperature/margins/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }


    var width = 0;

    const margin = {
        top: 10,
        right: 10,
        bottom: 40,
        left: 70,
    }; // margin of chart

    var xScale;
    var yScale;
    let height = 500;
    let brush;
    let clip;

    //var yGrid;
    //var yAxis

    var xGrid;
    var xAxis;

    let min;
    let max;

    function draw(svg) {

        /*var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);*/

        timeformat(t);

        // Clear
        svg.selectAll("*").remove();

        // setup SVG
        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        let start = d3.timeParse("%Y-%m-%d")(year + "-01-01");
        let end = d3.timeParse("%Y-%m-%d")(year + "-12-31");

        let minM = d3.min(data.months.map((d) => { return d.min }));
        let maxM = d3.max(data.months.map((d) => { return d.max }));

        let minD = d3.min(data.days.map((d) => { return d.min }));
        let maxD = d3.max(data.days.map((d) => { return d.max }));

        min = d3.min([minM, minD]);
        max = d3.max([maxM, maxD]);

        // Getting width
        width = parseInt(svg.style('width'));

        svg
            .attr("width", width)
            .attr("height", height);

        // Create scale
        xScale = d3.scaleTime()
            .domain([start, end])
            .range([margin.left, width - margin.right]);

        // Add scales to axis
        /*xAxis = d3.axisBottom()
            .scale(xScale);*/

        //Append group and insert axis
        xAxis = svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .attr("class", "axes")
            .call(d3.axisBottom(xScale));

        // Grid vertical
        let bottomScale = d3.axisBottom(xScale);
        xGrid = svg.append("g")
            .attr('id', 'xgrid')
            .attr('transform', 'translate(0 ,' + (height - margin.bottom) + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(bottomScale.tickSize(-height + margin.bottom + margin.top, 0).tickFormat(""));

        // Create scale
        yScale = d3.scaleLinear()
            .domain([min, max])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        d3.axisLeft()
            .scale(yScale);

        // brush
        brush = d3.brushX()
            .extent([[margin.left, margin.top], [width - margin.right, height - margin.bottom]])
            .on("end", updateChart); // brushended
        svg.append("g")
            .attr('class', 'brush')
            .call(brush);

        // Clip
        clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        svg.append("defs")
            .append("svg:clipPath")
            .attr('id', 'clip')
            .append("svg:rect")
            .attr("x", margin.left)
            .attr("y", 0)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height);


        //Append group and insert axis
        let yAxis = d3.axisLeft(yScale)
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 00)")
            .call(yAxis.ticks(10, '%0.1f').tickFormat((x) => { return x + " °C" }));

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' , 0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(yAxis.tickSize(-width + margin.right + margin.left, 0).tickFormat(""));


        let lines = clip.append('g');


        // Add zero line
        lines.append("line")
            .attr('id', 'zeroLine')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.0)
            .attr("x1", margin.left)
            .attr("y1", yScale(0))
            .attr("x2", width - margin.right)
            .attr("y2", yScale(0));

        // Lanjähriges Mittel
        lines.append("path")
            .attr('id', 'path2')
            .datum(data.months)
            .attr("fill", "none")
            .attr("stroke", "#8c564b")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y(function (d) { return yScale(d.val) })
                .defined((d) => { return d.val !== null })
            )

        // Gewähltes Jahr
        lines.append("path")
            .attr('id', 'path3')
            .datum(data.days)
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y(function (d) { return yScale(d.val) })
                .defined(((d) => { return d.val !== null }))
            )

        // line max
        if (cat === 1) {
            lines.append("path")
                .attr('id', 'path4')
                .datum(data.months)
                .attr("fill", "none")
                .attr("stroke", "red")
                .attr("stroke-width", 1.5)
                .attr("d", d3.line()
                    .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                    .y(function (d) { return yScale(d.max) })
                    .defined((d) => { return d.max !== null })
                )

            // line min
            lines.append("path")
                .attr('id', 'path5')
                .datum(data.months)
                .attr("fill", d3.color("steelblue").copy({ opacity: 0.3 }))
                .attr("stroke", "steelblue")
                .attr("stroke-width", 1.5)
                .attr("d", d3.area()
                    .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                    .y0(function (d) { return yScale(d.min) })
                    .y1(function (d) { return yScale(min) })
                    .defined((d) => d.min !== null)
                )
        }

        if (cat === 2) {
            lines.append("path")
                .attr('id', 'path6')
                .datum(data.days)
                .attr("fill", "none")
                .attr("stroke", "red")
                .attr("stroke-width", 2.5)
                .attr("d", d3.line()
                    .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                    .y(function (d) { return yScale(d.max) })
                    .defined(((d) => { return d.max !== null }))
                )
            lines.append("path")
                .attr('id', 'path7')
                .datum(data.days)
                .attr("fill", d3.color("steelblue").copy({ opacity: 0.3 }))
                .attr("stroke", "steelblue")
                .attr("stroke-width", 1.5)
                .attr("d", d3.area()
                    .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                    .y0(function (d) { return yScale(d.min) })
                    .y1(function (d) { return yScale(min) })
                    .defined((d) => d.min !== null)
                )
        }

        svg.append('line')
            .attr('id', 'cursorLine')
            .attr('stroke', 'grey')
            .attr('display', null);

        var dotgroup = svg.append("g").attr('id', 'dotgroup');

        /*  actual */
        dotgroup.append("circle")
            .attr('id', 'dot1')
            .style('display', 'none')
            .style("stroke", "red")
            .style("fill", 'red')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot2')
            .style('display', 'none')
            .style("stroke", "steelblue")
            .style("fill", 'steelblue')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot3')
            .style('display', 'none')
            .style("stroke", "#8c564b")
            .style("fill", '#8c564b')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot4')
            .style('display', 'none')
            .style("stroke", "green")
            .style("fill", 'green')
            .attr("r", 3);

        var tooltip = svg.append("g")
            .attr('id', 'tooltip')
            .style("pointer-events", "none");

        tooltip.append("path")
            .attr('id', 'pathtool')
            .data([,])
            .attr("class", "shadow")
            .attr("fill", "white")
            .attr("stroke", 'black')
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round");

        var tooltiptext = tooltip.append("text")
            .attr('id', 'tooltiptext')
            .style("pointer-events", "none");

        // Date
        tooltiptext
            .append("tspan")
            .attr('id', 'date')
            .attr("x", 15)
            .attr("y", 0)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');


        tooltiptext
            .append("tspan")
            .attr('id', 'value1')
            .attr("x", 25)
            .attr("y", 20)
            .attr("stroke", 'red')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value2')
            .attr("x", 25)
            .attr("y", 60)
            .attr("stroke", 'steelblue')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value3')
            .attr("x", 25)
            .attr("y", 40)
            .attr("stroke", '#8c564b')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value4')
            .attr("x", 25)
            .attr("y", 80)
            .attr("stroke", 'green')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        xlabel(svg, t("year"), width / 2, height - 5);
        ylabel(svg, t("temperature"), -height * 0.5, 15);

        logo(svg);
    }


    function pointermoved(event) {

        const X = d3.map(data.months, d => d3.timeParse("%Y-%m-%d")(d.date));
        const I = d3.map(data.months, (_, i) => i);
        const i = d3.bisectCenter(X, xScale.invert(d3.pointer(event)[0] - 5));

        // cursorline
        const svg = d3.select(svgRef.current);
        svg.selectAll('#cursorLine')
            .style('display', null)
            .attr('x1', d3.pointer(event)[0] - 5)
            .attr('x2', d3.pointer(event)[0] - 5)
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom);

        if (cat === 1) {
            svg
                .selectAll('#dot1')
                .style('display', null)
                .attr("cx", d3.pointer(event)[0] - 5)
                .attr("cy", + yScale(data.months[i].max));

            svg
                .selectAll('#dot2')
                .style('display', null)
                .attr("cx", d3.pointer(event)[0] - 5)
                .attr("cy", + yScale(data.months[i].min));
        }
        if (cat === 2) {
            svg
                .selectAll('#dot1')
                .style('display', null)
                .attr("cx", d3.pointer(event)[0] - 5)
                .attr("cy", + yScale(data.days[i] !== undefined ? data.days[i].max : 0));

            svg
                .selectAll('#dot2')
                .style('display', null)
                .attr("cx", d3.pointer(event)[0] - 5)
                .attr("cy", + yScale(data.days[i] !== undefined && data.days[i].min));
        }


        //if (cat === 1)
        //{
        svg
            .selectAll('#dot3')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(data.months[i].val));

        svg
            .selectAll('#dot4')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScale(data.days[i] !== undefined && data.days[i].val));
        //}

        // Textbox with values
        var tooltiptext = svg.select("#tooltiptext");
        tooltiptext.attr("transform", `translate(${0},${30 - 0})`);

        const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

        var path = svg.select("#pathtool");

        if (xScale(d3.timeParse("%Y-%m-%d")(data.months[i].date)) === width - margin.right) {
            svg.select("#tooltip")
                .style("display", 'none');
        }

        let rb = xScale(X[i]) > width - 100 ? width - 100 : xScale(X[i]);
        svg.select("#tooltip")
            .style("display", null)
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${rb},0)`);

        path
            .style('display', null)
            .transition()
            .duration(200)
            .attr('d', `M 0 20 l 10 -10 H${w + 20} V${h + 25} H10 V25 Z`);

        svg.select("#date")
            .transition()
            .duration(200)
            .text(data.months[i].date);

        if (cat === 1) {
            svg.select("#value1")
                .transition()
                .duration(200)
                .text(Math.round(data.months[I[i]].max * 10) / 10 + " °C");
            svg.select("#value2")
                .transition()
                .duration(200)
                .text(Math.round(data.months[I[i]].min * 10) / 10 + " °C");
        }
        if (cat === 2) {
            svg.select("#value1")
                .transition()
                .duration(200)
                .text(Math.round(data.days[I[i]] !== undefined ? data.days[I[i]].max * 10 : 0) / 10 + " °C");
            svg.select("#value2")
                .transition()
                .duration(200)
                .text(Math.round(data.days[I[i]] !== undefined ? data.days[I[i]].min * 10 : 0) / 10 + " °C");
        }

        svg.select("#value3")
            .transition()
            .duration(200)
            .text(Math.round(data.months[I[i]].val * 10) / 10 + " °C");
        svg.select("#value4")
            .transition()
            .duration(200)
            .text(Math.round(data.days[I[i]] !== undefined && data.days[I[i]].val * 10) / 10 + " °C");


        let x_pos = d3.pointer(event)[0];
        if (x_pos < 400) {
            svg.selectAll('#legend').attr('opacity', 0.1)
        }
        else {
            svg.selectAll('#legend').attr('opacity', 1)
        }

    }

    function pointerleft() {
        const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)
    }

    var idleTimeout
    function idled() { idleTimeout = null; }



    function updateChart(event) {

        const svg = d3.select(svgRef.current);
        var extentX = event.selection

        if (!extentX) {
            if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

            let min = d3.min(data.months.map((d) => { return d3.timeParse("%Y-%m-%d")(d.date) }));
            let max = d3.max(data.months.map((d) => { return d3.timeParse("%Y-%m-%d")(d.date) }));

            xScale.domain([min, max]);
        }
        else {

            //console.log(xScale.invert(extentX[0]), xScale.invert(extentX[1]));
            xScale.domain([xScale.invert(extentX[0]), xScale.invert(extentX[1])]);

            // This remove the grey brush area as soon as the selection has been done
            svg.select(".brush").call(brush.move, null)

        }

        // Bottom
        xAxis.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale));

        // Bottom grid
        xGrid.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale).tickSize(- height + margin.top + margin.bottom, 0).tickFormat(""));

        // Linie längjähriges Mittel
        svg.selectAll("#path1")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.max) }));

        // Linie ausgewähltes Jahr
        svg.selectAll("#path2")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.val) }));

        svg.selectAll("#path3")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.val) }));

        //
        svg.selectAll("#path4")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.max) }));

        svg.selectAll("#path5")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0((d) => { return yScale(d.min) })
                .y1((d) => { return yScale(min) }));

        //
        svg.selectAll("#path6")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y((d) => { return yScale(d.max) }));


        svg.selectAll("#path7")
            .transition()
            .duration(1000)
            .attr("d", d3.area()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d")(d.date)) })
                .y0((d) => { return yScale(d.min) })
                .y1((d) => { return yScale(min) }));

    }

    return (
        <>
            <Stack spacing={1}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

                <Item>
                    <ComboBox
                        title={t("values")}
                        value={cat} items={[t("mittelwerte"), t("absolutwerte")]}
                        callback={handleSelectorCat}
                    />
                </Item>

            </Stack>

            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Die MIN-MAX Grafik zeigt den Verlauf der Tagesmittelwerte einer ausgewählten Wetterstation und eines bestimmten Jahres. Der langjährige Durchschnitt bezieht sich auf den Zeitraum der dem ausgewählten Jahr vorangehenden 30 Jahre (braune Linie). Wenn die grüne Linie die rote Linie übersteigt, liegen die Tagesmittelwerte des ausgewählten Jahres über dem höchsten Tagesmittelwert (Maximum) des langjährigen Vergleichszeitraums. Unterschreitet die grüne Linie die blaue Linie, liegen die Tagesmittelwerte des ausgewählten Jahres unter dem tiefsten Tagesmittelwert (Minimum) des langjährigen Vergleichszeitraums. Im laufenden Jahr wird die Grafik täglich aktualisiert."}
                        it={"Il grafico MIN-MAX mostra l'andamento delle medie giornaliere di una stazione meteorologica selezionata e di un anno specifico. La media a lungo termine si riferisce al periodo dei 30 anni precedenti l'anno selezionato (linea marrone). Se la linea verde supera la linea rossa, i valori medi giornalieri dell'anno selezionato sono superiori al valore medio giornaliero più alto (massimo) del periodo di confronto a lungo termine. Se la linea verde scende al di sotto della linea blu, i valori medi giornalieri dell'anno selezionato sono inferiori al valore medio giornaliero più basso (minimo) del periodo di confronto a lungo termine. Il grafico viene aggiornato durante l'anno in corso."}
                        en={"The MIN-MAX graph shows the course of the daily averages with respect to the minimum and maximum values of a selected weather station and a specific year. The long-term average refers to the period of the 30 years preceding the selected year (brown line). If the green line exceeds the red line, the daily average values of the selected year are above the highest daily average value (maximum) of the long-term comparison period. If the green line falls below the blue line, the daily average values of the selected year are below the lowest daily average value (minimum) of the long-term comparison period. The graph is updated daily in the current year."}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("temperature_minmax")} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>

            </Grid>

        </>
    );

}
