import * as d3 from "d3"
import { useState, useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";

import ComboBox from '../../components/ComboBox';
import DatePicker from '../../components/DatePicker';
import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';


export const PhenologyWine = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [anlage, setAnlage] = useState(1);
    const [anlagen, setAnlagen] = useState([]);

    const [combo, setCombo] = useState(1);
    const [calender, setCalender] = useState(dayjs());

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    useEffect (() => {
	d3.json (preferences.address + "/query/anlagen.php")
	    .then((data) => {
		setAnlagen (data);
	    })
    }, [])

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Admin Stadien Weinbau', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])

    const saveCallback = (() => {

        //console.log(calender.year(), (calender.month() + 1), calender.date(), combo, variety);

        var url = preferences.address + '/query/admin/post_wine.php';

        fetch(url, {
            method: "POST",
            mode: 'cors',
            cache: "no-cache",
            headers: {},
            body: JSON.stringify({
                station: parameter.station,
                anlage: anlage,
                stadium: combo,
                year: calender.year(),
                month: (calender.month() + 1),
                day: calender.date()
            }),
        })
        .then (response => {
                if (response.status !== 200)
                    alert("Fehler")}
            );

    })


    let c = ["austrieb", "vollblüte", "reifebeginn"];

    const handleSelector1 = (event) => {
        setCombo(event.target.value);
    };

    const handleSelector = (event) => {
        setAnlage(event.target.value);
    };


    const handleDate = (event) => {
        setCalender(event);
    };


    return (
        <>
            <div className="stagearea grid p-2">


                <StackRow text={t("station")} component={<StationButton />} />

                <Stack spacing={0}>

                    <Item>
                        <ComboBox
                            title={"variety"}
                            value={anlage} items={anlagen}
                            callback={handleSelector} />
                    </Item>

                    <Item>
                        <ComboBox
                            title={"stadium"}
                            value={combo} items={c}
                            callback={handleSelector1} />
                    </Item>

                    <Item>
                        <DatePicker
                            title="datum"
                            value={calender}
                            callback={handleDate}
                            view={["year", "month", "day"]}
                            format="DD MMM YYYY"
                        />
                    </Item>

                </Stack>


                <Grid container flexDirection={"row-reverse"}>

                    <Grid item className="p-2">
                        <Button sx={{ margin: '10px' }} variant="contained" onClick={saveCallback} color="primary">Save</Button>
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
