import * as d3 from "d3";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext.js";
import "../../styles.css";
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import { preferences } from "../../preferences.js";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { dimensions, rest, label, xlabel, ylabel, logo } from '../../components/d3/charts/chart.js';
import { timeformat, getWidth, responsivness, getMonths } from '../../components/d3/d3timeformat';
import { legend } from '../../components/d3/legend/legend.js';
import { } from '../graph/brushX.js';

import ExcelButton from '../../components/ExcelButton.js';
import GraphicButton from '../../components/GraphicButton.js';
import InfoButton from '../../components/InfoButton.js';

import DatePicker from '../../components/DatePicker.js';
import ComboBox from '../../components/ComboBox.js';
import ColorButton from "../../components/ColorButton.js";

export const GeneralDiagram = () => {

    const { t, i18n } = useTranslation();
    const { trackEvent } = useMatomo()

    function now() {
        let date = new Date();
        return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
    function addDays(d) {
        let date = new Date();
        date.setDate(date.getDate() + d);
        return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
    function addMonth(m) {
        let date = new Date();
        date.setMonth(date.getMonth() + m);
        return (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }

    const [parameter, setParameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs(addDays(-60)));
    const [tojs, setTojs] = useState(dayjs());

    const sensorArray = ["airtemp2m", "soiltemp1", "humidity", "windspeed", "globalrad", "rain"];
    const [sensor, setSensor] = useState(1);
    const [stations, setStations] = useState();
    const [from, setFrom] = useState(addDays(-60));
    const [to, setTo] = useState(now());

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());

    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        let query = preferences.address + "/query/stations.php";

        d3.json(query)
            .then((data) => {
                let z = data.map(sensor => { sensor.active = true; return sensor })
                setStations(z);
            });
    }, [i18n.language, parameter]);

    const handleSensor = (event) => {
        setSensor(event.target.value);
    };


    const handleSelectorFrom = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let dateString = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setFrom(dateString);
    }

    const handleSelectorTo = (event) => {

        setTojs(event);

        let date = event.$d;
        let dateString = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setTo(dateString);
    }

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined) {
            if (data !== undefined) {
                draw(data);
                label(svg, "", t("_date"), "");
            }
        }

    }, [resize, data]);


    useEffect(() => {
        let query = preferences.address + "/query/general/get.php?sensor=" + sensorArray[sensor - 1] + "&from=" + from + "&to=" + to + "&level=1";

        var dat = d3.json(query)
            .then((data) => {
                data.timeline.map((d, i) => {
                    d = d3.timeParse("%Y-%m-%d %H:%M:%S")(d);
                });
                data.minTime = d3.timeParse("%Y-%m-%d %H:%M:%S")(data.minTime);
                data.maxTime = d3.timeParse("%Y-%m-%d %H:%M:%S")(data.maxTime);
                setData(data);
                if (data !== undefined)
                    draw(data);
            });
    }, [sensor, from, to, i18n.language]);

    const downloadExcel = () => {

        trackEvent({ category: 'Stationvergleich: ' + sensorArray[sensor - 1], action: 'Download PDF file' })

        var url = preferences.address + '/query/general/excel.php';

        let block = {};
        block.station = parameter.station;
        block.sensor = sensorArray[sensor - 1];
        block.from = from;
        block.to = to;
        block.level = 3;
        block.lang = i18n.language;
        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try { filename = response.headers.get('content-disposition').split('filename=')[1]; }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
            });
    }

    function draw(data) {

        // Timestamp responsivness
        timeformat(t);

        let svg = d3.select(svgRef.current);
        let margins = { width: svg.attr('width'), height: 500, top: 20, bottom: 40, right: 20, left: 60 }

        dimensions(svg, data, stations, 20, 50, 85, 20);
        let labels = [t("laimburg"), t("eyrs"), t("fragsburg"), t("dietenheim")];

        if (stations !== undefined)
            rest(svg, stations);

        // Y Label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -svg.attr('height') / 2)
            .attr("y", 20)
            .text(t(sensorArray[sensor - 1]));

        logo(svg);

    }

    const handleClick = (index) => () => {
        let h = [...stations];
        let s = h[index].active = !h[index].active;
        setStations(h);
        draw(data);
    };


    return (
        <>
            <Stack spacing={0}>

                <Item>
                    <ComboBox
                        title={"sensor"}
                        value={sensor} items={sensorArray}
                        callback={handleSensor} />
                </Item>

                <Item>
                    <DatePicker
                        sx={{ width: '300px' }}
                        title={"start"}
                        value={fromdjs}
                        callback={handleSelectorFrom} />
                </Item>

                <Item>
                    <DatePicker
                        title={"end"}
                        value={tojs}
                        callback={handleSelectorTo} />
                </Item>

            </Stack>

            <div className="flex-row">
                {(stations !== undefined && data !== undefined) &&
                    stations.map((station, index) => {
                        let name = station.name + (data.sensors[index].level !== null ? " [" + data.sensors[index].level + "]" : "");
                        return (
                            <ColorButton key={index} svgref="soil_chart" name={name} color={d3.schemeSet1[index]} active={station.active} callback={handleClick(index)} />
                        )
                    })
                }
            </div>

            <div id="svgchart">
                <svg ref={svgRef} id="soil_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={""}
                        it={""}
                        en={""}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object={"soil_chart"} filename={t("stationen") + "-" + t(sensorArray[sensor - 1])} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>
            </Grid>

        </>
    );
}
