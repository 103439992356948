import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
//import { saveAs } from 'file-saver';

import Grid from '@mui/material/Grid';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import DatePicker from '../../components/DatePicker';
//import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";
import ComboBox from '../../components/ComboBox';

import { logo } from "../../components/d3/charts/chart";
import { legend, addLine } from "../../components/d3/legend/legend";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import { getCurrentYear, } from '../../js/datetime';
import Title from "../../components/Title";


export const PhenologyStates = () => {

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [year, setYear] = useState(getCurrentYear());

    const [data, setData] = useState();
    const [ variety, setVariety] = useState(1);
    const [fromdjs, setFromdjs] = useState(dayjs());

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            if (data.keys.length > 0)
                draw(svg);

    }, [resize, data, variety, i18n.language]);

    useEffect(() => {

        d3.json(preferences.address + "/query/phenology/states/get.php?station=" + parameter.station + "&year="+year+"&variety="+variety)
            .then((data) => {
                setData(data);
            });
    }, [parameter, year, variety]);

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Phänologie Ganttchart', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location])

    /*const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/dry/excel.php';

        let block = {};
        block.station = parameter.station;
        //block.year = year;
        //block.period = period;
        //block.level = threshold;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }*/

    function draw(svg) {

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 60,
            right: 50,
            bottom: 60,
            left: 150,
        }; // margin of chart

        let FROM = year+"-02-15";
        let TO = year+"-05-31";

        let width = parseInt(svg.style("width"));

        //console.log (width);
        let parseTime = d3.timeParse("%Y-%m-%d");

        // Create scale
        let step = 40; // Pro zeile, Jahr

        // Anzahl Jahre
        let years = data.keys.length;
        if (years === 0) years = 1;

        let height = years * step + margin.top + margin.bottom;
        if (height < 200) height = 200;
        svg.attr("height", height);

        var yScale = d3.scaleBand()
            .domain(data.keys)
            .range([0, years * step]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale);

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr("class", "axes")
            .call(y_axis.tickFormat((x) => { return t(x) }));

        // X Label
        svg.append("text")
            .attr("class", "x label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", 15)
            .text(t("month"));

        // Y Label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", 20)
            .text(t("Stadium"));

        // TOP
        function dayOfTheYear(date) {
          return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24) - 1;
        }

        let d1 = new Date(FROM);
        let d2 = new Date(TO);

        var xScaleLinearLeft = d3.scaleLinear()
            .domain([dayOfTheYear(d1), dayOfTheYear(d2)])
            .range([margin.left, width - margin.right]);
        y_axis = d3.axisTop()
            .scale(xScaleLinearLeft);
        svg.append("g") // X Axis
            .attr("transform", `translate(0, ${margin.top})`)
            .call(y_axis.ticks(20));

        // BOTTOM
        let xScaleTime = d3.scaleTime()
            .domain([parseTime(FROM), parseTime(TO)])
            .range([margin.left, width - margin.right]);
        let xAxis = d3.axisBottom()
            .scale(xScaleTime);
        svg.append("g") // X Axis
            .attr("transform", `translate(0, ${height-margin.bottom})`)
            .attr("class", "axes")
            .call(xAxis);
        //let xGrid =
        svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(80));
        //let xGridWeeks = 
        svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(10));

        const box = svg.append("g").attr('id', 'block');

        /* Horizontale Linie */
        box
            .selectAll("vertLines")
            .data(data.keys)
            .enter()
            .append("line")
            .attr("transform", `translate(0, ${margin.top})`)
            .attr("x1", function (d, i) { return xScaleLinearLeft(d3.min(data.list[i])) })
            .attr("x2", function (d, i) { return xScaleLinearLeft(d3.max(data.list[i])) })
            .attr("y1", function (d, i) { return yScale(d)+20 })
            .attr("y2", function (d, i) { return yScale(d)+20 })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40);

        box
            .selectAll("boxes")
            .data(data.keys)
            .enter()
            .append("rect")
            .attr("transform", `translate(0, ${margin.top})`)
            .attr("x", function (d, i) { return xScaleLinearLeft(d3.quantile(data.list[i], 0.25)) })
            .attr("y", function (d, i) { return yScale(d) + 12})
            .attr("width", function (d, i) { return (xScaleLinearLeft(d3.quantile(data.list[i], 0.75)) - xScaleLinearLeft(d3.quantile(data.list[i], 0.25))) })
            .attr("height", 16)
            .attr("stroke", "black")
            .style("fill", (d, i) => { return d3.schemeTableau10[i+1]})
            .attr("rx", 2);

        box
            .selectAll("vertLines")
            .data(data.keys)
            .enter()
            .append("line")
            .attr("transform", `translate(0, ${margin.top+12})`)
            .attr("x1", function (d, i) { return xScaleLinearLeft(d3.quantile(data.list[i], 0.50)) })
            .attr("x2", function (d, i) { return xScaleLinearLeft(d3.quantile(data.list[i], 0.50)) })
            .attr("y1", function (d, i) { return yScale(d) })
            .attr("y2", function (d, i) { return yScale(d)+16 })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40);

        const l =[];
        data.list.map ((d, i) => {d.map((e, j) => {let o ={}; o.y = i; o.val = e; l.push (o);return 0;}); return 0; });
        var jitterWidth = 5;
        svg
            .selectAll("points")
            .data(l)
            .enter()
            .append("circle")
            .attr("transform", `translate(0, ${margin.top})`)
            .attr("cx", function (d, index) { return xScaleLinearLeft(d.val); })
            .attr("cy", function (d, index) { return yScale(data.keys[d.y]) + yScale.bandwidth() / 2 - jitterWidth / 2 + Math.random() * jitterWidth })
            .attr("r", 2)
            .style("fill", "white")
	    .attr("stroke", "blue");

        // Add the line current year
	if (data.current !== null){
        box.append("path")
            .datum(data.keys)
            .attr("transform", `translate(0, ${margin.top + yScale.bandwidth()/2})`)
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleLinearLeft(data.current[i]) })
                .y( (d) => { return yScale(d) })
                .defined(((d, i) => { return data.current[i] !== null;} ))
            )

        // Add the line
        box.selectAll("circles")
            .data(data.keys)
            .enter()
            .append("circle")
            .attr("transform", `translate(0, ${margin.top + yScale.bandwidth()/2})`)
            .attr("fill", "white")
            .attr("stroke", "orange")
            .attr('display', (d, i) => { return data.current[i] === null ? 'none' : '';} )
            .attr("cx", function (d, i) { return xScaleLinearLeft(data.current[i]) })
            .attr("cy", function (d) { return yScale(d) })
            .attr("r", 4)
	}

        logo(svg);


	let legend = svg.append("g")
		    .attr("id", "legend")
		    .attr("transform", 'translate('+(width-250)+', 0)');
	addLine (svg, 0, 5, "orange", t("current_year"));

        //bordersH (svg, ["", ""], 260, 70, 0);
        //addAreaH (svg, 0, "orange", t("dry"))

        //let colors = ['rgba(255,255,255,1)', '#084594', '#000000'];
        //label_mid (svg, colors, 0, 20, 80, "mm", width - 50, 30);

        //addAreaH (svg, 1, "#084594", t("rain"))

    }

    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    let c = ["Golden Delicious", "Cripps Pink", "Gala"];
    const handleSelectorVariety = (event) => {
        setVariety(event.target.value);
    };


    return (
        <>
            <div className="stagearea grid">

				<Title text={t("phenology_stats")} />

				<StackRow text={t("station")} component={<StationButton />} />
                
                <Stack spacing={0}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs} 
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

                    <Item>
                        <ComboBox
                                title={"variety"}
                                value={variety} items={c}
                                callback={handleSelectorVariety} />
                    </Item>

                </Stack>

                <div id="svgchart">
                    {data !== undefined && data.keys.length > 0
                        ?
                        <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
                        :
                        <div>No data available</div>
                    }
                </div>

                <Grid container flexDirection={"row"}>

	    <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                         de={""}
                         it={""}
                         en={""}
                    />
            </Grid>
	    
                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("dry")} />
                    </Grid>

                    <Grid item className="p-2">
                        {/* <ExcelButton callback={downloadExcel} /> */}
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
