import { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';

import { GraphContext } from "../GraphContext";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TabPanel } from "../../components/TabPanel";

import PropTypes from 'prop-types';

//import { TemperatureOverview } from './TemperatureOverview';
import { RainOverview } from './RainOverview';
import { RainMapDev } from './RainMapDev';
import { RainMap } from './RainMap';
import { RainBoxplot } from './RainBoxplot';
import { Dry } from './Dry';

import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";
import Title from "../../components/Title";

export function RainContent() {

	const { t, i18n } = useTranslation();
	const [parameter, setParameter] = useContext(GraphContext);

	const [value, setValue] = useState(0);

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (

		<>
			<div className="stagearea grid">

				<Title text={t("rain")} />

				{/* import StackRow from "../../../components/StackRow"; */}
				<StackRow text={t("station")} component={<StationButton />} />

				<Box sx={{ width: '100%' }}>

					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value}
							onChange={handleChange} 
							variant="scrollable" 
							aria-label="basic tabs example"
						>
							<Tab label={t("tagesmittelwerte")} {...a11yProps(0)} />
							<Tab label={t("langjährige_summenwerte")} {...a11yProps(1)} />
							<Tab label={t("abweichungen_vom_mittel")} {...a11yProps(2)} />
							<Tab label={t("boxplot")} {...a11yProps(3)} />
							<Tab label={t("niederschlagsfreie_zeiten")} {...a11yProps(4)} />
						</Tabs>
					</Box>

					<TabPanel value={value} index={0}>
						<RainOverview />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<RainMap />
					</TabPanel>

					<TabPanel value={value} index={2}>
						<RainMapDev />
					</TabPanel>

					<TabPanel value={value} index={3}>
						<RainBoxplot />
					</TabPanel>

					<TabPanel value={value} index={4}>
						<Dry />
					</TabPanel>
				</Box>

				<div className="flex-row p-4 expand">
				</div>

			</div>
		</>
	);

}

