import { useState, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import Layout from './routes/Layout';
import { createTheme, colors, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { AuthProvider } from 'react-auth-kit';

import { orange, pink, green } from "@material-ui/core/colors";

import './i18n';

import './App.css';
//import { GlobalStyles } from "./GlobalStyle";

const useStyles = makeStyles(theme => ({
    tab: { 
        '& .MuiBox-root': {
          padding: '0px',
          },
        },
    }));

const theme = createTheme({
  palette: {
    primary: {
      main: "#40516f",
    },
    secondary: {
      main: "#355E3B",
    },
    secondary: {
      main: "#355E3B",
    }/*,
    white : {
     main: "#ffffff" 
    },
    black : {
     main: "#000000" 
    }*/
  },
  typography: {
    fontFamily: [
      'Avenir',
      'Roboto',
      'sans-serif',
      'Segoe UI'
    ].join(','),
  },
  overrides: {
    MuiInputBaseInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "green"
        },
        "&:hover $notchedOutline": {
          borderColor: "red"
        },
        "&$focused $notchedOutline": {
          borderColor: "purple"
        },
        "&&& $input": {
          padding: "1px"
        }
      }
    },
  },
  components: {
    TabPanel : {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
	        color: 'black',
        },
      },
    },
    MuiTableHeader: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          backgroundColor: '#008051',
          color: "white",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          //backgroundColor: '#008051',
          //color: "white",
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: "#fff",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          //backgroundColor: '#008051',
          //color: "white",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          width: '100%',
          backgroundColor: 'var(--color-gray-100)',
        },
        icon: {
          color: 'var(--color-secondary)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px"
        }
      }
    },
  },

});

const App = () => {

  return (
    <>
    {/*<GlobalStyles /> */}
    <AuthProvider authType = {'cookie'}
                  authName={'_auth'}
                  cookieDomain={window.location.hostname}
                  cookieSecure={false}>
       <ThemeProvider theme={theme} >
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </ThemeProvider>
    </AuthProvider>
       
    </>
  );
}

export default App;
