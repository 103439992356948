import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { getCurrentYear, getCurrentMonth } from '../../../js/datetime';

import { GraphContext } from "../../GraphContext";
import "../../../styles.css";
import { saveAs } from 'file-saver';

import dayjs from 'dayjs';

import { preferences } from "../../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { y_axis, x_axis, y_array, xy_matrix, label } from "../../../components/Calendar/calendar";
import { xlabel, ylabel, logo } from '../../../components/d3/charts/chart';

import ExcelButton from '../../../components/ExcelButton';
import GraphicButton from '../../../components/GraphicButton';
import InfoButton from '../../../components/InfoButton';
import ComboBox from '../../../components/ComboBox';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const Map = ({ id, sensor }) => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter, setParameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        var r = getWidth();
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);
    const [classification, setClassification] = useState(1);

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Temperatur langjährige Mittelwerte', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])


    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }, [resize, data, i18n.language]);

    useEffect(() => {

        d3.json(preferences.address + "/query/heatmap/map/get.php?station=" + parameter.station + "&sensor=" + sensor + "&year=" + year)
            .then((data) => {
                setData(data);
                const svg = d3.select(svgRef.current);
            });
    }, [parameter, year, sensor]);

    const downloadExcel = () => {

        var url = preferences.address + '/query/heatmap/map/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.val = classification;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })

            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }


    function draw(svg) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 40,
            right: 50,
            bottom: 30,
            left: 50,
        }; // margin of chart

        const cellsize = 20;
        const cellborder = 2;
        const gap = 100;

        const y_offset = 5;

        //const sec_right = (width - 70) * 0.8;
        let pw = parseInt(svg.style("width"));

        let width = pw > 1000 ? 1000 : pw;

        // let width = parseInt(svg.style("width"));
        const x_offset = width * 0.04 + margin.left;
        const sec_left = (width - 70) * 0.2;
        const cellwidth = sec_left - margin.left;

        let max = d3.max(data.years.map((d) => { return d.year }));
        let min = d3.min(data.years.map((d) => { return d.year }));

        let minMonth = d3.min(data.months.map((d) => { return d.val }));
        let maxMonth = d3.max(data.months.map((d) => { return d.val }));

        let height = (max - min) * cellsize + margin.top + margin.bottom;
        if (height < 100) height = 200;

        svg
            .attr("width", width)
            .attr("height", height);


        const mean = data.long.value;
        const colors = ['white', d3.schemePaired[1]];
        const colorsY = ['white', d3.schemePaired[7]];
        let rangemin = 0;
        let rangemax = 0;

        let rangeminMonth = 0;
        let rangemaxMonth = 0;
        switch (classification) {
            case 1:
                rangemin = d3.min(data.years.map((d) => { if (d.year !== dayjs().year()) return d.val }));
                rangemax = d3.max(data.years.map((d) => { if (d.year !== dayjs().year()) return d.val }));
                rangeminMonth = d3.min(data.months.map((d) => { if (d.year !== dayjs().year()) return d.val }));
                rangemaxMonth = d3.max(data.months.map((d) => { if (d.year !== dayjs().year()) return d.val }));
                break;
            case 2:
                rangemin = d3.min(data.years.map((d) => { if (d.year !== dayjs().year()) return d.min }));
                rangemax = d3.max(data.years.map((d) => { if (d.year !== dayjs().year()) return d.min }));
                rangeminMonth = d3.min(data.months.map((d) => { if (d.year !== dayjs().year()) return d.min }));
                rangemaxMonth = d3.max(data.months.map((d) => { if (d.year !== dayjs().year()) return d.min }));
                break;
            case 3:
                rangemin = d3.min(data.years.map((d) => { if (d.year !== dayjs().year()) return d.max }));
                rangemax = d3.max(data.years.map((d) => { if (d.year !== dayjs().year()) return d.max }));
                rangeminMonth = d3.min(data.months.map((d) => { if (d.year !== dayjs().year()) return d.max }));
                rangemaxMonth = d3.max(data.months.map((d) => { if (d.year !== dayjs().year()) return d.max }));
                break;
            case 4:
                rangemin = d3.min(data.years.map((d) => { if (d.year !== dayjs().year()) return d.minmin }));
                rangemax = d3.max(data.years.map((d) => { if (d.year !== dayjs().year()) return d.minmin }));
                rangeminMonth = d3.min(data.months.map((d) => { if (d.year !== dayjs().year()) return d.minmin }));
                rangemaxMonth = d3.max(data.months.map((d) => { if (d.year !== dayjs().year()) return d.minmin }));
                break;
            case 5:
                rangemin = d3.min(data.years.map((d) => { if (d.year !== dayjs().year()) return d.maxmax }));
                rangemax = d3.max(data.years.map((d) => { if (d.year !== dayjs().year()) return d.maxmax }));
                rangeminMonth = d3.min(data.months.map((d) => { if (d.year !== dayjs().year()) return d.maxmax }));
                rangemaxMonth = d3.max(data.months.map((d) => { if (d.year !== dayjs().year()) return d.maxmax }));
                break;
        }

        const colorFn = d3.scaleLinear()
            //.domain([-10, 10, 20, 40])
            .domain([rangeminMonth, rangemaxMonth])
            .range(colors)
            .interpolate(d3.interpolateCubehelix);

        const colorFnYear = d3.scaleLinear()
            //.domain([mean - 5, mean, mean + 5])
            .domain([rangemin, rangemax])
            .range(colorsY)
            .interpolate(d3.interpolateCubehelix);

        y_axis(svg, data, y_offset, cellsize, margin.left, margin.top);

        let f1 = null;
        let c1 = null;
        switch (classification) {
            case 1:
                f1 = (d) => { return Math.round((d.val) * 10) / 10 + data.unit };
                c1 = (d) => colorFnYear(d.val);
                break;
            case 2:
                f1 = (d) => { return Math.round((d.min) * 10) / 10 + data.unit };
                c1 = (d) => colorFnYear(d.min);
                break;
            case 3:
                f1 = (d) => { return Math.round((d.max) * 10) / 10 + data.unit };
                c1 = (d) => colorFnYear(d.max);
                break;
            case 4:
                f1 = (d) => { return Math.round((d.minmin) * 10) / 10 + data.unit };
                c1 = (d) => colorFnYear(d.minmin);
                break;
            case 5:
                f1 = (d) => { return Math.round((d.maxmax) * 10) / 10 + data.unit };
                c1 = (d) => colorFnYear(d.maxmax);
                break;
        }


        y_array(svg, data, cellwidth, cellsize,
            cellborder, 0, margin.top,
            55, y_offset, colorFnYear,
            c1, f1);

        // Create scale
        let xScale = x_axis(svg, sec_left, width - (margin.right + 70), 20, margin.top, t);

        // months
        let f2 = null;
        let c2 = null
        switch (classification) {
            case 1:
                f2 = (d) => { return Math.round((d.val) * 10) / 10 + data.unit };
                c2 = (d, i) => colorFn(d.val);
                break;
            case 2:
                f2 = (d) => { return Math.round((d.min) * 10) / 10 + data.unit };
                c2 = (d, i) => colorFn(d.min);
                break;
            case 3:
                f2 = (d) => { return Math.round((d.max) * 10) / 10 + data.unit };
                c2 = (d, i) => colorFn(d.max);
                break;
            case 4:
                f2 = (d) => { return Math.round((d.minmin) * 10) / 10 + data.unit };
                c2 = (d, i) => colorFn(d.minmin);
                break;
            case 5:
                f2 = (d) => { return Math.round((d.maxmax) * 10) / 10 + data.unit };
                c2 = (d, i) => colorFn(d.maxmax);
                break;
        }

        xy_matrix(svg, data, xScale, cellsize, cellborder,
            gap, max, 20, margin.top, x_offset, colorFn, c2, f2);

        /* Scale */
        label(svg, colorsY, Math.round(rangemin), Math.round(rangemax), data.unit, width - 50, 50, "Y");
        label(svg, colors, Math.round(rangeminMonth), Math.round(rangemaxMonth), data.unit, width - 50, 250, "M");

        xlabel(svg, t("months"), width * 0.55, 15);
        xlabel(svg, t("years"), width * 0.1, 15);

        logo(svg);

    }

    const handleChangeClassification = (event) => {
        setClassification(event.target.value);
    };


    return (
        <>
            <div>
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Die Grafik der langjährigen Mittelwerte der Temperatur zeigt die Jahresdurchschnitts- und Monatsdurchschnittstemperaturen seit Beginn der Aufzeichnungen an der ausgewählten Wetterstation. In der ersten Spalte sind die Jahresdurchschnittstemperaturen aufgeführt, während in der Kalenderansicht unter dem Bereich 'Monate' die mittleren Temperaturen der einzelnen Monate angezeigt werden. Die genauen Werte können durch Bewegen des Mauszeigers abgelesen werden. Zudem sind die mittleren Temperaturen mithilfe einer Farbskala kategorisiert. Es besteht die Möglichkeit, auch die durchschnittlichen Minimum- oder Maximumwerte aus dem Drop-down-Liste oben rechts auszuwählen."}
                        it={""}
                        en={""}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("temperature_maps")} />
                </Grid>

                <Grid item className="p-2">
                    {/* <ExcelButton callback={downloadExcel}/> */}
                </Grid>

            </Grid>

        </>
    );

}
