import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { saveAs } from 'file-saver';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import CircularProgess from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import SpinButton from '../../components/SpinButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import Slider from '@mui/material/Slider';

import { label_mid } from "../../components/Calendar/calendar";
import { logo } from "../../components/d3/charts/chart";
import { bordersH, addAreaH } from "../../components/d3/legend/legend";

import { timeformat, getWidth, responsivness, getMonths } from '../../components/d3/d3timeformat';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        width: 200,
        display: "flex"
    },
});


export const Dry = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [data, setData] = useState();
    const [period, setPeriod] = useState(14);
    const [threshold, setThreshold] = useState(0);
    const [rain, setRain] = useState(false);

    const classes = useStyles();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg, rain);

        //svg.selectAll("#rain_block").attr('display', "none");

    }, [resize, data, rain]);

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/rain/dry/get.php?station=" + parameter.station + "&period=" + period + "&level=" + threshold)
            .then((data) => {
                setData(data);
            });
    }, [threshold, period, parameter.station]);

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Niederschlagsfreie Zeiten', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])


    function range(start, end) {
        let a = [];
        if (start > end) {
            for (let i = start; i >= end; i--)
                a.push(i);
            return a;
        }
        else {
            for (let i = start; i <= end; i++)
                a.push(i);
            return a;
        }
    }

    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/dry/excel.php';

        let block = {};
        block.station = parameter.station;
        //block.year = year;
        block.period = period;
        block.level = threshold;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    function draw(svg, rain) {

        // Timeformat
        timeformat(t);

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 60,
            right: 100,
            bottom: 40,
            left: 65,
        }; // margin of chart

        const offset = 0;

        let width = parseInt(svg.style("width"));

        //console.log (width);
        let parseTime = d3.timeParse("%Y-%m-%d");

        // Create scale
        let step = 25;
        let d = new Date();
        let m1 = d.getFullYear();
        let m2 = d3.max(data.dry.map((d) => { return d.year }));
        let m3 = d3.max(data.rain.map((d) => { return d.year }));
        let max_year = d3.max([m1, m2, m3]);
        let min_year = d3.min([d3.min(data.dry.map((d) => { return d.year })), d3.min(data.rain.map((d) => { return d.year }))]);

        // Anzahl Jahre
        let years = max_year - min_year;
        if (years === 0) years = 1;

        let height = years * step + margin.top + margin.bottom;
        if (height < 200) height = 200;
        svg.attr("height", height);

        // Max year trocken
        let minD = d3.min(data.dry.map((d) => { return d.year }));
        let maxD = d3.max(data.dry.map((d) => { return d.year }));

        // Max year Regen
        let minR = d3.min(data.rain.map((d) => { return d.year }));
        let maxR = d3.max(data.rain.map((d) => { return d.year }));

        // 
        let min = d3.min([minD, minR]);
        let max = d3.max([maxD, maxR, m1]);

        var yScale = d3.scaleBand()
            .domain(range(max, min))
            .range([0, years * step]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale);

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(y_axis);

        // X Label
        svg.append("text")
            .attr("class", "x label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", 15)
            .text(t("months"));

        // Y Label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", 20)
            .text(t("years"));

        // Create scale
        let year = new Date().getFullYear();
        var xScaleTime = d3.scaleTime()
            .domain([parseTime(year + "-01-01"), parseTime(year + "-12-31")])
            .range([offset, width - margin.right - 50]);

        // Add scales to axis
        var x_axis = d3.axisTop().scale(xScaleTime)
            .tickFormat((d) => { return d3.timeFormat("%B")(d) });

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", " + margin.top + ")")
            .call(x_axis);

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,' + margin.top + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-width + margin.left + margin.right - 15, 0).tickFormat(""));

        // Grid vertikal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,' + margin.top + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(x_axis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat(""));

        let border = 10;

        const rgd = svg.append("g").attr('id', 'dry_block');
        const rgd2 = svg.append("g").attr('id', 'rain_block');
        //let x_offset = 0;
        //let gap = 10;
        //let x = 0;
        //let y = 0;
        //let c_f = 0;

        // Trockephasen
        rgd
            .attr("transform", "translate(" + (margin.left) + ", " + margin.top + ")")
            .selectAll("rect")
            .data(data.dry)
            .join("rect")
            .attr("width", (d, i) => { return (xScaleTime(parseTime(d.last)) - xScaleTime(parseTime(d.first))) })
            .attr("height", step - border)
            .attr("x", (d, i) => { return xScaleTime(parseTime(d.first)) })
            .attr("y", (d, i) => { return yScale(d.year) + 5; })
            .attr("fill", "orange")
            .style("stroke-width", 1)
            .style("stroke", "black")
            .attr("opacity", 0.7)
            .attr("rx", 2)
            .attr("ry", 2)
            .append("title")
            .text((d) => { return d.days });

        // Regen
        var getColor = d3.scaleLinear()
            .domain([0, 20])
            .range(['rgba(255,255,255,1)', '#084594']);
        rgd2
            .attr("transform", "translate(" + (margin.left) + ", " + margin.top + ")")
            .selectAll("rect")
            .data(data.rain)
            .join("rect")
            .attr("width", (d, i) => { return (width - margin.left - margin.right) / 365 })
            .attr("height", step - border)
            .attr("x", (d, i) => { return xScaleTime(parseTime(d.timeline)) })
            .attr("y", (d, i) => { return yScale(d.year) + 5; })
            .attr("fill", (d, i) => { return getColor(d.value) })
            //.style("display", (d) => {return d.value < 1 ? 'none' : null})
            .style("stroke-width", 0)
            .style("stroke", "black")
            .attr("rx", 0)
            .attr("ry", 0)
            .attr("display", rain === true ? null : "none");

        logo(svg);

        bordersH(svg, ["", ""], 260, 70, 0);
        addAreaH(svg, 0, 250, "orange", t("dry"))

        let colors = ['rgba(255,255,255,1)', '#084594', '#000000'];
        label_mid(svg, colors, 0, 20, 80, "mm", width - 50, 30);

        //addAreaH (svg, 1, "#084594", t("rain"))

    }

    const handleChange = (event, newValue) => {
        setPeriod(newValue);
    };

    function valuetext(value) {
        return `${value}°C`;
    }

    const marks = [
        {
            value: 7,
            label: '7',
        },
        {
            value: 14,
            label: '14',
        },
        {
            value: 21,
            label: '21',
        },
        {
            value: 28,
            label: '28',
        },
        {
            value: 35,
            label: '35',
        },
        {
            value: 42,
            label: '42',
        },
        {
            value: 49,
            label: '49',
        },
    ];

    /*const onLevelChange = ((event) => {

        //if (event.target.value < 0) event.target.value = 0;
        let l = event.target.value;

        //console.log (l);

        setThreshold(l);
    })*/

    const up = (() => {
        setThreshold(threshold => threshold + 0.5);
    })

    const down = (() => {
        if (threshold > 0)
            setThreshold(threshold => threshold - 0.5);
    })

    const onRainClicked = ((event) => {
        console.log(!rain)
        setRain(!rain);
        //let svg = d3.selectAll("#svgchart");
        //console.log (rain, rain === true)
        //rain === false ? svg.select("#rain_block").attr('display', null) : svg.selectAll("#rain_block").attr('display', "none");
    })


    /*const excelDownloadCallback = () =>
    {

        d3.json(preferences.address + "/query/climate/dry_excel.php?station=" + parameter.station + "&period=" + period + "&level=" + threshold)
            .then((data) => {
                var file = new File([data], "Export.xlsx", { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
            });

    }*/

    return (
        <>

            <Stack spacing={0}>

                <Item>
                    <Box
                        sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                        className="p-1">

                        <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                            {t("mindestdauer")}
                        </Typography>
                        <div className={classes.root}>
                            <Slider
                                onChange={handleChange}
                                defaultValue={period}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={1}
                                min={5}
                                max={50}
                                marks={marks}
                            />
                        </div>
                    </Box>
                </Item>

                <Item>
                    <Box
                        sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                        className="p-1">
                        <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                            {t("schwellenwert_mm_tag")}
                        </Typography>

                        <SpinButton value={threshold} up={up} down={down} />
                    </Box>
                </Item>

                <Item>
                    <Box
                        sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                        className="p-1">
                        <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                            {t("rain")}
                        </Typography>

                        <Checkbox checked={rain} onChange={onRainClicked} />
                    </Box>

                </Item>

            </Stack>

            <div id="svgchart">
                {data === undefined ? <CircularProgess /> : <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>}
            </div>


            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Das interaktive Tool 'Niederschlagsfreie Zeiten' ermöglicht es, die Dauer von Trockenperioden an einer bestimmten Wetterstation zu berechnen, indem verschiedene Parameter kombiniert werden. Dabei können die Benutzer die Mindestdauer der anzuzeigenden regenfreien Perioden sowie den Schwellenwert des Tagesniederschlags definieren, bis zu welchem ein Tag als “niederschlagsfrei” eingestuft werden soll. Die grafische Darstellung zeigt dann die niederschlagsfreien oder niederschlagsarmen Zeiträume in Orange an. Durch Aktivieren des Auswahlkästchens können im Hintergrund die täglichen Niederschlagsmengen farblich differenziert dargestellt werden. Mittels Mauszeiger kann die Länge der Trockenperiode direkt in der Grafik angezeigt werden."}
                        it={"Lo strumento interattivo “Periodi senza precipitazioni” consente di calcolare la durata dei periodi di siccità in una specifica stazione meteorologica, combinando vari parametri. Gli utenti possono definire la durata minima dei periodi senza pioggia da visualizzare e il valore soglia delle precipitazioni giornaliere che andrà a definire se un giorno puó essere classificato come “senza precipitazioni”. La visualizzazione grafica mostra quindi i periodi senza precipitazioni o a bassa precipitazione in arancione. Attivando la casella di controllo, le quantità di precipitazioni giornaliere possono essere visualizzate in colori diversi sullo sfondo. La durata del periodo secco può essere visualizzata direttamente nel grafico con il puntatore del mouse."}
                        en={"The interactive tool “Precipitation-free periods” makes it possible to calculate the duration of dry periods at a specific weather station by combining different parameters. Users can define the minimum duration of the rain-free periods to be displayed as well as the threshold value of the daily precipitation up to which a day is to be classified as “precipitation-free”. The graphical display then shows the precipitation-free or low-precipitation periods in orange. By activating the checkbox, the daily precipitation amounts can be displayed in different colors in the background. The length of the dry period can be displayed directly in the graph using the mouse pointer."}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("dry")} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>

            </Grid>

        </>
    );

}
