import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../../js/datetime';

import { GraphContext } from "../../GraphContext";
import "../../../styles.css";

import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

import { preferences } from "../../../preferences";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { y_axis, x_axis, y_array, xy_matrix, label } from "../../../components/Calendar/calendar";
import { xlabel, logo } from '../../../components/d3/charts/chart';

import ExcelButton from '../../../components/ExcelButton';
import GraphicButton from '../../../components/GraphicButton';
import InfoButton from '../../../components/InfoButton';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const MapDev = ({ id, sensor }) => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();

    const [parameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setYear(1900 + date.getYear());
    }

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Temperaturabweichungen vom langjährigen Mittel', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }, [resize, data, i18n.language]);

    useEffect(() => {

        d3.json(preferences.address + "/query/heatmap/deviation/get.php?station=" + parameter.station + "&sensor=" + sensor + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [parameter, year, sensor]);


    const downloadExcel = () => {

        var url = preferences.address + '/query/heatmap/deviation/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })

            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    function draw(svg) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 40,
            right: 50,
            bottom: 30,
            left: 50,
        }; // margin of chart

        let width = parseInt(svg.style("width")) > 1000 ? 1000 : parseInt(svg.style("width"));

        let maxYear = d3.max(data.years.map((d) => { return d.year }));
        let minYear = d3.min(data.years.map((d) => { return d.year }));

        let maxY = d3.max(data.years.map((d) => { return d.val - data.basis.val }));
        let minY = d3.min(data.years.map((d) => { return d.val - data.basis.val }));

        const avgM = (m) => { return data.basisb.filter((d) => d.month === m).map((e) => { return e.val }) }

        let maxM = d3.max(data.months.map((d) => { return d.val - d3.mean(avgM(d.month)) }));
        let minM = d3.min(data.months.map((d) => { return d.val - d3.mean(avgM(d.month)) }));

        const cellsize = 20;
        const cellborder = 2;
        const gap = 0;
        const x_offset = width * 0.04 + margin.left;
        const y_offset = 5;

        const sec_left = (width - 70) * 0.2;
        const cellwidth = sec_left - margin.left;

        //const sec_right = (width - 70) * 0.8;

        let height = (maxYear - minYear) * cellsize + margin.top + margin.bottom;
        if (height < 100) height = 200;

        svg
            .attr("width", width)
            .attr("height", height);

        //const colors = ['white', d3.scaleOrdinal(0)];
        const colors = [d3.schemePaired[1], 'white', d3.schemePaired[5]];
        const colorsY = [d3.schemePaired[8], 'white', d3.schemePaired[7]];

        // Monat
        const colorFn = d3.scaleLinear()
            .domain([minM, 0, maxM])
            .range(colors)
            .interpolate(d3.interpolateCubehelix);

        // Jahr
        const colorFnYear = d3.scaleLinear()
            .domain([minY, 0, maxY])
            .range(colorsY)
            .interpolate(d3.interpolateCubehelix);

        y_axis(svg, data, y_offset, cellsize, margin.left, margin.top);

        let f1 = (d) => { return Math.round((d.val - data.basis.val) * 10) / 10 + data.unit };
        let c1 = (d) => colorFnYear(d.val - data.basis.val);

        y_array(svg, data, cellwidth, cellsize,
            cellborder, 0, margin.top,
            55, y_offset, colorFnYear,
            c1, f1);

        // Create scale
        let xScale = x_axis(svg, sec_left, width - (margin.right + 70), 20, margin.top, t);

        // months
        let f2 = (d) => { return Math.round((d.val - (data.basisb[d.month - 1] !== undefined ? data.basisb[d.month - 1].val : 0)) * 10) / 10 + " " + data.unit };

        /*let b = 0;
        try {
            b = data.basisb[d.month-1].val;
        } catch (error){
        }*/
        //let c2 = (d, i) => colorFn(d.val - data.basisb[d.month-1].val );
        let c2 = (d, i) => colorFn(d.val - (data.basisb[d.month - 1] !== undefined ? data.basisb[d.month - 1].val : 0));

        xy_matrix(svg, data, xScale, cellsize, cellborder,
            gap, maxYear, 20, margin.top, x_offset, colorFn, c2, f2);

        // Label
        label(svg, colorsY, minY, maxY, data.unit, width - 50, 50, "Y");
        label(svg, colors, minM, maxM, data.unit, width - 50, 250, "M");

        xlabel(svg, t("months"), width * 0.55, 15);
        xlabel(svg, t("years"), width * 0.1, 15);
        logo(svg);
    }

    return (
        <>
            <div>
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Die Grafik der Temperaturabweichungen zeigt die monatlichen und jährlichen Temperaturabweichungen seit Beginn der Wetteraufzeichnungen an der ausgewählten Wetterstation. Diese Abweichungen werden im Vergleich zum langjährigen Mittelwert berechnet, der sich aus den Temperaturdaten der jeweils vorangehenden 30 Jahre zusammensetzt. Durch einen Mausklick auf das entsprechende Feld können die genauen Abweichungswerte abgerufen werden. Darüber hinaus sind die verschiedenen Abweichungskategorien anhand einer Farbskala dargestellt. Starke Temperaturabweichungen nach unten sind dunkelblau, starke Abweichungen nach oben dunkelrot eingefärbt."}
                        it={""}
                        en={""}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("temperature_divs")} />
                </Grid>

                <Grid item className="p-2">
                    {/* <ExcelButton callback={downloadExcel} /> */}
                </Grid>

            </Grid>

        </>
    );

}
