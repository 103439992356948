import * as d3 from "d3";


function y_axis (svg, data, offset, step, x, y)
{

    // Create scale
    var scale = d3.scaleBand()
        .domain(data.years.map((d) => {return d.year}))
        .range([ offset,
                    offset +(data.years.length) * step]);
        

    // Add scales to axis
    var y_axis = d3.axisLeft()
        .scale(scale);
    
    //Append group and insert axis
    svg.append("g")
        .attr("transform", "translate("+x+","+y+")")
        .call(y_axis);
}


function x_axis (svg, start, end, x, y, t=null)
{
    //let start = offset + step * 3 + gap;
    //let start = x + offset;
    
    var scale = d3.scaleBand()
    .domain([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
    .range([start, end])
    .padding(0.1);

    var scaleLabel = d3.scaleBand()
    .domain(["_jan", "_feb", "_mar", "_apr", "_may", "_jun", "_jul", "_aug", "_sep", "_oct", "_nov", "_dec"].map((d) => {return t(d)} ))
    .range([start, end])
    .padding(0.1);

    // Add scales to axis
   var x_axis = d3.axisTop()
       .scale(scaleLabel);

   //Append group and insert axis
   svg.append("g")
       .attr("transform", "translate("+x+", "+y+")")
       .call(x_axis);

    return scale;
}

function y_array (svg, data, cellwidth, h, border, x, y,
                  x_offset, y_offset, colorFn,
                  c_f,
                  a_f
                  )
{
    const rg = svg.append("g").attr("class", "rectgroup");

    let ldate = Date();
    const yearrect = rg
    .attr("transform", "translate("+x+","+y+")")
    .selectAll("rect")
    .data(data.years)
    .join("rect")
    .attr("width", cellwidth)
    .attr("height", h - border)
    .attr("x", (d, i) => {return x_offset;})
    .attr("y", (d, i) => {return y_offset + i * h;})
    .attr("fill", c_f)
    .style("stroke-width", 2)
    .style("stroke", (d, i) => {return (i === 0) ? "black" : "none"})
    .attr("opacity", 1)
    .attr("rx", 5)
    .attr("ry", 5);

    // Tooltip
    rg
    .selectAll("rect")
    .data(data.years)
    .append("title")
    .text(a_f)
    ;
   
yearrect
    .on("mouseenter", function (e, v) {
	  d3.select(e.target).attr("opacity", 0.8);
        //d3.select(e.target).style("stroke", );
        //d3.select(e.target).style("stroke-width", 3);
    })
    .on("mouseleave", function (e, v) {
	  d3.select(e.target).attr("opacity", 1);
        //d3.select(e.target).style("stroke-width", 1);
    })
    .on("mousemove", function (e, v) {});

}

function xy_matrix (svg, data, scale, step, border, gap, max, x, y,
                    x_offset, colorFn,
                    c_f,
                    a_f)
{
    const rgd = svg.append("g").attr("class", "rectgroupd"); 
    
    // months
    let ldate = new Date();
    const monthrect = rgd
    .attr("transform", `translate(${x}, ${y})`)
    .selectAll("rect")
    .data(data.months)
    .join("rect")
    .attr("width", scale !== undefined ? scale.bandwidth() : 50)
    .attr("height", step - border)
    .attr("x", (d, i) => { return scale(d.month); })
    .attr("y", (d, i) => { return 5 + (max-d.year) * step;}) // y
    .attr("fill", c_f)
    .style("stroke-width", 2)
    .style("stroke", (d) => {return ((ldate.getYear()+1900) == d.year && (ldate.getMonth()+1) == d.month) ? "black" : "none"})
    .attr("rx", 4)
    .attr("ry", 4);

    // Tooltip
    rgd
    .selectAll("rect")
    .data(data.months)
    .append("title")
    .text(a_f);

    monthrect
    .on("mouseenter", function (e, v) {
	  d3.select(e.target).attr("opacity", 0.8);
        //d3.select(e.target).style("stroke", "black");
	//d3.selectAll(e.target).classed("hover", true);
    })
    .on("mouseleave", function (e, v) {
	  d3.select(e.target).attr("opacity", 1);
        //d3.select(e.target).style("stroke", "none");
	//d3.selectAll(e.target).classed("hover", false);
    })
    .on("mousemove", function (e, v) {
    });
}

function label (svg, colors, min, max, unit, x, y, id="grad")
{
    var width = 20;
    var height = 150;

    var grad = svg.append('defs')
    .append('linearGradient')
    .attr('id', id)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%');

grad.selectAll('stop')
    .data(colors.reverse())
    .enter()
    .append('stop')
    .style('stop-color', function(d){ return d; })
    .attr('offset', function(d, i){
      return 100 * (i / (colors.length - 1)) + '%';
    })
  
svg.append('rect')
    .attr('x', x-20)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .style('fill', `url(#${id})`);

// Create scale
var scale = d3.scaleLinear()
    .domain([max, min])
    .range([0, 150]);
 
// Add scales to axis
var label_axis = d3.axisRight()
    .ticks(10, "f")
    .scale(scale);

//Append group and insert axis
svg.append("g")
    .attr("transform", "translate("+x+", "+y+")")
    .call(label_axis);

// Y axis label:
svg.append("text")
    .attr("text-anchor", "end")
    //.attr("transform", "rotate(-80)")
    .attr("x", x+20)
    .attr("y", y-10)
    .text(unit)
}

function label_mid (svg, colors, min, mid, max, unit, x, y)
{
    var width = 20;
    var height = 150;

    var grad = svg.append('defs')
    .append('linearGradient')
    .attr('id', "grad")
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%');

grad.selectAll('stop')
    .data(colors.reverse())
    .enter()
    .append('stop')
    .style('stop-color', function(d){ return d; })
    .attr('offset', function(d, i){
      return 100 * (i / (colors.length - 1)) + '%';
    })
  
svg.append('rect')
    .attr('x', x-20)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .style('fill', 'url(#grad)');

// Create scale
var scale = d3.scaleLinear()
    .domain([max, min])
    .range([0, 150]);
 
// Add scales to axis
var label_axis = d3.axisRight()
    .ticks(10, "f")
    .scale(scale);

//Append group and insert axis
svg.append("g")
    .attr("transform", "translate("+x+", "+y+")")
    .call(label_axis);

// Y axis label:
svg.append("text")
    .attr("text-anchor", "end")
    //.attr("transform", "rotate(-80)")
    .attr("x", x+20)
    .attr("y", y-10)
    .text(unit)
}

export { y_axis, x_axis, y_array, xy_matrix, label, label_mid };