import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { saveAs } from 'file-saver';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import CircularProgress from "@mui/material/CircularProgress";
import ExcelButton from '../../components/ExcelButton';
import InfoButton from '../../components/InfoButton';

import SpinButton from '../../components/SpinButton';
import GraphicButton from '../../components/GraphicButton';
import Slider from '@mui/material/Slider';

import { label_mid } from "../../components/Calendar/calendar";
import { logo } from "../../components/d3/charts/chart";
import { bordersH, addAreaH } from "../../components/d3/legend/legend";

import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";
import { timeformat, getWidth, responsivness } from '../../components/d3/d3timeformat';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';
import Title from "../../components/Title";


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        width: 200,
        display: "flex"
    },
});


export const Extreme = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [data, setData] = useState();
    const [period, setPeriod] = useState(14);
    const [threshold, setThreshold] = useState(0);
    const [rain, setRain] = useState(false);
    const [wind, setWind] = useState(false);

    const classes = useStyles();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg, rain);

        //svg.selectAll("#rain_block").attr('display', "none");

    }, [resize, data, rain, wind]);

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/extreme/get.php?station=" + parameter.station)
            .then((data) => {
                setData(data);
            });
    }, [threshold, period, parameter]);

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Extreme Wetterereignisse', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])


    function range(start, end) {
        let a = [];
        if (start > end) {
            for (let i = start; i >= end; i--)
                a.push(i);
            return a;
        }
        else {
            for (let i = start; i <= end; i++)
                a.push(i);
            return a;
        }
    }

    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/dry/excel.php';

        let block = {};
        block.station = parameter.station;
        //block.year = year;
        block.period = period;
        block.level = threshold;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    function draw(svg, rain) {

        /*var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);*/
        
        // Import time format
        timeformat(t);

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 60,
            right: 50,
            bottom: 40,
            left: 65,
        }; // margin of chart

        const offset = 0;

        let width = parseInt(svg.style("width"));

        //console.log (width);
        let parseTime = d3.timeParse("%Y-%m-%d");

        // Create scale
        let step = 25;
        let d = new Date();
        let m1 = d.getFullYear();

        let max = d3.max([  d3.max(data.heat.map((d) => { return d.year })),
                            d3.max(data.frost.map((d) => { return d.year })),
                            data.wind !== null ? d3.max(data.wind.map((d) => { return d.year })) : null, 
                            data.stark !== null ? d3.max(data.stark.map((d) => { return d.year })) : null]);
        let min = d3.min([  d3.min(data.heat.map((d) => { return d.year })), 
                            d3.min(data.frost.map((d) => { return d.year })),
                            data.wind !== null ? d3.min(data.wind.map((d) => { return d.year })) : null, 
                            data.stark !== null ? d3.min(data.stark.map((d) => { return d.year })) : null]);

        // Anzahl Jahre
        let years = max - min;
        if (years === 0) years = 1;

        let height = years * step + margin.top + margin.bottom;
        if (height < 200) height = 200;
        svg.attr("height", height);

        // Max year trocken
        /*let minD = d3.min(data.dry.map((d) => { return d.year }));
        let maxD = d3.max(data.dry.map((d) => { return d.year }));

        // Max year Regen
        let minR = d3.min(data.rain.map((d) => { return d.year }));
        let maxR = d3.max(data.rain.map((d) => { return d.year }));
        */

        // 
        //let max = max_year;//d3.min([minD, minR]);
        //let min = min_year;//d3.max([maxD, maxR, m1]);

        var yScale = d3.scaleBand()
            .domain(range(max, min))
            .range([0, years * step]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale);

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(y_axis);

        // X Label
        svg.append("text")
            .attr("class", "x label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", 15)
            .text(t("months"));

        // Y Label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", 20)
            .text(t("years"));

        // Create scale
        let year = new Date().getFullYear();
        var xScaleTime = d3.scaleTime()
            .domain([parseTime(year + "-01-01"), parseTime(year + "-12-31")])
            .range([offset, width - margin.right - 50]);

        // Add scales to axis
        var x_axis = d3.axisTop().scale(xScaleTime)
            .tickFormat((d) => { return d3.timeFormat("%B")(d) });

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", " + margin.top + ")")
            .call(x_axis);

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,' + margin.top + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-width + margin.left + margin.right - 15, 0).tickFormat(""));

        // Grid vertikal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' ,' + margin.top + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(x_axis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat(""));

        let border = 10;

        const rgdH = svg.append("g").attr('id', 'heat_block');
        const rgdF = svg.append("g").attr('id', 'frost_block');

        const rgd2 = svg.append("g").attr('id', 'rain_block');

        // Hitzwellen
        rgdH
            .attr("transform", "translate(" + (margin.left) + ", " + margin.top + ")")
            .selectAll("rect")
            .data(data.heat)
            .join("rect")
            .attr("width", (d, i) => { return (xScaleTime(parseTime(d.last)) - xScaleTime(parseTime(d.first))) })
            .attr("height", step - border)
            .attr("x", (d, i) => { return xScaleTime(parseTime(d.first)) })
            .attr("y", (d, i) => { return yScale(d.year) + 5; })
            .attr("fill", "red")
            .style("stroke-width", 1)
            .style("stroke", "black")
            .attr("opacity", 0.7)
            .attr("rx", 2)
            .attr("ry", 2)
            .attr("opacity", 0.4)
            .append("title")
            .text((d) => { return d.days + " Tage" });

        // Kältewellen
        rgdF
            .attr("transform", "translate(" + (margin.left) + ", " + margin.top + ")")
            .selectAll("rect")
            .data(data.frost)
            .join("rect")
            .attr("width", (d, i) => { return (xScaleTime(parseTime(d.last)) - xScaleTime(parseTime(d.first))) })
            .attr("height", step - border)
            .attr("x", (d, i) => { return xScaleTime(parseTime(d.first)) })
            .attr("y", (d, i) => { return yScale(d.year) + 5; })
            .attr("fill", "steelblue")
            .style("stroke-width", 1)
            .style("stroke", "black")
            .attr("opacity", 0.7)
            .attr("rx", 2)
            .attr("ry", 2)
            .attr("opacity", 0.4)
            .append("title")
            .text((d) => { return d.days + " Tage" });

        // Starkregen
        if (data.stark !== null && rain) {
            const rgd3 = svg.append("g").attr('id', 'stark_block');
            var getColor = d3.scaleLinear()
                .domain([0, 80])
                .range(['rgba(255,255,255,1)', 'green']);
            rgd3
                .attr("transform", "translate(" + (margin.left) + ", " + margin.top + ")")
                .selectAll("circle")
                .data(data.stark)
                .enter().append("circle")
                .attr('cx', (d) => { return xScaleTime(parseTime(d.timeline)) })
                .attr('cy', (d, i) => { return yScale(d.year) + 12; })
                .attr('r', 6)
                .attr('stroke', 'black')
                .attr('fill', (d) => { return getColor(d.value) })
                .attr("opacity", 1)
                .append("title")
                .text((d) => { return d.value + " mm/h" });
        }

        // Windböhe
        if (data.wind !== null && wind) {
            const rgd4 = svg.append("g").attr('id', 'stark_block');
            var getColor = d3.scaleLinear()
                .domain([10, 20])
                .range(['yellow', 'orange']);
            rgd4
                .attr("transform", "translate(" + (margin.left) + ", " + margin.top + ")")
                .selectAll("circle")
                .data(data.wind)
                .enter().append("circle")
                .attr('cx', (d) => { return xScaleTime(parseTime(d.timeline)) })
                .attr('cy', (d, i) => { return yScale(d.year) + 12; })
                .attr('r', 6)
                .attr('stroke', 'black')
                .attr('fill', (d) => { return getColor(d.value) })
                .attr("opacity", 1)
                .append("title")
                .text((d) => { return d.value + " m/s" });
        }

        logo(svg);

        //bordersH (svg, ["", ""], 260, 70, 0);
        //addAreaH (svg, 0, 250, "orange", t("dry"))

        let colors = ['rgba(255,255,255,1)', '#084594', '#000000'];
        //label_mid (svg, colors, 0, 20, 80, "mm", width - 50, 30);

        //addAreaH (svg, 1, "#084594", t("rain"))

    }

    const handleChange = (event, newValue) => {
        setPeriod(newValue);
    };

    function valuetext(value) {
        return `${value}°C`;
    }

    const marks = [
        {
            value: 7,
            label: '7',
        },
        {
            value: 14,
            label: '14',
        },
        {
            value: 21,
            label: '21',
        },
        {
            value: 28,
            label: '28',
        },
        {
            value: 35,
            label: '35',
        },
        {
            value: 42,
            label: '42',
        },
        {
            value: 49,
            label: '49',
        },
    ];

    /*const onLevelChange = ((event) => {

        //if (event.target.value < 0) event.target.value = 0;
        let l = event.target.value;

        //console.log (l);

        setThreshold(l);
    })*/

    const up = (() => {
        setThreshold(threshold => threshold + 0.5);
    })

    const down = (() => {
        if (threshold > 0)
            setThreshold(threshold => threshold - 0.5);
    })

    const onRainClicked = ((event) => {
        setRain(!rain);
    })
    const onWindClicked = ((event) => {
        setWind(!wind);
    })


    return (
        <>

            <div className="stagearea grid">

                <Title text={t("extreme")} />

                <StackRow text={t("station")} component={<StationButton />} />

                <Stack spacing={0}>

                    <Item>
                        <Box
                            sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                            className="p-1">
                            <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                                {t("precipitation")}
                            </Typography>

                            <Checkbox checked={rain} onChange={onRainClicked} />
                        </Box>

                    </Item>

                    <Item>
                        <Box
                            sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                            className="p-1">
                            <Typography component={'h5'} sx={{ verticalAlign: "middle" }}>
                                {t("wind")}
                            </Typography>

                            <Checkbox checked={wind} onChange={onWindClicked} />
                        </Box>

                    </Item>

                </Stack>

                <div id="svgchart">
                    {data === undefined ? <CircularProgress/>: <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>}
                </div>

                <Grid container flexDirection={"row"}>

	    <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                         de={""}
                         it={""}
                         en={""}
                    />
            </Grid>
	    
                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("dry")} />
                    </Grid>

                    <Grid item className="p-2">
                        <ExcelButton callback={downloadExcel} />
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
