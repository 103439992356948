import * as d3 from "d3";
import * as ss from "simple-statistics";
import * as React from "react";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { saveAs } from 'file-saver';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

import ComboBox from '../../components/ComboBox';
import InfoBox from '../../components/InfoBox';
import SensorComboBox from '../../components/SensorComboBox';
import DatePicker from '../../components/DatePicker';
import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";

import Snackbar from "@mui/material/Snackbar";
import ExcelButton from '../../components/ExcelButton';
import SpinButton from '../../components/SpinButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import { label_mid } from "../../components/Calendar/calendar";
import { xlabel, ylabel, logo } from "../../components/d3/charts/chart";
import { bordersH, addAreaH } from "../../components/d3/legend/legend";
import Title from "../../components/Title";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {
        width: 200,
        display: "flex"
    },
});


export const PhenologyDiagramm = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [data, setData] = useState();
    const [period, setPeriod] = useState(14);
    const [threshold, setThreshold] = useState(0);
    const [rain, setRain] = useState(false);
    const [variety, setVariety] = useState(1);

    const [open, setOpen] = useState(false);

    const [combo1, setCombo1] = useState(1);
    const [combo2, setCombo2] = useState(5);

    const classes = useStyles();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            if (data.list.length > 0)
                draw(svg, rain);

        //svg.selectAll("#rain_block").attr('display', "none");

    }, [resize, data, rain, i18n.language]);

    useEffect(() => {

        d3.json(preferences.address + "/query/phenology/diagramm/get.php?station=" + parameter.station + "&variety=" + variety + "&stadion1=" + c[combo1 - 1] + "&stadion2=" + c[combo2 - 1])
            .then((data) => {
                setData(data);
            });
    }, [parameter, variety, combo1, combo2]);

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Phänologie Stadiumvergleich', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location])


    const downloadExcel = () => {

        let filename = "Export.xlsx";
        let url = preferences.address + "/query/phenology/diagramm/excel.php?station=" + parameter.station + "&variety=" + variety + "&stadion1=" + c[combo1 - 1] + "&stadion2=" + c[combo2 - 1]+ "&lang=" + i18n.language;
        
        setOpen(true);

        fetch(url)
            .then((response) => {
                try {
                    console.log(response.headers);
                    filename = response.headers.get('Content-Disposition').split('filename=')[1];
                }
                catch (error) { console.log(error); filename = 'Data_Export.xlsx' }
                setOpen(false);
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });
    }

    function draw(svg, rain) {

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 60,
            right: 40,
            bottom: 40,
            left: 80,
        }; // margin of chart

        const offset = 0;

        let width = parseInt(svg.style("width"));

        //console.log (width);
        let parseTime = d3.timeParse("%Y-%m-%d");

        // Create scale
        let step = 40; // Pro zeile, Jahr

        let min = d3.min(data.list.map((d) => { return d.year }));
        let max = d3.max(data.list.map((d) => { return d.year }));


        // Anzahl Jahre
        let height = 600;
        svg.attr("height", height);

        /*var xScaleLinearLeft = d3.scaleLinear()
            .domain([55, 165])
            .range([offset, width - margin.right - 20]);
        var y_axis = d3.axisTop()
            .scale(xScaleLinearLeft);*/

        //Append group and insert axis

        // X Axis
        let xScale = d3.scaleLinear()
            .domain([min, max])
            .range([margin.left, width - margin.right]);
        let xAxis = d3.axisBottom(xScale);
        svg.append("g")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .attr("class", "axes")
            .call(xAxis.tickFormat((x) => { return x + "" }).ticks(data.list.length / 2 < 10 ? 10 : data.list.length / 2)); // Labels
        let xGrid = svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(data.list.length));

        // Y Axis
        var yScale = d3.scaleLinear()
            //.domain([d3.min(data.list.map((d) => { return d.days })), d3.max(data.list.map((d) => { return d.days }))])
            .domain([0, d3.max(data.list.map((d) => { return d.days }))])
            .range([height - margin.bottom, margin.top]);
        let yAxis = d3.axisLeft(yScale);
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "axes")
            .call(yAxis.tickFormat((x) => { return x + " " + t("days") }))
        let yGrid = svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .attr("class", "gridline")
            .call(yAxis.tickSize(-width + margin.left + margin.right, 0).tickFormat("").ticks(20));
        /*svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .call(yAxis.tickFormat((x) => { return x + " mm" }));;*/

        //Append group and insert axis
        /*svg.append("g")
            .attr("transform", "translate(" + margin.left + ", " + margin.top+ ")")
            .call(y_axis.tickFormat((x) => { return x }));*/

        let clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        // Add the line current year
        clip.append("path")
            .datum(data.list)
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x(function (d, i) { return xScale(d.year) })
                .y(function (d) { return yScale(d.days) })
                .defined(((d) => { return d.days !== null; }))
            )


        // Add the line
        svg.selectAll("circles")
            .data(data.list)
            .enter()
            .append("circle")
            .attr("fill", "white")
            .attr("stroke", "orange")
            .attr("cx", (d, i) => { return xScale(d.year) })
            .attr("cy", (d) => { return yScale(d.days) })
            .attr("r", 3)
            .attr("stroke-width", 2.5)
            .attr('display', (d) => { return d.days === null ? 'none' : '' });

        let learn = [];
        data.list.map((d) => { if (d.days !== null) learn.push([d.year, d.days]) })
        const reg = ss.linearRegression(learn);
        let lm = ss.linearRegressionLine(reg);

        clip.append("path")
            .datum(data.list)
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d, i) { return xScale(d.year) })
                .y(function (d) { return yScale(lm(d.year)) })
            )

        xlabel(svg, t("years"), width / 2, height - 5);
        ylabel(svg, t("days"), -height * 0.5, 15);

        logo(svg);

        //bordersH (svg, ["", ""], 260, 70, 0);
        //addAreaH (svg, 0, "orange", t("dry"))

        //let colors = ['rgba(255,255,255,1)', '#084594', '#000000'];
        //label_mid (svg, colors, 0, 20, 80, "mm", width - 50, 30);

        //addAreaH (svg, 1, "#084594", t("rain"))

    }

    const handleChange = (event, newValue) => {
        setPeriod(newValue);
    };

    function valuetext(value) {
        return `${value}°C`;
    }

    const marks = [
        {
            value: 7,
            label: '7',
        },
        {
            value: 14,
            label: '14',
        },
        {
            value: 21,
            label: '21',
        },
        {
            value: 28,
            label: '28',
        },
        {
            value: 35,
            label: '35',
        },
        {
            value: 42,
            label: '42',
        },
        {
            value: 49,
            label: '49',
        },
    ];

    /*const onLevelChange = ((event) => {

        //if (event.target.value < 0) event.target.value = 0;
        let l = event.target.value;

        //console.log (l);

        setThreshold(l);
    })*/

    const up = (() => {
        setThreshold(threshold => threshold + 0.5);
    })

    const down = (() => {
        if (threshold > 0)
            setThreshold(threshold => threshold - 0.5);
    })

    const onRainClicked = ((event) => {
        console.log(!rain)
        setRain(!rain);
        //let svg = d3.selectAll("#svgchart");
        //console.log (rain, rain === true)
        //rain === false ? svg.select("#rain_block").attr('display', null) : svg.selectAll("#rain_block").attr('display', "none");
    })

    let c = ["aufbruch", "mausohr", "greenknospen", "redknospen", "bloomstart", "bloom", "bloomend", "tstadium", "erntebeginn"];

    const handleSelector1 = (event) => {
        setCombo1(event.target.value);
    };

    const handleSelector2 = (event) => {
        setCombo2(event.target.value);
    };

    const handleSelector = (event) => {
        setVariety(event.target.value);
    };

    const action = (
        <React.Fragment>
        </React.Fragment>
    )

    return (
        <>
            <div className="stagearea grid">

                <Title text={t("phenology_compare")} />

                <StackRow text={t("station")} component={<StationButton />} />

                <Stack spacing={0}>

                    <Item>
                        <ComboBox
                            title={"variety"}
                            value={variety} items={["Golden Delicious", "Cripps Pink"]}
                            callback={handleSelector} />
                    </Item>

                    <Item>
                        <ComboBox
                            title={"stadium_from"}
                            value={combo1} items={c}
                            callback={handleSelector1} />
                    </Item>

                    <Item>
                        <ComboBox
                            title={"stadium_to"}
                            value={combo2} items={c}
                            callback={handleSelector2} />
                    </Item>

                    <Item>
                        {data !== undefined && <InfoBox title="minimum" value={data.min} unit={t("days")} />}
                    </Item>
                    <Item>
                        {data !== undefined && <InfoBox title="maximum" value={data.max} unit={t("days")} />}
                    </Item>
                    <Item>
                        {data !== undefined && <InfoBox title="average" value={data.avg} unit={t("days")} />}
                    </Item>

                </Stack>

                <div id="svgchart">
                    {data !== undefined && data.list.length > 0
                        ?
                        <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
                        :
                        <div>No data available</div>
                    }
                </div>

                <Grid container flexDirection={"row"}>

                    <Grid item className="p-2" flexGrow={1}>
                        <InfoButton
                            de={""}
                            it={""}
                            en={""}
                        />
                    </Grid>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("dry")} />
                    </Grid>

                    <Grid item className="p-2">
                        <ExcelButton callback={downloadExcel} />
                    </Grid>

                </Grid>

                <Snackbar
                open={open}
                autoHideDuration={6000}
                message="Downloading ..."
                action={action}
            />
            </div>
        </>
    );

}
