// Graph
import * as d3 from "d3";

import { useTranslation } from 'react-i18next';

import { useState, useEffect, useContext } from "react";
//import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { format } from 'date-fns'
//import { ButtonGroup, Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { GraphContext } from "../GraphContext";
import { preferences } from "../../preferences";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import SensorComboBox from '../../components/SensorComboBox';

import PropTypes from 'prop-types';

import Title from "../../components/Title";

import StackRow from "../../components/StackRow";

// Main part with time buttons ans graph

import StationButton from "../../components/StationButton";

import { TabPanel } from "../../components/TabPanel";

import { Map } from "./parts/Map";
import { MapDev } from "./parts/MapDev";

export const HeatmapContent = () => {

	const { t } = useTranslation();

	const [parameter, setParameter] = useContext(GraphContext);
	const [station, setStation] = useState({ sensors: [] });
	const [value, setValue] = useState(0);


	const [sensor, setSensor] = useState(1);
	const [sensors, setSensors] = useState();
	const [sensorstring, setSensorstring] = useState(); // "airtemp2m"

	const [url, setUrl] = useState("");

	const Item = styled(Paper)(({ theme }) => ({

		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	}));

	useEffect(() => {

		{/*  */ }
		let par = { parameter }
		let query = preferences.address + "/rest/station.php?id=" + par.parameter.station;

		d3.json(query).then((data) => {
			setStation(data);
		})

	}, [parameter])

	useEffect(() => {


	}, [station])


	useEffect(() => {

		let station = parameter.station;
		var url = preferences.address + '/rest/sensors.php?station=' + station;

		fetch(url)
			.then((response) => response.json())
			.then((data) => {
				data.map((sensor) => {
					sensor.main = true;
					sensor.min = false;
					sensor.max = false;
					return 0;
				})

				setSensors(data);
				setSensorstring("airtemp2m");
				setSensor(2);
			});
	}, [parameter])

	TabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired,
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};


	const handleSensor = (event) => {

		//let station = parameter.station;
		let index = event.target.value - 1;

		setSensor(event.target.value);
		setSensorstring(sensors[index].cat);
	}

	return (


		<>
			<div className="stagearea grid p-2">

				<Title text={t("seasons")} />

				<Stack spacing={0}>
					{/* <Title text="Darstellung Sensoren" /> */}
					{/* import StackRow from "../../../components/StackRow"; */}
					<StackRow text={t("station")} component={<StationButton />} />

					<Item>

						<SensorComboBox
							title={"sensor"}
							value={sensor} items={sensors}
							callback={handleSensor} />
					</Item>

				</Stack>


				<Box sx={{ width: '100%' }}>

					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value}
							onChange={handleChange}
							variant="scrollable"
							aria-label="basic tabs example"
						>
							<Tab label={t("mittelwerte")} {...a11yProps(0)} />
							<Tab label={t("abweichungen")} {...a11yProps(1)} />
						</Tabs>
					</Box>

					<TabPanel value={value} index={0}>
						<Map id={sensor} sensor={sensorstring} />
					</TabPanel>

					<TabPanel value={value} index={1}>
						<MapDev id={sensor} sensor={sensorstring} />
					</TabPanel>
				</Box>

				<div className="flex-row p-4 expand">
				</div>

				<div id="pdf">
				</div>


			</div>

		</>

	);
}
