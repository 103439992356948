import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { getCurrentYear } from '../../js/datetime';

import { GraphContext } from "../GraphContext";
import "../../styles.css";
import { saveAs } from 'file-saver';

import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import DatePicker from '../../components/DatePicker';
import ComboBox from '../../components/ComboBox';
import RefreshButton from '../../components/RefreshButton';

import { borders, addLine } from '../../components/d3/legend/legend';
import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#406060',
        color: theme.palette.common.white,
        fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border                                                                                                                 
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

/* Preselect year */
const calculateYear = (() => {
    let d = new Date();
    let cy = getCurrentYear()
    if (d.getMonth() < 2)
        return cy - 1;
    else
        return cy;
})

const currentYear = calculateYear();

export const Frostberegnung = () => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();


    /* Track page view */
    useEffect(() => {
        trackPageView({
            documentTitle: 'Frostschutzberegnung', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])



    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs().year(currentYear));

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);


    const [year, setYear] = useState(currentYear);
    const [time, setTime] = useState(dayjs().month() < 2 ? 2 : 1);

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    /* Callback Year field */
    const handleSelector = (event) => {
        setFromdjs(event);
        let date = event.$d;
        let y = 1900 + date.getYear();
        setYear(y);
        if (y < dayjs().year())
            setTime(2)
        else
            setTime(1);
    }


    useEffect(() => {
        d3.json(preferences.address + "/query/climate/temperature/frost/get.php?station=" + parameter.station + "&year=" + year + "&time=" + time)
            .then((data) => {
                setData(data);
            });
    }, [parameter, time, year, i18n.language]);

    const refresh = (() => {
        d3.json(preferences.address + "/query/climate/temperature/frost/get.php?station=" + parameter.station + "&year=" + year + "&time=" + time)
            .then((data) => {
                setData(data);
            });
    });


    useEffect(() => {

        const svg = d3.select(svgRef.current);
        if (data !== undefined)
            draw(svg);

    }, [resize, data, i18n.language]);

    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/temperature/margins/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }


    var width = 0;

    const margin = {
        top: 10,
        right: 10,
        bottom: 50,
        left: 70,
        height1: 150,
        height2: 150,
        height3: 150,
        height4: 150,
        gap: 40,
    }; // margin of chart

    var xScale;
    var yScaleT;
    var yScaleT2;
    var yScaleH;
    var yScaleW;
    let height = margin.top + margin.bottom + margin.height1 + margin.height2 + margin.height3 + margin.height4 + margin.gap * 3 + 30;
    let brush;
    let clip;

    //var yGrid;
    //var yAxis

    var xGrid;
    var xAxis;

    function draw(svg) {

        var ID_Time = {
            "dateTime": "%d %B %Y",
            "date": "%d.%m.%Y",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        // setup SVG
        svg
            .attr("viewBox", [0, 0, svg.style('width'), svg.style('height')])
            .style("overflow", "visible")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        //let start = data.dry !== undefined ? d3.min(data.dry.map((d) => {d.date})) : year+"-03-01";
        //let end = data.dry !== undefined ? d3.max(data.dry.map((d) => {d.date})) : year+"-05-31";
        //let start = d3.timeParse("%Y-%m-%d %H:%M:%S")(data.from);
        //let start = d3.timeParse("%Y-%m-%d %H:%M:%S")(data.from);
        //let end = d3.timeParse("%Y-%m-%d %H:%M:%S")(data.to);

        let start = data.from;
        let end = data.to;

        let minT = d3.min([d3.min(data.dry.map((d) => { return d.val })), d3.min(data.wet.map((d) => { return d.val }))]);
        let maxT = d3.max([d3.max(data.dry.map((d) => { return d.val })), d3.max(data.wet.map((d) => { return d.val }))]);

        let minH = d3.min(data.humidity.map((d) => { return d.val }));
        let maxH = d3.max(data.humidity.map((d) => { return d.val }));

        let minW = d3.min(data.wind.map((d) => { return d.val }));
        let maxW = d3.max(data.wind.map((d) => { return d.val }));

        // Getting width
        width = parseInt(svg.style('width'));

        svg
            .attr("width", width)
            .attr("height", height);

        // Create scale
        xScale = d3.scaleTime()
            .domain([d3.timeParse("%Y-%m-%d %H:%M:%S")(start), d3.timeParse("%Y-%m-%d %H:%M:%S")(end)])
            .range([margin.left, width - margin.right]);

        //Append group and insert axis
        xAxis = svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom + 10) + ")")
            .call(d3.axisBottom(xScale));

        // Grid vertical
        let bottomScale = d3.axisBottom(xScale);
        xGrid = svg.append("g")
            .attr('id', 'xgrid')
            .attr('transform', 'translate(0 ,' + (height - margin.bottom + 10) + ')')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .attr("class", "axes")
            .call(bottomScale.tickSize(-height + margin.top + margin.bottom - 10, 0).tickFormat(""));

        // Create scale
        yScaleT = d3.scaleLinear()
            .domain([minT, maxT])
            .range([margin.top + margin.height1, margin.top]);
        // Add scales to axis
        d3.axisLeft()
            .scale(yScaleT);

        // Create scale
        yScaleT2 = d3.scaleLinear()
            .domain([-5, 5])
            .range([margin.top + margin.height1 + margin.gap + margin.height2, margin.top + margin.height1 + margin.gap]);
        // Add scales to axis
        d3.axisLeft()
            .scale(yScaleT2);

        // Create scale
        yScaleH = d3.scaleLinear()
            .domain([minH, maxH])
            .range([margin.top + margin.height1 + margin.gap + margin.height2 + margin.gap + margin.height3, margin.top + margin.height1 + margin.gap + margin.height2 + margin.gap]);
        // Add scales to axis
        d3.axisLeft()
            .scale(yScaleH);

        // Create scale
        yScaleW = d3.scaleLinear()
            .domain([minW, maxW])
            .range([margin.top + margin.height1 + margin.height2 + margin.height3 + margin.height4 + margin.gap * 3, margin.top + margin.height1 + margin.height2 + margin.height3 + margin.gap * 3]);
        // Add scales to axis
        d3.axisLeft()
            .scale(yScaleW);


        // brush
        brush = d3.brushX()
            .extent([[margin.left, margin.top], [width - margin.right, height - margin.bottom]])
            .on("end", updateChart); // brushended
        svg.append("g")
            .attr('class', 'brush')
            .call(brush);

        // Clip
        clip = svg.append('g')
            .attr("clip-path", "url(#clip)");

        svg.append("defs")
            .append("svg:clipPath")
            .attr('id', 'clip')
            .append("svg:rect")
            .attr("x", margin.left)
            .attr("y", 0)
            .attr("width", width - margin.left - margin.right)
            .attr("height", height);


        //Append group and insert axis
        let yAxis = d3.axisLeft(yScaleT)
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 00)")
            .call(yAxis.ticks(10, '%0.1f').tickFormat((x) => { return x + " °C" }));
        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' , 0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(yAxis.tickSize(-width + margin.right + margin.left, 0).tickFormat(""));

        let yAxis2 = d3.axisLeft(yScaleT2)
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 00)")
            .call(yAxis2.ticks(10, '%0.1f').tickFormat((x) => { return x + " °C" }));
        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' , 0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(yAxis2.tickSize(-width + margin.right + margin.left, 0).tickFormat(""));

        //Append group and insert axis
        let yAxisH = d3.axisLeft(yScaleH)
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 00)")
            .call(yAxisH.ticks(10, '%0.1f').tickFormat((x) => { return x + " %rF" }));
        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' , 0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(yAxisH.tickSize(-width + margin.right + margin.left, 0).tickFormat(""));

        //Append group and insert axis
        let yAxisW = d3.axisLeft(yScaleW)
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 00)")
            .call(yAxisW.ticks(10, '%0.1f').tickFormat((x) => { return x + " m/s" }));
        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' , 0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(yAxisW.tickSize(-width + margin.right + margin.left, 0).tickFormat(""));


        let lines = clip.append('g');

	/* Add zero line
        lines.append("line")
            .attr('id', 'zeroLine')
            .attr('class', "line")
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 1.0)
	    .attr("x1", margin.left)
	    .attr("y1", yScale(0))
	    .attr("x2", width - margin.right)
	    .attr("y2", yScale(0));*/

        // line max
        lines.append("path")
            .attr('id', 'path1')
            .datum(data.dry)
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y(function (d) { return yScaleT(d.val) })
            )

        lines.append("path")
            .attr('id', 'path2')
            .datum(data.wet)
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y(function (d) { return yScaleT(d.val) })
            )

        lines.append("path")
            .attr('id', 'path2a')
            .datum(data.wet)
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y(function (d) { return yScaleT2(0) })
            )

        // line max D2
        //clipPath
        lines.append('clipPath')
            .attr('id', 'clippath')
            .append('rect')
            .attr('x', xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(start)))
            .attr('y', yScaleT2(5))
            .attr('height', yScaleT2(-5) - yScaleT2(5))
            .attr('width', xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(end)))

        lines.append("path")
            .attr('id', 'path1_')
            .datum(data.dry)
            .attr('clip-path', 'url(#clippath)')
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y(function (d) { return yScaleT2(d.val) })
                //.defined (function (d) { return d.val > -5 && d.val < 5 })
            )

        lines.append("path")
            .attr('id', 'path2_')
            .datum(data.wet)
            .attr('clip-path', 'url(#clippath)')
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y(function (d) { return yScaleT2(d.val) })
                //.defined (function (d) { return d.val > -5 && d.val < 5 })
            )

        // Feuchtigkeit
        lines.append("path")
            .attr('id', 'path3')
            .datum(data.humidity)
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y(function (d) { return yScaleH(d.val) })
            )

        lines.append("path")
            .attr('id', 'path4')
            .datum(data.wind)
            .attr("fill", "none")
            .attr("stroke", "brown")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y(function (d) { return yScaleW(d.val) })
            )

        svg.append('line')
            .attr('id', 'cursorLine')
            .attr('stroke', 'grey')
            .attr('display', null);

        var dotgroup = svg.append("g").attr('id', 'dotgroup');

        /*  actual */
        dotgroup.append("circle")
            .attr('id', 'dot1')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot2')
            .style('display', 'none')
            .style("stroke", "#8c564b")
            .style("fill", 'black')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot3')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        dotgroup.append("circle")
            .attr('id', 'dot4')
            .style('display', 'none')
            .style("stroke", "grey")
            .style("fill", 'black')
            .attr("r", 3);

        var tooltip = svg.append("g")
            .attr('id', 'tooltip')
            .style("pointer-events", "none");

        tooltip.append("path")
            .attr('id', 'pathtool')
            .data([,])
            .attr("class", "shadow")
            .attr("fill", "white")
            .attr("stroke", 'black')
            .attr("stroke-width", 1.5)
            .attr("stroke-linejoin", "round")
            .attr("stroke-linecap", "round");

        var tooltiptext = tooltip.append("text")
            .attr('id', 'tooltiptext')
            .style("pointer-events", "none");

        // Date
        tooltiptext
            .append("tspan")
            .attr('id', 'date')
            .attr("x", 15)
            .attr("y", 0)
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value1')
            .attr("x", 25)
            .attr("y", 20)
            .attr("stroke", 'red')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value2')
            .attr("x", 25)
            .attr("y", 40)
            .attr("stroke", 'steelblue')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value3')
            .attr("x", 25)
            .attr("y", 60)
            .attr("stroke", 'green')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        tooltiptext
            .append("tspan")
            .attr('id', 'value4')
            .attr("x", 25)
            .attr("y", 80)
            .attr("stroke", 'brown')
            .attr('text-anchor', 'start')
            .attr('font-size', 'smaller');

        xlabel(svg, t("date"), width / 2, height - 5);
        ylabel(svg, t("temperature"), -height * 0.25, 15);
        ylabel(svg, t("humidity"), -height * 0.6, 15);
        ylabel(svg, t("windspeed"), -height * 0.85, 15);

        logo(svg);
    }


    function pointermoved(event) {

        const X1 = d3.map(data.dry, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date));
        const X2 = d3.map(data.humidity, d => d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date));
        const I1 = d3.map(data.dry, (_, i) => i);
        const I2 = d3.map(data.humidity, (_, i) => i);
        const i1 = d3.bisectCenter(X1, xScale.invert(d3.pointer(event)[0] - 5));
        const i2 = d3.bisectCenter(X2, xScale.invert(d3.pointer(event)[0] - 5));

        // cursorline
        const svg = d3.select(svgRef.current);
        svg.selectAll('#cursorLine')
            .style('display', null)
            .attr('x1', d3.pointer(event)[0] - 5)
            .attr('x2', d3.pointer(event)[0] - 5)
            .attr('y1', margin.top)
            .attr('y2', height - margin.bottom + 10);

        try {
            svg
                .selectAll('#dot1')
                .style('display', null)
                .attr("cx", d3.pointer(event)[0] - 5)
                .attr("cy", + yScaleT(data.dry[i1].val));
        } catch {}

        try {
        svg
            .selectAll('#dot2')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScaleT(data.wet[i1].val));
        } catch {}

        try {
        svg
            .selectAll('#dot3')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScaleH(data.humidity[i2].val));
        } catch {}

        try {
        svg
            .selectAll('#dot4')
            .style('display', null)
            .attr("cx", d3.pointer(event)[0] - 5)
            .attr("cy", + yScaleW(data.wind[i2].val));
        } catch {}

        // Textbox with values
        var tooltiptext = svg.select("#tooltiptext");
        tooltiptext.attr("transform", `translate(${0},${30 - 0})`);

        const { x, y, width: w, height: h } = tooltiptext.node().getBBox();

        var path = svg.select("#pathtool");

        try {
        if (xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(data.dry[i1].date)) === width - margin.right) {
            svg.select("#tooltip")
                .style("display", 'none');
        }
        } catch {}

        try {
        let rb = xScale(X1[i1]) > width - 160 ? width - 160 : xScale(X1[i1]);
        svg.select("#tooltip")
            .style("display", null)
            .attr("text-anchor", "middle")
            .attr("transform", `translate(${rb},0)`);
        } catch {}

        path
            .style('display', null)
            .transition()
            .duration(200)
            .attr('d', `M 0 20 l 10 -10 H${w + 20} V${h + 25} H10 V25 Z`);

        try {
        svg.select("#date")
            .transition()
            .duration(200)
            .text(data.dry[i1].date);
        } catch {}

        try {
        svg.select("#value1")
            .transition()
            .duration(200)
            .text(Math.round(data.dry[I1[i1]].val * 10) / 10 + " °C");
        } catch {}
        try {
        svg.select("#value2")
            .transition()
            .duration(200)
            .text(Math.round(data.wet[I1[i1]].val * 10) / 10 + " °C");
        } catch {}
        try {
        svg.select("#value3")
            .transition()
            .duration(200)
            .text(Math.round(data.humidity[I2[i2]].val * 10) / 10 + " %rH");
        } catch {}
        try {
        svg.select("#value4")
            .transition()
            .duration(200)
            .text(Math.round(data.wind[I2[i2]].val * 10) / 10 + " m/s");
        } catch {}

        let x_pos = d3.pointer(event)[0];

        if (x_pos < 400) {
            svg.selectAll('#legend').attr('opacity', 0.1)
        }
        else {
            svg.selectAll('#legend').attr('opacity', 1)
        }

    }

    function pointerleft() {
        const svg = d3.select(svgRef.current);

        svg.selectAll('#cursorLine')
            .style('display', 'none');

        svg
            .selectAll('#dot1')
            .style('display', 'none')
        svg
            .selectAll('#dot2')
            .style('display', 'none')
        svg
            .selectAll('#dot3')
            .style('display', 'none')
        svg
            .selectAll('#dot4')
            .style('display', 'none')

        svg.select("#tooltip")
            .style("display", 'none');

        svg.selectAll('#legend').attr('opacity', 1)
    }

    var idleTimeout
    function idled() { idleTimeout = null; }

    function updateChart(event) {

        const svg = d3.select(svgRef.current);
        var extentX = event.selection

        if (!extentX) {
            if (!idleTimeout) return idleTimeout = setTimeout(idled, 350); // waiting a bit

            let min = d3.min(data.dry.map((d) => { return d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date) }));
            let max = d3.max(data.dry.map((d) => { return d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date) }));

            xScale.domain([min, max]);
        }
        else {
            xScale.domain([xScale.invert(extentX[0]), xScale.invert(extentX[1])]);

            // This remove the grey brush area as soon as the selection has been done
            svg.select(".brush").call(brush.move, null)
        }

        // Bottom
        xAxis.transition()
            .duration(1000)
            .call(d3.axisBottom(xScale));

        // Bottom grid
        xGrid.transition()
            .duration(1000)
            .attr('transform', 'translate(0 ,' + (height - margin.bottom + 10) + ')')
            .call(d3.axisBottom(xScale).tickSize(-height + margin.top + margin.bottom - 10, 0).tickFormat(""));

        // Linie trockentemperatur
        svg.selectAll("#path1")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y((d) => { return yScaleT(d.val) }));

        // Linie Nasstemperatur
        svg.selectAll("#path2")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y((d) => { return yScaleT(d.val) }));

        // 2. Diagramm
        svg.selectAll("#path1_")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y((d) => { return yScaleT2(d.val) }))
        //.defined (function (d) { return d.val > -5 && d.val < 5 });
        svg.selectAll("#path2_")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y((d) => { return yScaleT2(d.val) }));
        //.defined (function (d) { return d.val > -5 && d.val < 5 });

        // Feuchtigkeit
        svg.selectAll("#path3")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y((d) => { return yScaleH(d.val) }));

        // Wind
        svg.selectAll("#path4")
            .transition()
            .duration(1000)
            .attr("d", d3.line()
                .x((d) => { return xScale(d3.timeParse("%Y-%m-%d %H:%M:%S")(d.date)) })
                .y((d) => { return yScaleW(d.val) }));
    }

    const timeChange = (events) => {
        console.log(events.target);
        setTime(events.target.value);
    }

    return (
        <>
            <Stack spacing={1}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                        mindate="2022"
                    />
                </Item>

                <Item>
                    <ComboBox title={t("abschnitt")} items={['24h', '3 Monate']} callback={timeChange} value={time} />
                </Item>

            </Stack>


            {data !== undefined && data.wet.length > 0 &&
                <TableContainer component={Paper}>
                    <Grid sx={{ margin: '10px' }}>
                        <Table size="small" aria-label="station table">
                            <TableHead>
                            </TableHead>
                            <TableBody>


                                <StyledTableRow onClick={refresh}>
                                    <StyledTableCell component="th" scope="row">
                                        Trockentemperatur
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.dry.length > 0 && data.dry[data.dry.length - 1].val} °C
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.dry.length > 0 && data.dry[data.dry.length - 1].date}
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Feuchttemperatur
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.wet.length > 0 && data.wet[data.wet.length - 1].val} °C
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.wet.length > 0 && data.wet[data.wet.length - 1].date}
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Luftfeuchtigkeit
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.humidity.length > 0 && data.humidity[data.humidity.length - 1].val} %rH
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.humidity.length > 0 && data.humidity[data.humidity.length - 1].date}
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Windgeschwindigkeit
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.wind.length > 0 && data.wind[data.wind.length - 1].val} m/s
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {data.wind.length > 0 && data.wind[data.wind.length - 1].date}
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </TableContainer>
            }

            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

	    <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
			 de={"Die Grafik der Frosttemperatur ermöglicht eine detaillierte Darstellung von Temperatur, relativer Luftfeuchtigkeit und Windgeschwindigkeit. Im oberen Bereich sind die Temperaturkurven für den ausgewählten Zeitraum angegeben. Die rote Linie repräsentiert die Trockentemperatur und die blaue Linie die Feuchttemperatur. Der untere Bereich der Grafik stellt eine Detail-Ansicht des kritischen Temperaturbereichs nahe dem Gefrierpunkt dar. Im mittleren Teil der Grafik kann man den Verlauf der relativen Luftfeuchtigkeit (grün) ablesen, während im unteren Bereich die Windgeschwindigkeit (braun) dargestellt wird. Diese Informationen sind wichtig zur Abschätzung der Spätfrostgefahr. Die Werte werden im Frühjahr von März bis Mai aufgezeichnet. Der darzustellende Zeitraum kann aus der Drop-down-Liste oben rechts ausgewählt werden."}
			 it={"Il grafico della temperatura del gelo fornisce una visualizzazione dettagliata della temperatura, dell'umidità relativa e della velocità del vento. Le curve di temperatura per il periodo selezionato sono mostrate in alto. La linea rossa rappresenta la temperatura del bulbo secco e quella blu la temperatura del bulbo umido. La parte inferiore del grafico mostra una visione dettagliata dell'intervallo di temperatura critico vicino al punto di congelamento. La parte centrale del grafico mostra l'umidità relativa (verde), mentre la parte inferiore mostra la velocità del vento (marrone). Queste informazioni sono importanti per stimare il rischio di gelate tardive. I valori vengono registrati in primavera, da marzo a maggio. Il periodo da visualizzare può essere selezionato dall'elenco a discesa in alto a destra."}
			 en={"The frost temperature graph provides a detailed display of temperature, relative humidity and wind speed. The temperature curves for the selected period are shown in the upper section. The red line represents the dry bulb temperature and the blue line the wet bulb temperature. The lower part of the graph shows a detailed view of the critical temperature range near the freezing point. The middle section of the graph shows the relative humidity (green), while the lower section shows the wind speed (brown). This information is important for estimating the risk of late spring frost. The values are recorded in spring from March to May at the Laimburg site only. The period to be displayed can be selected from the drop-down list at the top right. "}
		    />
                </Grid>


                <Grid item className="p-2">
                    <RefreshButton callback={refresh} />
                </Grid>
            </Grid>

        </>
    );

}
