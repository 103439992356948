import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { saveAs } from 'file-saver';

import { getCurrentYear, getCurrentMonth } from '../../js/datetime';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { y_axis, x_axis, y_array, xy_matrix, label } from "../../components/Calendar/calendar";

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const RainMap = ({ description, list }) => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter, setParameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        var r = getWidth();
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setYear(1900 + date.getYear());
    }

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Regen Lngjährige Mittelwerte', // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location])


    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg);

    }, [resize, data]);

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/rain/map/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [year]);

    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/map/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }
    function draw(svg) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 40,
            right: 50,
            bottom: 30,
            left: 50,
        }; // margin of chart

        let width = parseInt(svg.style("width")) > 1000 ? 1000 : parseInt(svg.style("width"));


        let max = d3.max(data.years.map((d) => { return d.year }));
        let min = d3.min(data.years.map((d) => { return d.year }));

        const cellsize = 20;
        const cellborder = 2;
        const gap = 0;
        const x_offset = width * 0.04 + margin.left;
        const y_offset = 5;

        const sec_left = (width - 70) * 0.2;
        const cellwidth = sec_left - margin.left;

        const sec_right = (width - 70) * 0.8;
        let height = (max - min) * cellsize + margin.top + margin.bottom;
        if (height < 200) height = 200;

        svg
            .attr("width", width)
            .attr("height", height);

        let colors = ['orange', 'orange', 'green', 'darkgreen'];
        let colors2 = ['orange', 'lightgreen', 'darkgreen'];

        const colorFn = d3.scaleLinear()
            .domain([0, 0, 200, 300])
            .range(colors)
            .interpolate(d3.interpolateCubehelix);

        const mean = data.long.value / data.long.count;
        console.log(mean, data.long.value, data.long.count);

        const colorFnYear = d3.scaleLinear()
            .domain([0, mean - 250, mean, mean + 400])
            .range(colors)
            .interpolate(d3.interpolateCubehelix);


        y_axis(svg, data, y_offset, cellsize, margin.left, margin.top);

        let f1 = (d) => { return Math.round((d.val) * 10) / 10 + "mm" };
        let c1 = (d) => colorFnYear(d.val);
        y_array(svg, data, cellwidth, cellsize,
            cellborder, 0, margin.top,
            55, y_offset, colorFn,
            c1, f1);

        // Create scale
        let xScale = x_axis(svg, sec_left, width - (margin.right + 70), 20, margin.top, t);

        // months
        let f2 = (d) => { return Math.round((d.val) * 10) / 10 + "mm" };
        let c2 = (d, i) => colorFn(d.val);
        xy_matrix(svg, data, xScale, cellsize, cellborder,
            gap, max, 20, margin.top, x_offset, colorFn, c2, f2);

        label(svg, colors, 0, 300, "mm", width - 50, 30);

        xlabel(svg, t("months"), width * 0.55, 15);
        xlabel(svg, t("years"), width * 0.1, 15);
        logo(svg);
    }



    return (
        <>
            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Die langjährige Niederschlagsgrafik zeigt die Gesamtmenge an Niederschlag pro Jahr und pro Monat seit Beginn der Aufzeichnungen an der gewählten Wetterstation. Die Jahressummen werden in der ersten Spalte und die Monatssummen in der Kalenderansicht unter dem Bereich 'Monate' dargestellt. Die genauen Werte können durch Bewegen des Mauszeigers abgelesen werden. Die Niederschlagsmengen sind zudem durch eine Farbskala kategorisiert."}
                        it={"Il grafico delle precipitazioni a lungo termine mostra la quantità totale di precipitazioni per anno e per mese dall'inizio delle registrazioni nella stazione meteorologica selezionata. I totali annuali sono riportati nella prima colonna e i totali mensili nella vista calendario sotto la sezione “Mesi”. I valori esatti possono essere letti spostando il puntatore del mouse. Le quantità di precipitazioni sono inoltre classificate in base a una scala di colori."}
                        en={"The long-term precipitation graph shows the total amount of precipitation per year and per month since the start of recordings at the selected weather station. The annual totals are shown in the first column and the monthly totals in the calendar view under the “Months” section. The exact values can be read by moving the mouse pointer. The precipitation amounts are also categorized using a colour scale. The graph shows the monthly precipitation deviations since the start of weather recording at the selected weather station. These deviations are calculated in comparison to the long-term average, which is made up of the precipitation data from the previous 30 years. The exact deviation values can be called up by clicking on the corresponding field. In addition, the various deviation categories are shown using a color scale."}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("rain_map")} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>

            </Grid>

        </>
    );

}
