import { useState, useContext, useEffect, useRef, Fragment } from "react";

import Snackbar from "@mui/material/Snackbar";
import { getCurrentYear, getCurrentMonth } from '../../../js/datetime';

import { GraphContext } from "../../GraphContext";

import dayjs from 'dayjs';

import { saveAs } from 'file-saver';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { preferences } from "../../../preferences";
import Grid from "@mui/material/Grid";
import ExcelButton from '../../../components/ExcelButton';
import PDFButton from '../../../components/PDFButton';
import Title from '../../../components/Title';

import { OverviewTable } from './table/OverviewTable';

import DatePicker from '../../../components/DatePicker';
import StationButton from "../../../components/StationButton";
import StackRow from "../../../components/StackRow";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import { useTranslation } from "react-i18next";

export const Monatsreportview = () => {

    const { t, i18n } = useTranslation();

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const [parameter, setParameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [year, setYear] = useState(getCurrentYear());
    const [month, setMonth] = useState(getCurrentMonth());

    const [open, setOpen] = useState(false);
    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    // Track page view
    useEffect(() => {
        trackPageView({
            documentTitle: 'Monatsübersicht' + year + "-" + month, // optional
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])


    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;

        if (year !== 1900 + date.getYear())
            setYear(1900 + date.getYear());

        if (month !== date.getMonth() + 1)
            setMonth(date.getMonth() + 1);
    }

    const exportExcel = (event) => {



        let station = parameter.station;

        var url = preferences.address + '/query/report/export/month/overview/excel.php?station=' + station + "&year=" + year + "&month=" + month + "&lang=" + i18n.language;
        setOpen(true);

        let filename = '';
        fetch(url)
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                setOpen(false);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    const exportPDF = (event) => {

        let station = parameter.station;

        var url = preferences.address + '/query/report/export/month/overview/pdf.php?station=' + station + "&year=" + year + "&month=" + month + "&lang=" + i18n.language;
        setOpen(true);

        let filename = '';
        fetch(url)
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Report.pdf' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/pdf;charset=utf-8" });
                saveAs(file);
                setOpen(false);
                trackEvent({
                    category: 'Downloads',
                    action: 'PDF',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    const action = (
        <Fragment>
        </Fragment>
    )

    return (
        <>
            <div className="stagearea grid p-2">

                <Title text={t("monatstabelle")} />
                <Stack spacing={0}>

                    <StackRow text={t("station")} component={<StationButton />} />

                    <Item>
                        <DatePicker
                            title="month"
                            value={fromdjs}
                            callback={handleSelector}
                            view={["month", "year"]}
                            format="MMM YYYY"
                        />
                    </Item>

                </Stack>

                <OverviewTable year={year} month={month}></OverviewTable>

                <Grid container flexDirection={"row-reverse"}>

                    <Grid item className="p-2">
                        <ExcelButton callback={exportExcel} />
                    </Grid>

                    <Grid item className="p-2">
                        <PDFButton callback={exportPDF} />
                    </Grid>

                </Grid>

            </div>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                message="Downloading ..."
                action={action}
            />

        </>
    );
}
