import Typography from '@mui/material/Typography';
import { useTranslation} from 'react-i18next';
export default function Title ({text, level, unit}) {

    const { t, i18n } = useTranslation();

	return (

	<>
		<div className="p-4"
		>
			<Typography 
				variant="h6"
				component="h6"
				color='#006400'
				
				sx={{
					fontWeight: 'bold',
					marginTop: '20px',
					marginBottom: '20px',
					border: '1',
					padding: "10px"
				}}
				>
				<strong>{t(text)} {level !== null && level} {unit !== undefined && "[" + unit +"]" }</strong>
			</Typography>
		</div>
	</>

	);
} 
