import * as d3 from "d3";
import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from "@mui/material/Grid";

import { GraphContext } from "../../../GraphContext";

import { preferences } from "../../../../preferences";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


import { t } from "i18next";

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  }
];

export const TableZS = ({sensor, month, year}) => {

    const { t, i18n } = useTranslation();

    const [ data, setData ] = useState();

    const [ ind, setInd ] = useState();

    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(10);

    const [ parameter, setParameter] = useContext(GraphContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/general.php?station=" + parameter.station + "&sensor=" + sensor + "&year=" + year + "&month=" + month)
            .then((data) => {
                setData(data);
            });

    }, [i18n.language, month, year, parameter]);

    useEffect (() => {
        
        if (data !== undefined )
        {
            setInd ( data.list.findIndex ((item) => item.year === year) );
        }

    }, [data])

    return (
        <>
            {data !== undefined ?
            <Grid item>
                {data.sensor !== null &&
                <TableContainer component={Paper} sx={{ maxWidth: 350 }} >
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={3} sx={{background: "#008051", color: "#fff", fontWeight: "bold"}}>
                                {t(data.sensor.description)}
                                {data.sensor.level !== null && " ("+data.sensor.level+")"}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right">{t("index")}</TableCell>
                            <TableCell align="right">[{data.sensor.unit}] </TableCell>
                            <TableCell align="right"> {t("year")} </TableCell>
                        </TableRow>
                        </TableHead>

                        <TableBody>
                        {(data.list !== undefined) &&
                            data.list.map((row, i) => (
				    <>
				    
                                {(i===0 || ((i - ind) < 3 && (i - ind) > -3) || (i+1) === data.list.length) &&
                                <TableRow
                                key={sensor+i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row" sx={{fontWeight: row.year==year ? "bold" : ""}} >
                                    {i+1}
                                </TableCell>
                                <TableCell sx={{fontWeight: row.year==year ? "bold" : ""}} align="right">{row.val}</TableCell>
                                <TableCell sx={{fontWeight: row.year==year ? "bold" : ""}} align="right">{row.year}</TableCell>
                                </TableRow>
				}

                                { ((i - ind) === 4 || (i - ind) === -4) &&
                                <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row" sx={{fontWeight: row.year==year ? "bold" : ""}} >-</TableCell>
                                <TableCell sx={{fontWeight: row.year==year ? "bold" : ""}} align="right">-</TableCell>
                                <TableCell sx={{fontWeight: row.year==year ? "bold" : ""}} align="right">-</TableCell>
                                </TableRow>
				}
				</>
                            ))
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                }
                </Grid>
                : ""
            }
        </>
    );
}
