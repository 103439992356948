import * as d3 from "d3";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';

import { getCurrentYear, getCurrentMonth } from '../../js/datetime';

import { GraphContext } from "../GraphContext";
import "../../styles.css";

import dayjs from 'dayjs';

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import { xlabel, ylabel, logo } from '../../components/d3/charts/chart';
import { timeformat, getWidth, responsivness, getMonths } from '../../components/d3/d3timeformat';

import DatePicker from '../../components/DatePicker';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const RainBoxplot = ({ description, list }) => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter, setParameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [data, setData] = useState();

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        var r = getWidth();
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setYear(1900 + date.getYear());
    }
    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Regen Boxplot', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location])

    useEffect(() => {

        d3.json(preferences.address + "/query/climate/rain/boxplot/get.php?station=" + parameter.station + "&year=" + year)
            .then((data) => {
                setData(data);
            });
    }, [year]);

    const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/boxplot/excel.php';

        let block = {};
        block.station = parameter.station;
        block.year = year;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
            draw(svg, data);

    }, [resize, data]);

    var width = 0;

    function draw(svg, data) {

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 10,
            right: 10,
            bottom: 40,
            left: 80,
        }; // margin of chart


        let max = 400;//d3.max (data.months.map((d) =>{return d.val}));

        // Getting width
        width = parseInt(svg.style('width'));

        let height = 500;

        svg
            .attr("width", width)
            .attr("height", height);

        // Create scale
        var xScaleBand = d3.scaleBand()
            .domain([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
            .range([0, width - margin.right - margin.left])
            .padding(0.1);

        let months = getMonths(t);

        var xScaleLabel = d3.scaleBand()
            .domain(months.map((d) => { return t(d) }))
            .range([0, width - margin.left - margin.right])
            .padding(0.1);

        // Add scales to axis
        var x_axis = d3.axisBottom()
            .scale(xScaleLabel);

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + (height - margin.bottom) + ")")
            .attr("class", "axes")
            .call(x_axis);

        // Create scale
        var yScale = d3.scaleLinear()
            .domain([0, max])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        var y_axis = d3.axisLeft()
            .scale(yScale);

        //Append group and insert axis
        svg.append("g")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("class", "axes")
            .call(y_axis.tickFormat((x) => { return x + " mm" }));

        // Grid horizontal
        svg.append("g")
            .attr('transform', 'translate(' + margin.left + ' , 0)')
            .style("pointer-events", "none")
            .attr("class", "gridline")
            .call(y_axis.tickSize(-width + margin.left + margin.right, 0).tickFormat(""));

        var subgroups = ['min', 'val', 'max'];

        var color = d3.scaleOrdinal()
            .domain(subgroups)
            .range(['#e41a1c', '#377eb8', '#4daf4a'])

        //stack the data? --> stack per subgroup
        var stackedData = d3.stack()
            .keys(subgroups)
            (data.months)

        let s = xScaleBand.bandwidth() / 2;

        svg
            .selectAll("vertLines")
            .data(data.months)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) })
            .attr("y1", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40);

        svg
            .selectAll("upperLines")
            .data(data.months)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.max(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        svg
            .selectAll("lowerLines")
            .data(data.months)
            .enter()
            .append("line")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) - s / 4 })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + s / 4 })
            .attr("y1", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("y2", function (d) { return yScale(d3.min(d.map((e) => { return e.val }))) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        svg
            .selectAll("boxes")
            .data(data.months)
            .enter()
            .append("rect")
            .attr("transform", "translate(" + (margin.left) + ", 0)")
            .attr("x", function (d, index) { return xScaleBand(index + 1) })
            .attr("y", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.75)) })
            .attr("height", function (d) { return (yScale(d3.quantile(d.map((e) => { return e.val }), 0.25)) - yScale(d3.quantile(d.map((e) => { return e.val }), 0.75))) })
            .attr("width", xScaleBand.bandwidth())
            .attr("stroke", "black")
            .style("fill", "green")
            .attr("rx", 5);

        svg
            .selectAll("horLines")
            .data(data.months)
            .enter()
            .append("line")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("x1", function (d, index) { return xScaleBand(index + 1) })
            .attr("x2", function (d, index) { return xScaleBand(index + 1) + 2 * s })
            .attr("y1", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("y2", function (d) { return yScale(d3.quantile(d.map((e) => { return e.val }), 0.5)) })
            .attr("stroke", "black")
            .attr("stroke-width", 1.5)
            .style("width", 40)

        const l = [];
        data.months.map((e, i) => { e.map((j, k) => { let t = {}; t.month = j.month; t.val = j.val; l.push(t); }) });

        var jitterWidth = 50;
        svg
            .selectAll("points")
            .data(l)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + margin.left + ", 0)")
            .attr("cx", function (d, index) { return xScaleBand(d.month) + xScaleBand.bandwidth() / 2 - jitterWidth / 2 + Math.random() * jitterWidth })
            .attr("cy", function (d, index) { return yScale(d.val) })
            .attr("r", 2)
            .style("fill", "white")
            .attr("stroke", "blue");


        // Add the line current year
        svg.append("path")
            .datum(data.current)
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x(function (d) { return xScaleBand(d.month) })
                .y(function (d) { return yScale(d.val) })
            )
        // Add the line
        svg.selectAll("circles")
            .data(data.current)
            .enter()
            .append("circle")
            .attr("transform", "translate(" + (margin.left + xScaleBand.bandwidth() / 2) + ", 0)")
            .attr("fill", "white")
            .attr("stroke", "orange")
            .attr("cx", function (d) { return xScaleBand(d.month) })
            .attr("cy", function (d) { return yScale(d.val) })
            .attr("r", 4)

        xlabel(svg, t("months"), width / 2, height - 5);
        ylabel(svg, t("rain"), -height * 0.5, 15);
        logo(svg);
    }



    return (
        <>
            <Stack spacing={1}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs}
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

            </Stack>
            <div id="svgchart">
                <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
            </div>

            <Grid container flexDirection={"row"}>

                <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                        de={"Diese Grafik zeigt den Verlauf der monatlichen Niederschläge (orange Linie) des ausgewählten Jahres an.<br> Zum Vergleich gibt ein Boxplot die Streuung und den Median der monatlichen Niederschlagssummen der vorangehenden 30 Jahre wieder. Jeder Punkt repräsentiert die Niederschlagssumme eines bestimmten Monats seit Beginn der Aufzeichnungen. Die grün eingefärbte Box markiert den Bereich, in dem sich die mittleren 50% der Messwerte der vergangenen Jahre befinden, wobei die Linie in der Mitte der Box den Medianwert der vorangehenden 30 Jahre darstellt, welcher die Mitte der Datenverteilung angibt. Die vertikalen Linien (Schnurrhaare) erstrecken sich zum niedrigsten und zum höchsten gemessenen Wert."}
                        it={"Questo grafico mostra l'andamento delle precipitazioni mensili (linea arancione) per l'anno selezionato. A titolo di confronto, un box plot mostra la dispersione e la mediana dei totali mensili delle precipitazioni dei 30 anni precedenti. Ogni punto rappresenta la somma delle precipitazioni di un particolare mese dall'inizio delle registrazioni. Il riquadro di colore verde indica l'area in cui si trova il 50% medio dei valori misurati negli anni passati, mentre la linea al centro del riquadro rappresenta il valore mediano dei 30 anni precedenti, che indica la parte centrale della distribuzione dei dati. Le linee verticali (baffi) si estendono dal valore più basso a quello più alto misurato."}
                        en={"This graphic shows the monthly precipitation trend (orange line) of the selected year. For comparison, a boxplot displays the dispersion and median of the monthly precipitation totals of the previous 30 years. Each point represents the precipitation total of a specific month since the beginning of the records. The green box marks the range where the middle 50% of the measurements of past years are located, with the line in the middle of the box representing the median value of the previous 30 years, indicating the center of the data distribution. The vertical lines (whiskers) extend from the lowest to the highest measured value."}
                        img={"/boxplot.png"}
                    />
                </Grid>

                <Grid item>
                    <GraphicButton object="svg_chart" filename={t("rain_boxplot")} />
                </Grid>

                <Grid item className="p-2">
                    <ExcelButton callback={downloadExcel} />
                </Grid>

            </Grid>

        </>
    );

}
