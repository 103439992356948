import * as d3 from "d3";
import * as ss from "simple-statistics";

import { useState, useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
//import { saveAs } from 'file-saver';

import Grid from '@mui/material/Grid';
import { GraphContext } from "../GraphContext";
import "../../styles.css";

import { preferences } from "../../preferences";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import DatePicker from '../../components/DatePicker';
//import ExcelButton from '../../components/ExcelButton';
import GraphicButton from '../../components/GraphicButton';
import InfoButton from '../../components/InfoButton';

import StationButton from "../../components/StationButton";
import StackRow from "../../components/StackRow";
import ComboBox from '../../components/ComboBox';

import { logo } from "../../components/d3/charts/chart";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';
import Title from "../../components/Title";

import { getCurrentYear } from '../../js/datetime';
//import { polyfit } from "../../components/math/polyfit"

// polynominal-regression
//import { createModel } from "../../components/math/index.js";
import { PolynomialRegressor } from '@rainij/polynomial-regression-js';
import { PolynomialFeatures } from '@rainij/polynomial-regression-js';

import { borders, addLine, AddArea } from '../../components/d3/legend/legend';

export const WinecultureReife = () => {

    //const model = createModel()

    const { trackPageView } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [parameter] = useContext(GraphContext);

    const [year, setYear] = useState(getCurrentYear());

    const [data, setData] = useState();
    const [ anlage, setAnlage ] = useState(1);
    const [ anlagen, setAnlagen ] = useState([]);
    const [fromdjs, setFromdjs] = useState(dayjs());

    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    const [resize, setResize] = useState(getWidth());
    const resizeHandler = (() => {
        setResize(getWidth());
    });

    window.addEventListener('resize', resizeHandler);

    //const [year, setYear] = useState(getCurrentYear());

    const svgRef = useRef();

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {

        const svg = d3.select(svgRef.current);

        if (data !== undefined)
	    if (data.list.length > 0)
             draw(svg);

    }, [resize, data, anlage]);

    useEffect(() => {
        setData(undefined);
        d3.json(preferences.address + "/query/wineculture/analysis/get.php?station=" + parameter.station + "&year=" + year + "&anlage="+anlage)
            .then((data) => {
                setData(data);
            });
    }, [year, anlage]);

    useEffect(() => {

        d3.json(preferences.address + "/query/anlagen_reife.php")
            .then((data) => {
                setAnlagen(data);
            });
	/*setAnlagen(["Auer Furggl - Lagrein",
		    "Brixen Neustift - Silvaner",
		    "Bozen Hagenbacherhof - Lagrein",
		    "Bozen St. Magdalena Großvernatsch",
		    "Eppan Berg Weißburgunder",
		    "Eppan Schwarzhaus Weißburgunder",
		    "Eppan Girlan Naun B Sauvignon",
		    "Kaltern Seegüter Großvernatsch",
		    "Kaltern See Cabernet",
		    "Kastelbell-Tschars Blauburgunder",
		    "Villanders Harbhof Müller-Thurgau",
		    "Kurtatsch Brenntal Merlot",
		    "Kurtinig Giatl Chardonnay",
		    "Kurtinig Castellfeder Ruländer",
		    "Margreid Pfannenstiel Cabernet",
		    "Meran Untermais Merlot",
		    "Montan Gehof Blauburgunder",
		    "Neumarkt Mazzon Blauburgunder",
		    "Salurn Stiermoos Chardonnay",
		    "Salurn Pfatten Ruländer",
		    "Terlan Eyrl 2 Weißburgunder",
		    "Terlan Winkl Sauvignon",
		    "Tramin Rungg Gewürztraminer",
		    "Tramin Maratsch Gewürztraminer",
		    "Tschrms Guggenhof Weißburgunder"
		    ]);*/
    }, []);

    // Track page view                                                                                                                           
    useEffect(() => {
        trackPageView({
            documentTitle: 'Reifetests upload', // optional                                                                                             
            href: 'https://meteo.laimburg.it' + location.pathname, // optional                                                                         
            customDimensions: [
                {
                    id: 1,
                    value: 'loggedIn',
                },
            ], // optional                                                                                                                           
        })
    }, [trackPageView, location, i18n.language])

    /*const downloadExcel = () => {

        var url = preferences.address + '/query/climate/rain/dry/excel.php';

        let block = {};
        block.station = parameter.station;
        //block.year = year;
        //block.period = period;
        //block.level = threshold;
        block.lang = i18n.language;

        let filename = '';
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: "no-cache",
            headers: {
            },
            body: JSON.stringify(block)
        })
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

	    }*/
    function dayOfTheYear(date) {
          return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24) - 1;
        }

    function draw(svg) {

        var ID_Time = {
            "dateTime": "%d %B",
            "date": "%d.%M",
            "time": "%H:%M:%S",
            "periods": ["AM", "PM"],
            "days": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "shortDays": [t("_sun"), t("_mon"), t("_tue"), t("_wed"), t("_thu"), t("_fri"), t("_sat")],
            "months": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")],
            "shortMonths": [t("_jan"), t("_feb"), t("_mar"), t("_apr"), t("_may"), t("_jun"), t("_jul"), t("_aug"), t("_sep"), t("_oct"), t("_nov"), t("_dec")]
        };
        d3.timeFormatDefaultLocale(ID_Time);

        // Clear
        svg.selectAll("*").remove();

        const margin = {
            top: 20,
            right: 50,
            bottom: 40,
            left: 60,
        }; // margin of chart

        let width = parseInt(svg.style("width"));

        //console.log (width);
        let parseTime = d3.timeParse("%Y-%m-%d");

        // Create scale
        let step = 40; // Pro zeile, Jahr

        // Anzahl Jahre
        /*let years = data.keys.length;
        if (years === 0) years = 1;*/

        let height = 600;
        svg.attr("height", height);

	let minTime = d3.min(data.list.map((d) => d3.timeParse("%Y-%m-%d")(d.current)));
	let maxTime = d3.max(data.list.map((d) => d3.timeParse("%Y-%m-%d")(d.current)));

        var xScaleTime = d3.scaleTime()
            .domain([minTime, maxTime])
            .range([margin.left, width - margin.right]);

        let xAxis = d3.axisBottom()
            .scale(xScaleTime);

        var yScale = d3.scaleLinear()
            .domain([0, 60])
            .range([height - margin.bottom, margin.top]);

        // Add scales to axis
        var yAxis = d3.axisLeft()
            .scale(yScale);

        // Append group and insert axis
        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(yAxis.tickFormat((x) => { return t(x) }));

        // X Label
        svg.append("text")
            .attr("class", "x label")
            .attr("text-anchor", "middle")
            .attr("x", width / 2)
            .attr("y", height-10)
            .text(t("date"));

        // Y Label
        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -height * 1/4)
            .attr("y", 20)
            .text(t("*KMW"));

        svg.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("transform", "rotate(-90)")
            .attr("x", -height * 3/4)
            .attr("y", 20)
            .text(t("Säure"));

        // TOP

        /*let d1 = new Date(FROM);
        let d2 = new Date(TO);*/

        /*var xScaleLinearLeft = d3.scaleLinear()
            .domain([dayOfTheYear(d1), dayOfTheYear(d2)])
            .range([margin.left, width - margin.right]);

        y_axis = d3.axisTop()
            .scale(xScaleLinearLeft);
        svg.append("g") // X Axis
            .attr("transform", `translate(0, ${margin.top})`)
            .call(y_axis.ticks(20));*/


        svg.append("g") // X Axis
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis);
        //let xGrid =
        svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(40));
        //let xGridWeeks = 
        svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis.tickSize(-height + margin.top + margin.bottom, 0).tickFormat("").ticks(10));

	// Horizontales Gitter
        svg.append("g")
            .attr("class", "gridline")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(yAxis.tickSize(-width + margin.left + margin.right, 0).tickFormat("").ticks(20));
        
	const box = svg.append("g").attr('id', 'block');


	sugar (box, margin, xScaleTime, yScale, minTime, maxTime);
	acid (box, margin, xScaleTime, yScale, minTime, maxTime);
	apfel (box, margin, xScaleTime, yScale, minTime, maxTime);
	wine (box, margin, xScaleTime, yScale, minTime, maxTime);
	ph (box, margin, xScaleTime, yScale, minTime, maxTime);
	hefe (box, margin, xScaleTime, yScale, minTime, maxTime);
	amino (box, margin, xScaleTime, yScale, minTime, maxTime);
	ammon (box, margin, xScaleTime, yScale, minTime, maxTime);

        logo(svg);

	let labels = ["KMW", "Gesamtsäure", "Apfelsäure", "Weinsäure", "pH", "HVS", "Amino", "Amminiak"];
	borders (svg, labels, 220, 60, 20);
	addLine (svg, 0, 2.5, "orange", "KWM");
	addLine (svg, 1, 2.5, "green", "Gesamtsäure");
	addLine (svg, 2, 2.5, "red", "Apfelsäure");
	addLine (svg, 3, 2.5, "steelblue", "Weinsäure");
	addLine (svg, 4, 2.5, "darkviolet", "pH-Wert");
	addLine (svg, 5, 2.5, "grey", "HVS");
	addLine (svg, 6, 2.5, "powderblue", "Amino-Stickstoff");
	addLine (svg, 7, 2.5, "pink", "Ammonium-Stickstoff");

    }

    function sugar (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		dataset_y.push( [d.kmw] );
	    })
	    

	// Search for a polynomial model of degree = 2.
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		// as Date and dateofyear
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}

        box.selectAll("circles")
            .data(data.list)
            .enter()
            .append("circle")
            .attr("fill", "white")
            .attr("stroke", "orange")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.kmw) })
            .attr("r", 4)

	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )	
	
	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "orange")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.kmw) })
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "orange")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.kmw) })
            .attr("r", 4)

    }

    function acid (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		dataset_y.push( [d.acid] );
	    })
	    

	// Search for a polynomial model of degree = 2.
	if (dataset_x.length > 5)
	{
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		// as Date and dateofyear
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}

	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )	

	}

	// Datenpunkte Säure
        box.selectAll("circles")
            .data(data.list)
            .enter()
            .append("circle")
            //.attr("transform", `translate(0, 0})`)
            .attr("fill", "white")
            .attr("stroke", "green")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.acid) })
            .attr("r", 4)

	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.acid) })
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "green")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.acid) })
            .attr("r", 4)

    }

    function apfel (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		if (d.appel !== 0)
		{
		    dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		    dataset_y.push( [d.appel] );
		}
	    })

	// Search for a polynomial model of degree = 2.
	if (dataset_x.length > 5)
	{
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}
	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )	
	}

        box.selectAll("circles")
            .data(data.list.filter((d) => {return d.appel !== 0}))
            .enter()
            .append("circle")
            .attr("fill", "white")
            .attr("stroke", "red")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.appel) })
            .attr("r", 4)

	
	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "red")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.appel) })
		.defined((d) => d.appel !== 0)
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "red")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.appel) })
            .attr("r", 4)

    }

    function wine (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		if (d.wine !== 0)
		{
		    dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		    dataset_y.push( [d.wine] );
		}
	    })

	// Search for a polynomial model of degree = 2.
	if (dataset_x.length > 5)
	{
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		// as Date and dateofyear
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}

	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )
	}

        box.selectAll("circles")
            .data(data.list.filter((d) => {return d.wine !== 0}))
            .enter()
            .append("circle")
            .attr("fill", "white")
            .attr("stroke", "steelblue")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.wine) })
            .attr("r", 4)

	
	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "steelblue")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.wine) })
		.defined((d) => d.wine !== 0)
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "black")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.wine) })
            .attr("r", 4)

    }

    function ph (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		if (d.ph !== 0)
		{
		    dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		    dataset_y.push( [d.ph] );
		}
	    })
	    

	// Search for a polynomial model of degree = 2.
	if (dataset_x.length > 5)
	{
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		// as Date and dateofyear
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}

	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "darkviolet")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )
	}

	// Datenpunkte Säure
        box.selectAll("circles")
            .data(data.list.filter((d) => {return d.ph !== 0}))
            .enter()
            .append("circle")
            .attr("fill", "white")
            .attr("stroke", "darkviolett")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.ph) })
            .attr("r", 4)

	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "darkviolet")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.ph) })
		.defined((d) => d.ph !== 0)
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "darkviolet")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.ph) })
            .attr("r", 4)

    }

    function hefe (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		if (d.hefe !== 0)
		{
		    dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		    dataset_y.push( [d.hefe] );
		}
	    })
	    

	// Search for a polynomial model of degree = 2.
	if (dataset_x.length > 5)
	{	
	
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		// as Date and dateofyear
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}

	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "grey")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )
	}
	
	// Datenpunkte Säure
        box.selectAll("circles")
            .data(data.list.filter((d) => {return d.hefe !== 0}))
            .enter()
            .append("circle")
            .attr("fill", "white")
            .attr("stroke", "grey")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.hefe) })
            .attr("r", 4)

	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "grey")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.hefe) })
		.defined((d) => d.hefe !== 0)
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "grey")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.hefe) })
            .attr("r", 4)

    }


    function amino (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		if (d.amino !== 0)
		{
		    dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		    dataset_y.push( [d.amino] );
		}
	    })
	    

	// Search for a polynomial model of degree = 2.
	if (dataset_x.length > 5)
	{
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		// as Date and dateofyear
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}

	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "powderblue")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )
	}

	// Datenpunkte Säure
        box.selectAll("circles")
            .data(data.list.filter((d) => {return d.amino !== 0}))
            .enter()
            .append("circle")
            //.attr("transform", `translate(0, 0})`)
            .attr("fill", "white")
            .attr("stroke", "powderblue")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.amino) })
            .attr("r", 4)

	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "powderblue")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.amino) })
		.defined((d) => d.amino !== 0)
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "powderblue")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.amino) })
            .attr("r", 4)

    }


    function ammon (box, margin, xScaleTime, yScale, minTime, maxTime) {
	
	let dataset_x = [];
	let dataset_y = [];
	    data.list.map((d) => {
		if (d.ammon !== 0)
		{
		    dataset_x.push( [dayOfTheYear(new Date(d.current))] );
		    dataset_y.push( [d.ammon] );
		}
	    })
	    

	// Search for a polynomial model of degree = 2.
	if (dataset_x.length > 5)
	{
	const model = new PolynomialRegressor(2);
	model.fit(dataset_x, dataset_y) // Training

	let mi = dayOfTheYear(minTime);
	let ma = dayOfTheYear(maxTime);

	let x_pos = new Date(minTime);
	x_pos.setDate(minTime.getDate() + 3);
	
	let x_calc = [];
	let graph = [];
	
	while (dayOfTheYear(x_pos) < dayOfTheYear(maxTime)-2)
	{
	    let item = {}
	    item.date = new Date(x_pos.getTime());
	    item.x = dayOfTheYear (item.date);
	    if (item.x % 1 === 0)
	    {
		// as Date and dateofyear
		item.y = model.predict([ [item.x] ])[0];
		graph.push(item);
	    }
	    x_pos.setDate(x_pos.getDate() + 1);
	}

	box.append("path")
            .datum(graph)
            .attr("fill", "none")
            .attr("stroke", "pink")
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d.date) })
                .y( (d) => { return yScale(d.y) })
            )
	}

	// Datenpunkte Säure
        box.selectAll("circles")
            .data(data.list.filter((d) => {return d.ammon !== 0}))
            .enter()
            .append("circle")
            //.attr("transform", `translate(0, 0})`)
            .attr("fill", "white")
            .attr("stroke", "pink")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.ammon) })
            .attr("r", 4)

	
	box.append("path")
            .datum(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .attr("fill", "none")
            .attr("stroke", "pink")
            .attr("stroke-width", 2.5)
            .attr("d", d3.line()
                .x( (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
                .y( (d) => { return yScale(d.ammon) })
		.defined((d) => d.ammon !== 0)
            )	
        box.selectAll("circles")
            .data(data.list.filter((d) => {let date = d3.timeParse("%Y-%m-%d")(d.datum); return date.getYear()+1900 === year}))
            .enter()
            .append("circle")
            .attr("fill", "black")
            .attr("stroke", "pink")
            .attr("cx", (d, i) => { return xScaleTime(d3.timeParse("%Y-%m-%d")(d.current)) })
            .attr("cy", function (d) { return yScale(d.ammon) })
            .attr("r", 4)

    }


    const handleSelector = (event) => {

        setFromdjs(event);
        let date = event.$d;
        setYear(1900 + date.getYear());
    }

    let c = ["Golden Delicious", "Cripps Pink", "Gala"];
    const handleSelectorAnlage = (event) => {
        setAnlage (event.target.value);
    };


    return (
        <>
            <div className="stagearea grid p-2">
				
                <Title text={t("phenology_wine")} />

			{/* <StackRow text={t("station")} component={<StationButton />} /> */}
                
                <Stack spacing={0}>

                <Item>
                    <DatePicker
                        title="year"
                        value={fromdjs} 
                        callback={handleSelector}
                        view={["year"]}
                        format="YYYY"
                    />
                </Item>

                    <Item>
                        <ComboBox
                                title={"variety"}
                                value={anlage} items={anlagen}
                                callback={handleSelectorAnlage} />
                    </Item>

                </Stack>


               <div id="svgchart">
                    {data !== undefined 
                        ?
                        <svg ref={svgRef} id="svg_chart" className="d3chart"></svg>
                        :
                        <div>No data available</div>
                    }
                </div>

                <Grid container flexDirection={"row"}>

	    <Grid item className="p-2" flexGrow={1}>
                    <InfoButton
                         de={""}
                         it={""}
                         en={""}
                    />
                </Grid>

                    <Grid item>
                        <GraphicButton object="svg_chart" filename={t("dry")} />
                    </Grid>

                    <Grid item className="p-2">
                        {/* <ExcelButton callback={downloadExcel} /> */}
                    </Grid>

                </Grid>
            </div>
        </>
    );

}
