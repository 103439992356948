import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

export default function ExcelMiddleButton ({callback}) {

   const { t } = useTranslation();

return (

    <>
       <Button 
        style={{
            backgroundColor: "#556455",
            }}
            className="m-1"
            variant="contained"
            onClick={callback}>
               {t("export_ljm_excel")}
            </Button>
    </>

)

}