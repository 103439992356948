// Stationinfo.js
import * as d3 from "d3";
import { useState, useEffect } from "react";

import "../../styles.css";

import pref from "../../preferences"

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { TabPanel } from "../../components/TabPanel";
import PropTypes from 'prop-types';

import { GeneralDiagram } from './GeneralDiagram';

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

import Title from "../../components/Title";

export default function GeneralContent () {

    const { trackPageView } = useMatomo()
    const location = useLocation();

	const { t, i18n } = useTranslation();

    useEffect(() => {
      trackPageView({
        documentTitle: 'Stationsvergleich', // optional
	href: 'https://meteo.laimburg.it'+location.pathname, // optional

        customDimensions: [
          {
            id: 0,
          },
        ], // optional
      })
    }, [trackPageView, location])
    
	  
TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
};
}


return (

	<>

  <div className="stagearea grid p-2">
	
	<Title text={t("Stationsvergleich")} />

  <Box sx={{ width: '100%' }}>

    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
       <GeneralDiagram />
    </Box>

  </Box>

  <div className="flex-row p-4 expand">
  </div>

  <div id="pdf">
  </div>


  </div>

    </>
	
);
}
