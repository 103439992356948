import { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { getCurrentYear, getCurrentMonth } from '../../js/datetime';
import { GraphContext } from "../GraphContext";
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { preferences } from "../../preferences";

import { useTheme } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import StationButton from "../../components/StationButton";

import SensorComboBox from '../../components/SensorComboBox';
import StackRow from "../../components/StackRow";
import { useIsAuthenticated } from 'react-auth-kit';

import Dropzone from "../../components/Dropzone";
import ImageGrid from "../../components/ImageGrid";
import cuid from "cuid";
import DatePicker from '../../components/DatePicker';

export const Reifedaten = ({ active }) => {

    const isAuthenticated = useIsAuthenticated()
    const { t, i18n } = useTranslation();

    const [ images, setImages ] = useState([]);
    const [ fromdjs, setFromdjs ] = useState(dayjs());
    const [ year, setYear ] = useState(getCurrentYear());
    const [ file, setFile ] = useState( );

    const onDrop = useCallback((acceptedFiles) => {
	console.log (acceptedFiles, year);

	acceptedFiles.map((file) => {
	    let reader = new FileReader();

	    reader.onload = function (e) {
		console.log (e.target);
		/*setImages((prevState) => [
		    ...prevState,
		    { id: cuid(), src: e.target.result },
		]);*/
		setImages([
		    { id: cuid(), src: e.target.result },
		]);
	    };

	    setFile (file);
	    console.log (file);
	    reader.readAsDataURL (file);
	    reader.onload = function (){
		}

	    return file;
	}); // map
    }, []);


    const handleDateSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setYear(1900 + date.getYear());

    }

    const upload = (() => {

	    let reader = new FileReader();
	    reader.readAsDataURL (file);
	    reader.onload = function (){
		const formdata  = new FormData();
		formdata.append("year", year);
		//formdata.append("language", i18n.language);
		formdata.append("filename", file.name);
		formdata.append("filesize", file.size);
		formdata.append("files", reader.result);

	fetch (preferences.address + '/upload/uploadReifedaten.php', {
		method: 'POST',
		body: formdata
	})
	.then (response => response.json())
	.then (text => console.log (text))
	.catch(error => console.log (error));

	setFile();
    }	
    })

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
	color: theme.palette.text.secondary,
    }));


    return (
	    <>
	    {isAuthenticated() &&
	     <div className="stagearea grid p-2">

	     <Stack spacing={0}>

	     		<Item>
		        <DatePicker
                            title="year"
                            value={fromdjs}
	                    callback={handleDateSelector}
                            view={["year"]}
                            format="YYYY"
			/>
                    </Item>
	     </Stack>

	     <Box>

	         <Dropzone onDrop={onDrop} accept={"application/pdf"} />

	     <Box sx={{ width: "100%",
			color: 'black',
			bgcolor: "background.paper" }}> 

	     <nav aria-label="main mailbox folders"> 
             <List>

	     {file !== undefined &&
              <ListItem> 
              <ListItemButton> 
              <ListItemText primary={file.name} /> 
              </ListItemButton> 
              </ListItem>
	     }
	     
             </List> 
             </nav> 
	     </Box>
	     
	     </Box>

	     <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
		<Button onClick={upload}variant="outlined" color="primary">Upload</Button>
	     </Box>
       
	     </div>
	    }
	</>
    );
}
