import { useState, useContext, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import ExcelButton from '../../../components/ExcelButton';
import ExcelMiddleButton from '../../../components/ExcelMiddleButton';
import PDFButton from '../../../components/PDFButton';
import Title from '../../../components/Title';
import dayjs from 'dayjs';

import { GraphContext } from "../../GraphContext";
import { getCurrentYear, getCurrentMonth } from "../../../js/datetime";
import { preferences } from "../../../preferences";
import { OverviewYearTable } from './table/OverviewYearTable';

import { saveAs } from 'file-saver';

import DatePicker from '../../../components/DatePicker';
import StationButton from "../../../components/StationButton";
import StackRow from "../../../components/StackRow";

import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useLocation } from 'react-router-dom';

export const Jahresreport = (props) => {

    const { trackPageView, trackEvent } = useMatomo()
    const location = useLocation();

    const { t, i18n } = useTranslation();

    const [parameter, setParameter] = useContext(GraphContext);

    const [fromdjs, setFromdjs] = useState(dayjs());

    const [year, setYear] = useState(getCurrentYear());

    const [open, setOpen] = useState(false);

    const Item = styled(Paper)(({ theme }) => ({

        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    // Track page view                                                                                                                              
    useEffect(() => {
        trackPageView({
            documentTitle: 'Jahrestabelle: ' + year, // optional                                                                       
            href: 'https://meteo.laimburg.it' + location.pathname, // optional
            customDimensions: [
                {
                    id: 1
                },
            ], // optional
        })
    }, [trackPageView, location])

    const handleSelector = (event) => {

        setFromdjs(event);

        let date = event.$d;
        let format = (1900 + date.getYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        setYear(1900 + date.getYear());

    }

    const exportExcel = (event) => {

        let station = parameter.station;

        console.log(station, year);
        setOpen(true);
        var url = preferences.address + '/query/report/export/year/excel.php?station=' + parameter.station + "&year=" + year + "&lang=" + i18n.language;

        let filename = '';
        fetch(url)
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                setOpen(false);
            });

    }
    const exportExcel2 = (event) => {

        let station = parameter.station;

        console.log(station, year);
        setOpen(true);
        var url = preferences.address + '/query/report/export/year/excel2.php?station=' + parameter.station + "&year=" + year + "&lang=" + i18n.language;

        let filename = '';
        fetch(url)
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Data_Export.xlsx' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/vnd.ms-excel;charset=utf-8" });
                saveAs(file);
                setOpen(false);
                trackEvent({
                    category: 'Downloads',
                    action: 'Excel',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    const exportPDF = (event) => {

        let station = parameter.station;

        console.log(station, year);
        setOpen(true);

        var url = preferences.address + '/query/report/export/year/pdf.php?station=' + parameter.station + "&year=" + year + "&lang=" + i18n.language;

        let filename = '';
        fetch(url)
            .then((response) => {
                try {
                    filename = response.headers.get('content-disposition').split('filename=')[1];
                }
                catch (error) { filename = 'Report.pdf' }
                return response.blob()
            })
            .then((data) => {
                var file = new File([data], filename, { type: "application/pdf;charset=utf-8" });
                saveAs(file);
                setOpen(false);
                trackEvent({
                    category: 'Downloads',
                    action: 'PDF',
                    name: filename, // optional
                    href: 'https://meteo.laimburg.it' + location.pathname, // optional	    
                })
            });

    }

    const action = (
        <Fragment>
        </Fragment>
    )

    return (
        <>


            <div className="stagearea grid p-2">

                <Title text={t("jahrestabelle")} />
                <Stack spacing={0}>

                    <StackRow text={t("station")} component={<StationButton />} />

                    <Item>
                        <DatePicker
                            title="year"
                            value={fromdjs}
                            callback={handleSelector}
                            view={["year"]}
                            format="YYYY"
                        />
                    </Item>

                </Stack>

                <OverviewYearTable year={year}></OverviewYearTable>

                <Grid container flexDirection={"row-reverse"}>

                    <Grid item className="p-2">
                        <ExcelMiddleButton callback={exportExcel2} />
                    </Grid>
                    <Grid item className="p-2">
                        <ExcelButton callback={exportExcel} />
                    </Grid>

                    <Grid item className="p-2">
                        <PDFButton callback={exportPDF} />
                    </Grid>

                </Grid>

            </div>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                message="Downloading ..."
                action={action}
            />

        </>
    );
}
